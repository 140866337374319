import { Fragment, useContext } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useMeasure } from "@react-hookz/web";
import { isNull } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { getSequence, mapSequence } from "@parallel/vertex/util/collection.util";
import { StoreContext } from "@/stores";

// when sections taller than this, split to two sections per hour
const MAX_HOUR_SECTION_HEIGHT = 75;

const CalendarBackground = () => {
  const {
    calendarStore: { hourRange },
  } = useContext(StoreContext);

  const hourSectionProportion = 1 / (hourRange.max - hourRange.min);
  const hourSectionHeight = percentString(hourSectionProportion);

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  const theme = useTheme();

  let sectionsPerHour: number | null = null;
  if (containerArea) {
    const sectionHeight = containerArea.height * hourSectionProportion;
    sectionsPerHour = sectionHeight < MAX_HOUR_SECTION_HEIGHT ? 1 : 2;
  }

  return (
    <FullStack sx={{ borderTop: 1, borderColor: theme.palette.bordergrey.main }} ref={containerRef}>
      {!isNull(sectionsPerHour) &&
        getSequence(hourRange.min, hourRange.max - 1).map(hour => (
          <Fragment key={hour}>
            {mapSequence(sectionsPerHour, i => (
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  height: hourSectionHeight,
                  paddingLeft: 1,
                  paddingTop: 1,
                  borderBottom: 1,
                  borderColor: theme.palette.bordergrey.main,
                  overflow: "hidden",
                }}
                key={`${hour}-${i}`}
              >
                <Typography
                  variant="body2"
                  sx={{ width: "4rem", alignSelf: "start", textAlign: "right", color: theme.palette.bordergrey.main }}
                >
                  {DateTime.fromObject({ hour, minute: Math.round(60 / sectionsPerHour) * i }).toLocaleString(
                    DateTime.TIME_SIMPLE,
                  )}
                </Typography>
              </Stack>
            ))}
          </Fragment>
        ))}
    </FullStack>
  );
};

export default observer(CalendarBackground);
