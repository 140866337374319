import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { UserType } from "@parallel/vertex/enums/user.enums";
import { filterExists } from "@parallel/vertex/util/collection.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import SubHeader from "@/components/shared/layout/SubHeader";
import { StoreContext } from "@/stores";

const USER_TYPE_ICONS = {
  STUDENT: <GroupsIcon />,
  SLPA: <AssignmentIndIcon />,
  FACILITATOR: <SupervisedUserCircleIcon />,
  PROVIDER: <AccountCircleIcon />,
  ADMIN: <BadgeIcon />,
};

const UserListHeader = ({ listType }: { listType: UserType }) => {
  const navigate = useNavigate();

  const { authStore } = useContext(StoreContext);
  const { currentUser } = authStore;

  const visibleUserTypes: UserType[] = filterExists([
    currentUser?.userType !== "STUDENT" && "STUDENT",
    currentUser && ["ADMIN", "PROVIDER"].includes(currentUser.userType) && "FACILITATOR",
    currentUser &&
      (currentUser.userType === "ADMIN" ||
        (currentUser.userType === "PROVIDER" && currentUser.providerType === "SLP")) &&
      "SLPA",
    currentUser?.userType === "ADMIN" && "PROVIDER",
    currentUser?.userType === "ADMIN" && "ADMIN",
  ]);

  return (
    <SubHeader
      icon={<PeopleAltIcon />}
      title={"Users"}
      navigation={{
        tabs: visibleUserTypes.map(type => ({
          key: type,
          label: `${toTitleCase(type)}s`,
          icon: USER_TYPE_ICONS[type],
        })),
        currentKey: listType,
        onChange: type => navigate(`/user/${type.toLowerCase()}`),
      }}
    />
  );
};

export default UserListHeader;
