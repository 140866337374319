import { useContext } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import { ProcessIconButton } from "@parallel/polygon/components/shared/input/status.input";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { ReportEditorSubsection } from "@parallel/vertex/types/assessment/assessment.report.types";
import ReportEditorBlock from "@/components/report/editor/ReportEditorBlock";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getSubsectionMarkdown } from "@/util/report.markdown.util";
import { useEditorAutoScroll } from "@/util/report.util";

const logger = initLogger("ReportEditorSubsectionComponent", getLoggerContext);

const ReportEditorSubsectionComponent = ({ subsection }: { subsection: ReportEditorSubsection }) => {
  const {
    reportStore: { currentReport, upsertCustomSection, menuSelection, onEditorSectionVisibilityChange },
  } = useContext(StoreContext);

  const { containerRef } = useEditorAutoScroll(
    subsection.reportSectionTemplateId,
    menuSelection,
    onEditorSectionVisibilityChange,
  );

  const isSectionRequiredByUploads =
    isEmpty(subsection.testUploadSourceIds) ||
    currentReport?.testUploads.some(
      u => subsection.testUploadSourceIds.includes(u.reportTestUploadSourceId) && !u.includeReasonId,
    );

  const canHide = subsection.canHide || !isSectionRequiredByUploads;

  const setSectionHidden = (isHidden: boolean) =>
    upsertCustomSection(subsection.reportSectionTemplateId, { isHidden }).catch(
      logger.handleFailureAndThrow("setSectionHidden", { context: { isHidden } }),
    );

  const isHidden = subsection.custom?.isHidden;

  const controls = isHidden ? (
    <ProcessIconButton process={() => setSectionHidden(false)} size="small">
      <VisibilityIcon fontSize="small" />
    </ProcessIconButton>
  ) : (
    <Stack direction="row" gap={1}>
      {canHide && (
        <ProcessIconButton process={() => setSectionHidden(true)} size="small">
          <VisibilityOffIcon fontSize="small" />
        </ProcessIconButton>
      )}
      <CopyIconButton text={getSubsectionMarkdown(subsection)} label={`Section ${subsection.title}`} logger={logger} />
    </Stack>
  );

  return (
    <Stack width="100%" gap={1} ref={containerRef}>
      <SplitRow
        left={
          <Typography variant="h3" sx={{ textDecoration: isHidden ? "line-through" : undefined }}>
            {subsection.title}
          </Typography>
        }
        right={controls}
      />

      {!isHidden &&
        subsection.blocks.map(block => <ReportEditorBlock block={block} key={block.reportBlockTemplateId} />)}
    </Stack>
  );
};

export default observer(ReportEditorSubsectionComponent);
