import {
  StudentRow,
  StudentRowValidation,
  StudentUploadBody,
  StudentUploadResponse,
  StudentUploadSourceResponse,
} from "@parallel/vertex/types/bulk.upload.types";
import { ServiceType } from "@parallel/vertex/types/service.types";
import { BaseAPI } from "@/api/base.api";

export class StudentAPI extends BaseAPI {
  public getSheet = ({
    googleSheetId,
    serviceType,
  }: {
    googleSheetId: string;
    serviceType: ServiceType;
  }): Promise<StudentUploadSourceResponse> =>
    this.instance
      .get(`student/bulk/source?googleSheetId=${googleSheetId}&serviceType=${serviceType}`)
      .then(r => r.data);

  public postRowValidate = ({
    data,
    serviceType,
  }: {
    data: StudentRow;
    serviceType: ServiceType;
  }): Promise<StudentRowValidation> =>
    this.instance.post(`student/bulk/validate?serviceType=${serviceType}`, data).then(r => r.data);

  public createStudents = (body: StudentUploadBody): Promise<StudentUploadResponse> =>
    this.instance.post("student/bulk", body).then(r => r.data);
}
