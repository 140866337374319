import { ReactNode } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";

type SyncPromptAction = {
  fn: () => unknown;
  label: string;
  icon?: ReactNode;
};

type AsyncPromptAction = {
  fn: () => Promise<unknown>;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
};

const PromptLayout = ({
  headerText,
  children,
  leftAction,
  rightAction,
}: {
  headerText: string;
  children: ReactNode;
  leftAction: SyncPromptAction;
  rightAction: AsyncPromptAction;
}) => {
  return (
    <Stack gap={3}>
      <Typography variant="h2">{headerText}</Typography>
      {children}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button variant="outlined" onClick={leftAction.fn} startIcon={leftAction.icon}>
          {leftAction.label}
        </Button>
        <ProcessButton
          variant="contained"
          process={rightAction.fn}
          startIcon={rightAction.icon}
          disabled={rightAction.disabled}
        >
          {rightAction.label}
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default PromptLayout;
