import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UndoIcon from "@mui/icons-material/Undo";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import Stack, { StackProps } from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ApprovalStatus } from "@parallel/vertex/types/calendar/time.types";
import { formatNounWithCount } from "@parallel/vertex/util/string.util";

export const PendingApprovalControls = ({
  onStatusUpdate,
  bulkUpdateCount,
}: {
  onStatusUpdate: (status: ApprovalStatus) => void;
  bulkUpdateCount?: number;
}) => {
  const clickHandler = (status: ApprovalStatus) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onStatusUpdate(status);
  };
  return (
    <>
      <Tooltip title={bulkUpdateCount ? `Approve ${bulkUpdateCount} Selected` : "Approve"}>
        <IconButton onClick={clickHandler("APPROVED")}>
          <CheckCircleIcon color="success" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          bulkUpdateCount ? `Request ${formatNounWithCount(bulkUpdateCount, "Selected Review")}` : "Request Review"
        }
      >
        <IconButton onClick={clickHandler("FOR_REVIEW")}>
          <WarningIcon color="warning" />
        </IconButton>
      </Tooltip>
      <Tooltip title={bulkUpdateCount ? `Deny ${bulkUpdateCount} Selected` : "Deny"}>
        <IconButton onClick={clickHandler("DENIED")}>
          <CancelIcon color="error" />
        </IconButton>
      </Tooltip>
      {bulkUpdateCount && (
        <Tooltip title={`Reset ${bulkUpdateCount} Selected`}>
          <IconButton onClick={clickHandler("PENDING")}>
            <UndoIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

const ApprovalControls = ({
  approvalStatus,
  onStatusUpdate,
}: {
  approvalStatus?: ApprovalStatus;
  onStatusUpdate: (status: ApprovalStatus) => void;
}) => {
  const setPendingHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    onStatusUpdate("PENDING");
  };
  const commonStackProps: StackProps = { direction: "row", alignItems: "center", gap: 1, pr: 1 };
  switch (approvalStatus) {
    case "PENDING":
      return <PendingApprovalControls onStatusUpdate={onStatusUpdate} />;
    case "FOR_REVIEW":
      return (
        <Stack {...commonStackProps}>
          <WarningIcon color="warning" />
          <Typography noWrap>Provider Review</Typography>
          <IconButton onClick={setPendingHandler}>
            <UndoIcon />
          </IconButton>
        </Stack>
      );
    case "DENIED":
      return (
        <Stack {...commonStackProps}>
          <CancelIcon color="error" />
          <Typography>Denied</Typography>
          <IconButton onClick={setPendingHandler}>
            <UndoIcon />
          </IconButton>
        </Stack>
      );
    default:
      return (
        <Stack {...commonStackProps}>
          <CheckCircleIcon color="success" />
          <Typography>Approved</Typography>
          {approvalStatus && (
            <Tooltip title="Reset">
              <IconButton onClick={setPendingHandler}>
                <UndoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      );
  }
};

export default ApprovalControls;
