import { isUndefined } from "lodash";
import { AppointmentCompletionRequirement, AppointmentProgress } from "@parallel/vertex/types/progress.types";

export const doesProgressMeetRequirement = (
  progress: AppointmentProgress,
  requirement: AppointmentCompletionRequirement,
): boolean => {
  switch (requirement) {
    case "PROGRESS_NOTE":
      return !!progress.groupNote || progress.students.some(s => !!s.note);
    case "PROGRESS_METRIC":
      return progress.students.some(
        s => s.isWaived || s.goals.some(g => g.objectives.some(o => !isUndefined(o.metric?.value))),
      );
  }
};
