import { ReactNode, useContext, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { GreyBox } from "@parallel/polygon/components/shared/layout/container";
import { RecurrenceEditMode } from "@parallel/vertex/enums/calendar.enums";
import { ExtendedRecurrence } from "@parallel/vertex/types/calendar/recurrence.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { timeRangeString } from "@parallel/vertex/util/datetime.util";
import RecurrenceEditModeInput from "@/components/shared/input/RecurrenceEditModeInput";
import { StoreContext } from "@/stores";
import { recurrenceText } from "@/util/calendar.util";
import { RecordName } from "@/util/shared.util";

type BaseDisplayRecord = {
  startTime: DateTime;
  endTime: DateTime;
  recurrence?: ExtendedRecurrence;
};

export type DisplayFields = { text: string; icon?: ReactNode; label?: string }[];

const CommonDisplay = <A extends BaseDisplayRecord>({
  title,
  record,
  recordName,
  fields,
  deleteRecord,
  onEdit,
  otherActions,
  onClose,
  children,
}: {
  title: string;
  record: A;
  recordName: RecordName;
  fields?: DisplayFields;
  deleteRecord?: (recurrenceEditMode?: RecurrenceEditMode) => Promise<void>;
  onEdit?: () => void;
  otherActions?: { icon: ReactNode; label: string; onClick: () => void }[];
  onClose: () => void;
  children?: ReactNode;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const [isDeleting, setIsDeleting] = useState(false);
  const [recurrenceEditMode, setRecurrenceEditMode] = useState<RecurrenceEditMode>("single");

  return (
    <Stack gap={3} width={540}>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <Box sx={{ alignSelf: "center" }}>
            <Typography variant="h2">{title}</Typography>
          </Box>

          {!isDeleting && (
            <Stack direction="row" alignItems="center" aria-label="Edit Appointment">
              {onEdit && (
                <IconButton onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              )}
              {deleteRecord && (
                <IconButton onClick={() => setIsDeleting(true)} aria-label="Delete Appointment">
                  <DeleteForeverIcon />
                </IconButton>
              )}
              {mapExists(otherActions, ({ icon, label, onClick }, i) => (
                <Tooltip title={label} key={i}>
                  <IconButton onClick={onClick} aria-label="Close Appointment Detail">
                    {icon}
                  </IconButton>
                </Tooltip>
              ))}
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <GreyBox>
          <Typography variant="body1">{record.startTime.toLocaleString(DateTime.DATE_HUGE)} </Typography>
          <Typography variant="body2">{timeRangeString(record, timezone, { withZone: true })}</Typography>
          {record.recurrence ? <Typography variant="body2">{recurrenceText(record.recurrence)}</Typography> : undefined}
        </GreyBox>

        {fields && !isEmpty(fields) && !isDeleting && (
          <Stack spacing={1} sx={{ color: "grey.800" }}>
            {fields.map(({ text, icon, label }, i) => (
              <Tooltip title={label} placement="left" key={i}>
                <Stack direction="row" spacing={2} alignItems="start" sx={{ cursor: "pointer" }}>
                  {icon}
                  <Typography variant="body1">{text}</Typography>
                </Stack>
              </Tooltip>
            ))}
          </Stack>
        )}
      </Stack>

      {deleteRecord && isDeleting ? (
        <>
          {record.recurrence ? (
            <RecurrenceEditModeInput
              params={{ recurrenceEditMode }}
              setParams={p => setRecurrenceEditMode(p.recurrenceEditMode)}
              actionName="delete"
              recordName={recordName}
            />
          ) : (
            <Typography>Please confirm that you want to permanently delete this {recordName.singular}.</Typography>
          )}
          <Stack direction="row" gap={2}>
            <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => setIsDeleting(false)} fullWidth>
              Back
            </Button>
            <ProcessButton
              process={() => deleteRecord(recurrenceEditMode).then(onClose)}
              variant="contained"
              startIcon={<DeleteForeverIcon />}
              fullWidth
              aria-label="Confirm Delete Appointment"
            >
              Delete
            </ProcessButton>
          </Stack>
        </>
      ) : (
        children
      )}
    </Stack>
  );
};

export default CommonDisplay;
