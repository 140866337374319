import {
  Institution,
  SearchInstitutionQuery,
  SearchCampusQuery,
  ExtendedCampus,
} from "@parallel/vertex/types/institution.types";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "@/api/base.api";

export class InstitutionAPI extends BaseAPI {
  public searchInstitutions = (params: SearchInstitutionQuery): Promise<PaginatedResult<Institution>> =>
    this.instance.get("institution", { params }).then(r => r.data);

  public searchCampuses = (params: SearchCampusQuery): Promise<PaginatedResult<ExtendedCampus>> =>
    this.instance.get("institution/campus", { params }).then(r => r.data);
}
