import { ReportEditorBlock, SingleReport } from "../types/assessment/assessment.report.types";

export const getFlattenedBlocks = (report: SingleReport): ReportEditorBlock[] =>
  report.editorSections.flatMap(section =>
    section.children.flatMap(child => {
      switch (child.type) {
        case "block":
          return [child];
        case "subsection":
          return child.blocks;
      }
    }),
  );
