import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AppointmentProgressMetricPrompt = ({
  appointmentId,
  navigate,
  onBack,
}: {
  appointmentId: string;
  navigate: (url: string) => void;
  onBack: () => void;
}) => {
  return (
    <Stack gap={2} width={500}>
      <Typography variant="body1">
        This appointment type requires IEP data to be collected in the session notes before it can be marked as
        "Occurred."
      </Typography>
      <Typography variant="body1">
        Please review the session notes and either add IEP data or select "No Data collected this session" at the top of
        the page for each student that does not have data. Then, return here to update the appointment status.
      </Typography>
      <Stack direction="row" gap={2} mt={2}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onBack} fullWidth>
          Back
        </Button>
        <Button
          variant="contained"
          startIcon={<EmojiEventsIcon />}
          onClick={() => navigate(`/calendar/appointment/${appointmentId}/progress`)}
          fullWidth
        >
          View Session Notes
        </Button>
      </Stack>
    </Stack>
  );
};

export default AppointmentProgressMetricPrompt;
