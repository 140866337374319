import { ReactNode, useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import ReportInterviewNotes from "@/components/report/data/ReportInterviewNotes";
import ReportTestingPlan from "@/components/report/data/ReportTestingPlan";
import ReportTestsAdministered from "@/components/report/data/ReportTestsAdministered";
import ReportEditor from "@/components/report/editor/ReportEditor";
import { StoreContext } from "@/stores";

const ReportContent = () => {
  const {
    reportStore: { menuSelection },
  } = useContext(StoreContext);

  if (menuSelection.type === "editor") return <ReportEditor sectionTemplateId={menuSelection.sectionTemplateId} />;

  switch (menuSelection.section) {
    case "testing-plan":
      return <ReportTestingPlan />;
    case "tests-administered":
      return <ReportTestsAdministered />;
    case "interview-notes":
      return <ReportInterviewNotes />;
  }
};

export const ReportContentLayout = ({
  title,
  actions,
  children,
}: {
  title: string;
  actions?: ReactNode;
  children: ReactNode;
}) => (
  <FullBox>
    <Stack direction="row" pb={2} justifyContent="space-between" alignItems="start" width="100%">
      <Typography variant="h2">{title}</Typography>
      {actions}
    </Stack>
    {children}
  </FullBox>
);

export default observer(ReportContent);
