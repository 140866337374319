import { ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EmailIcon from "@mui/icons-material/Email";
import ManageAccountIcon from "@mui/icons-material/ManageAccounts";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid-premium";
import { capitalize, isEmpty } from "lodash";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import { UserType } from "@parallel/vertex/enums/user.enums";
import { PaginateParams } from "@parallel/vertex/types/shared.types";
import { ExtendedFacilitatorUser } from "@parallel/vertex/types/user/facilitator.types";
import { ExtendedProviderUser } from "@parallel/vertex/types/user/provider.types";
import { ExtendedSlpaUser } from "@parallel/vertex/types/user/slpa.types";
import { ExtendedStudentUser } from "@parallel/vertex/types/user/student.types";
import { filterExists } from "@parallel/vertex/util/collection.util";
import { FilterOption } from "@/components/shared/input/FilterSearchInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import AdminForm from "@/components/user/AdminForm";
import FacilitatorForm from "@/components/user/FacilitatorForm";
import ProviderForm from "@/components/user/ProviderForm";
import SlpaForm from "@/components/user/SlpaForm";
import UserDataTable, {
  INITIAL_PAGE_SIZE,
  UserState,
  toProviderRow,
  toStudentRow,
} from "@/components/user/UserDataTable";
import UserListFilter, { CategoricalFilters } from "@/components/user/UserListFilter";
import UserListHeader from "@/components/user/UserListHeader";
import StudentAssignmentEdit from "@/components/user/student/form/StudentAssignmentEdit";
import StudentCreateForm from "@/components/user/student/form/StudentCreateForm";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("UserListScreen", getLoggerContext);

const Confirmation = ({
  text,
  confirm,
  onFinished,
}: {
  text: string;
  confirm: {
    icon: ReactNode;
    process: () => Promise<unknown>;
    verb: string;
    pastTenseVerb: string;
    object: string;
    onSuccess?: () => void;
  };
  onFinished: () => void;
}) => {
  const onConfirm = () =>
    confirm
      .process()
      .then(() => {
        confirm.onSuccess && confirm.onSuccess();
        toast.success(`Successfully ${confirm.pastTenseVerb} ${confirm.object}`);
      })
      .catch(e => {
        logger.logFailure(`${confirm.verb} ${confirm.object}`, e);
        toast.error(`Failed to ${confirm.verb} ${confirm.object}`);
      })
      .finally(onFinished);

  return (
    <Stack width={400} gap={2}>
      <Typography variant="body1">{text}</Typography>
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Button onClick={onFinished}>Cancel</Button>
        <ProcessButton variant="contained" startIcon={confirm.icon} process={onConfirm}>
          {capitalize(confirm.verb)}
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

const UserListScreen = ({ listType = "STUDENT" }: { listType?: UserType }) => {
  const {
    apiStore: { userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [filters, setFilters] = useState<FilterOption[]>([]);
  const searchQuery = filters.reduce(
    (currParams, nextFilter) => ({
      ...currParams,
      [nextFilter.searchParamKey]: nextFilter.searchParamValue,
    }),
    {},
  );
  useEffect(() => setFilters([]), [listType]);

  const [categoricalFilters, setCategoricalFilters] = useState<CategoricalFilters>({
    showTestData: true,
    showArchivedStudents: false,
    filterUnassignedStudents: false,
  });

  const [pageModel, setPageModel] = useState<PaginateParams>({ pageSize: INITIAL_PAGE_SIZE });
  const [sortModel, setSortModel] = useState<any>();

  const [users, setUsers] = useState<UserState>({});
  const fetchUsers = (type: UserType) => {
    switch (type) {
      case "STUDENT":
        return userApi
          .searchStudents({ ...searchQuery, ...categoricalFilters, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, STUDENT: { ...r, records: r.records.map(toStudentRow) } }))
          .catch(logger.handleFailure("searchStudents"));
      case "FACILITATOR":
        return userApi
          .searchFacilitators({ ...searchQuery, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, FACILITATOR: r }))
          .catch(logger.handleFailure("searchFacilitators"));
      case "SLPA":
        return userApi
          .searchSlpas({ ...searchQuery, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, SLPA: r }))
          .catch(logger.handleFailure("searchSlpas"));
      case "PROVIDER":
        return userApi
          .searchProviders({ ...searchQuery, ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, PROVIDER: { ...r, records: r.records.map(toProviderRow) } }))
          .catch(logger.handleFailure("searchProviders"));
      case "ADMIN":
        return userApi
          .searchUsers({ ...searchQuery, userTypes: ["ADMIN"], ...pageModel, ...sortModel })
          .then(r => setUsers({ ...users, ADMIN: r }))
          .catch(logger.handleFailure("searchAdmins"));
    }
  };

  useEffect(() => {
    fetchUsers(listType);
  }, [listType, filters, categoricalFilters, pageModel, sortModel]);

  const [creatingType, setCreatingType] = useState<UserType>();

  const [selectedStudents, setSelectedStudents] = useState<ExtendedStudentUser[]>([]);
  const [isEditingStudentAssignments, setIsEditingStudentAssignments] = useState(false);

  const studentAssignmentsUpdated = (updated: ExtendedStudentUser[]) => {
    const students = users.STUDENT;
    if (!students) return;
    const updatedRows = updated.map(toStudentRow);
    const merged = students.records.map(current => updatedRows.find(u => u.userId === current.userId) || current);
    setUsers({ ...users, STUDENT: { records: merged, totalCount: students.totalCount } });
    setSelectedStudents(updated);
  };

  const [editingFacilitator, setEditingFacilitator] = useState<ExtendedFacilitatorUser>();
  const [welcomeEmailFacilitator, setWelcomeEmailFacilitator] = useState<ExtendedFacilitatorUser>();
  const [deletingFacilitator, setDeletingFacilitator] = useState<ExtendedFacilitatorUser>();

  const [editingSlpa, setEditingSlpa] = useState<ExtendedSlpaUser>();
  const [welcomeEmailSlpa, setWelcomeEmailSlpa] = useState<ExtendedSlpaUser>();
  const [deletingSlpa, setDeletingSlpa] = useState<ExtendedSlpaUser>();

  const [editingProvider, setEditingProvider] = useState<ExtendedProviderUser>();

  const clearModal = () => {
    setCreatingType(undefined);
    setIsEditingStudentAssignments(false);
    setEditingFacilitator(undefined);
    setWelcomeEmailFacilitator(undefined);
    setDeletingFacilitator(undefined);
    setEditingSlpa(undefined);
    setWelcomeEmailSlpa(undefined);
    setDeletingSlpa(undefined);
    setEditingProvider(undefined);
  };

  let modalContent: ReactNode | undefined;

  const getCommonUserFormProps = (userType: UserType) => ({
    onWrite: () => {
      fetchUsers(userType);
      clearModal();
    },
    onClose: clearModal,
  });

  if (isEditingStudentAssignments) {
    modalContent = (
      <StudentAssignmentEdit students={selectedStudents} onCancel={clearModal} onSave={studentAssignmentsUpdated} />
    );
  } else if (creatingType === "STUDENT") {
    modalContent = <StudentCreateForm {...getCommonUserFormProps("STUDENT")} />;
  } else if (creatingType === "FACILITATOR" || editingFacilitator) {
    modalContent = <FacilitatorForm {...getCommonUserFormProps("FACILITATOR")} editing={editingFacilitator} />;
  } else if (creatingType === "SLPA" || editingSlpa) {
    modalContent = <SlpaForm {...getCommonUserFormProps("SLPA")} editing={editingSlpa} />;
  } else if (creatingType === "PROVIDER" || editingProvider) {
    modalContent = <ProviderForm {...getCommonUserFormProps("PROVIDER")} editing={editingProvider} />;
  } else if (creatingType === "ADMIN") {
    modalContent = <AdminForm {...getCommonUserFormProps("ADMIN")} />;
  } else if (welcomeEmailFacilitator || welcomeEmailSlpa) {
    const emailTarget = welcomeEmailFacilitator || welcomeEmailSlpa;
    const emailNoun = `Parallel welcome email to ${emailTarget!.fullName}`;
    modalContent = (
      <Confirmation
        text={`Please confirm that you want to resend the ${emailNoun} at ${emailTarget!.email}`}
        confirm={{
          icon: <EmailIcon />,
          process: welcomeEmailFacilitator
            ? () => userApi.sendFacilitatorWelcomeEmail(emailTarget!.userId)
            : () => userApi.sendSlpaWelcomeEmail(emailTarget!.userId),
          verb: "resend",
          pastTenseVerb: "resent",
          object: emailNoun,
        }}
        onFinished={clearModal}
      />
    );
  } else if (deletingFacilitator || deletingSlpa) {
    const archiveTarget = deletingFacilitator || deletingSlpa;
    const archiveNoun = deletingFacilitator
      ? `Facilitator ${archiveTarget!.fullName}`
      : `SLPA ${archiveTarget!.fullName}`;
    modalContent = (
      <Confirmation
        text={`Please confirm that you want to permanently delete facilitator ${archiveTarget!.fullName}`}
        confirm={{
          icon: <DeleteForeverIcon />,
          process: deletingFacilitator
            ? () => userApi.deleteFacilitator(archiveTarget!.userId)
            : () => userApi.deleteSlpa(archiveTarget!.userId),
          verb: "delete",
          pastTenseVerb: "deleted",
          object: archiveNoun,
          onSuccess: deletingFacilitator ? () => fetchUsers("FACILITATOR") : () => fetchUsers("SLPA"),
        }}
        onFinished={clearModal}
      />
    );
  }

  const canWrite = currentUser && ["ADMIN", "PROVIDER"].includes(currentUser.userType);

  const UserTableFooter = () =>
    canWrite ? (
      <GridFooterContainer>
        <Button
          onClick={() => setIsEditingStudentAssignments(true)}
          startIcon={<ManageAccountIcon />}
          disabled={isEmpty(selectedStudents)}
          sx={{ ml: 1.5 }}
        >
          <Typography variant="body1" fontWeight="500">
            {currentUser.userType === "ADMIN" ? "Edit Facilitators / Providers" : "Edit Facilitators"}
          </Typography>
        </Button>
        <GridFooter sx={{ border: "none" }} />
      </GridFooterContainer>
    ) : (
      <></>
    );

  const visibleUserTypes: UserType[] = filterExists([
    currentUser?.userType !== "STUDENT" && "STUDENT",
    currentUser && ["ADMIN", "PROVIDER"].includes(currentUser.userType) && "FACILITATOR",
    currentUser &&
      (currentUser.userType === "ADMIN" ||
        (currentUser.userType === "PROVIDER" && currentUser.providerType === "SLP")) &&
      "SLPA",
    currentUser?.userType === "ADMIN" && "PROVIDER",
    currentUser?.userType === "ADMIN" && "ADMIN",
  ]);

  if (!currentUser || !visibleUserTypes.includes(listType)) return <NotFoundScreen />;

  return (
    <PrimaryLayout headerContent={<UserListHeader listType={listType} />}>
      <FullStack>
        <UserListFilter
          listType={listType}
          selectedFilters={filters}
          setSelectedFilters={setFilters}
          categoricalFilters={categoricalFilters}
          setCategoricalFilters={setCategoricalFilters}
          onAdd={canWrite ? () => setCreatingType(listType) : undefined}
        />

        <Box sx={{ height: 0, width: "100%", flex: "1 1 0%" }}>
          <FullBox role="tabpanel">
            <UserDataTable
              userType={listType}
              data={users}
              onPageChange={pageModel => setPageModel(pageModel)}
              onSortModelChange={sortModel =>
                setSortModel(
                  sortModel && sortModel.length > 0
                    ? { sortField: sortModel[0].field, sortOrder: sortModel[0].order }
                    : {},
                )
              }
              onStudentSelect={setSelectedStudents}
              onFacilitatorEdit={setEditingFacilitator}
              onFacilitatorEmail={setWelcomeEmailFacilitator}
              onFacilitatorDelete={{
                fn: setDeletingFacilitator,
                isEnabled: f => currentUser?.userType === "ADMIN" || f.createdBy === currentUser?.userId,
              }}
              onSlpaEdit={setEditingSlpa}
              onSlpaEmail={setWelcomeEmailSlpa}
              onSlpaDelete={{
                fn: setDeletingSlpa,
                isEnabled: s => currentUser?.userType === "ADMIN" || s.createdBy === currentUser?.userId,
              }}
              onProviderEdit={setEditingProvider}
              footer={UserTableFooter}
            />
          </FullBox>
        </Box>
      </FullStack>

      <CenterModal isOpen={!!modalContent} onClose={clearModal}>
        {modalContent}
      </CenterModal>
    </PrimaryLayout>
  );
};

export default UserListScreen;
