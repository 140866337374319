import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty, isNull, uniqBy } from "lodash";
import AutoCompleteInput, { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { ExtendedFrontendEvent, FrontendEventType } from "@parallel/vertex/types/calendar/appointment.event.types";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { PaginatedResult, PaginateParams } from "@parallel/vertex/types/shared.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import AppointmentEventsDataTable, {
  findAppointmentUser,
} from "@/components/calendar/appointment/events/AppointmentEventsDataTable";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const INITIAL_PAGE_SIZE = 25;

const logger = initLogger("AppointmentEventsList", getLoggerContext);

const AppointmentEventsList = ({ appointment }: { appointment: ExtendedAppointment }) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);

  const [eventPage, setEventPage] = useState<PaginatedResult<ExtendedFrontendEvent> | null>();

  const [filterOptions, setFilterOptions] = useState<{ users: SelectOption[]; types: SelectOption[] }>();

  const setInitialFilterOptions = (events: ExtendedFrontendEvent[]) => {
    // only set once on first data fetch
    if (filterOptions) return;
    setFilterOptions({
      users: uniqBy(
        mapExists(events, e => {
          const user = findAppointmentUser(e, appointment);
          if (!user) return;
          return { key: user.userId, label: user.fullName };
        }),
        "key",
      ),
      types: uniqBy(
        events.map(e => ({ key: e.type, label: e.type })),
        "key",
      ),
    });
  };

  const [userFilters, setUserFilters] = useState<SelectOption[]>([]);
  const [typeFilters, setTypeFilters] = useState<SelectOption[]>([]);

  const fetchEvents = (page: PaginateParams = { pageSize: INITIAL_PAGE_SIZE }) => {
    calendarApi
      .searchAppointmentEvents(appointment.appointmentId, {
        userIds: !isEmpty(userFilters) ? userFilters.map(f => f.key) : undefined,
        eventTypes: !isEmpty(typeFilters) ? typeFilters.map(f => f.key as FrontendEventType) : undefined,
        ...page,
      })
      .then(result => {
        setEventPage(result);
        setInitialFilterOptions(result.records);
      })
      .catch(e => {
        logger.logFailure("searchAppointmentEvents", e);
        setEventPage(null);
      });
  };
  useEffect(fetchEvents, [appointment.appointmentId, userFilters, typeFilters]);

  if (isNull(eventPage))
    return (
      <Typography variant="body2" pt={2}>
        There was an unexpected error loading the appointment log summary. Please refresh the page and contact support
        if the error persists.
      </Typography>
    );

  if (!eventPage) return <LoadingScreen />;

  return (
    <FullStack pt={2} gap={2}>
      <Stack direction="row" gap={2}>
        <AutoCompleteInput
          label="User"
          options={filterOptions?.users}
          selected={userFilters}
          onSelect={setUserFilters}
          size="small"
          width={400}
        />
        <AutoCompleteInput
          label="Type"
          options={filterOptions?.types}
          selected={typeFilters}
          onSelect={setTypeFilters}
          size="small"
          width={400}
        />
      </Stack>

      <Box sx={{ width: "100%", flex: "1 1 0%", overflowY: "auto" }}>
        <AppointmentEventsDataTable
          appointment={appointment}
          events={eventPage.records}
          pagination={{
            initialPageSize: 25,
            onPageChange: fetchEvents,
            totalCount: eventPage.totalCount,
          }}
        />
      </Box>
    </FullStack>
  );
};

export default AppointmentEventsList;
