import { useContext, useState } from "react";
import { useMountEffect } from "@react-hookz/web";
import { ServiceRequirementType } from "@parallel/vertex/types/service.types";
import { SingleStudentUser, UpdateStudentBody } from "@parallel/vertex/types/user/student.types";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import StudentInfoInput from "@/components/user/student/form/StudentInfoInput";
import StudentServiceInput from "@/components/user/student/form/StudentServiceInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getInitialStudentParams, parseInfoBody, parseServicesBody } from "@/util/student.form.util";

export type StudentEditColumn = "info" | "services" | "providers" | "facilitators";

const logger = initLogger("StudentEditForm", getLoggerContext);

const StudentEditForm = ({
  column,
  student,
  onFinished,
}: {
  column: StudentEditColumn;
  student: SingleStudentUser;
  onFinished: (maybeUpdated: SingleStudentUser) => void;
}) => {
  const {
    apiStore: { serviceApi, userApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState(getInitialStudentParams(student));

  const [allRequirementTypes, setAllRequirementTypes] = useState<ServiceRequirementType[]>([]);
  useMountEffect(() => {
    if (column !== "services") return;
    serviceApi
      .searchServiceRequirementTypes()
      .then(setAllRequirementTypes)
      .catch(logger.handleFailure("searchServiceRequirementTypes"));
  });

  const onSubmit = async (body: UpdateStudentBody) => {
    const result = await userApi
      .updateStudent(student.userId, body)
      .catch(logger.handleFailureAndThrow("updateStudent", { level: "warning" }));

    onFinished(result);
  };

  const commonFormProps = {
    operationName: "update" as const,
    params,
    onSubmit,
    onCancel: () => onFinished(student),
  };

  switch (column) {
    case "info":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student info"
          formContent={<StudentInfoInput params={params} setParams={setParams} />}
          validate={parseInfoBody}
        />
      );
    case "services":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student services"
          formContent={
            <StudentServiceInput
              params={params}
              setParams={setParams}
              allRequirementTypes={allRequirementTypes}
              currentServiceCount={student.services.length}
            />
          }
          validate={params => parseServicesBody(params, allRequirementTypes)}
        />
      );
    case "facilitators":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student facilitators"
          formContent={
            <AutoCompletePageSearchInput
              label="Facilitators"
              search={keyword =>
                userApi.searchFacilitators({ keyword }).catch(logger.handleFailure("searchFacilitators"))
              }
              getOption={u => ({ key: u.userId, label: u.fullName })}
              selected={params.facilitators}
              onSelect={facilitators => setParams({ ...params, facilitators })}
            />
          }
          validate={parseInfoBody}
        />
      );
    case "providers":
      return (
        <SubmitForm
          {...commonFormProps}
          recordName="student providers"
          formContent={
            <AutoCompletePageSearchInput
              label="Providers"
              search={keyword => userApi.searchProviders({ keyword }).catch(logger.handleFailure("searchProviders"))}
              getOption={u => ({ key: u.userId, label: u.fullName })}
              selected={params.providers}
              onSelect={providers => setParams({ ...params, providers })}
            />
          }
          validate={parseInfoBody}
        />
      );
  }
};

export default StudentEditForm;
