import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { DayOfWeek, WEEK_DAYS } from "@parallel/vertex/enums/calendar.enums";
import { RecurrenceBody, RecurrenceUnit } from "@parallel/vertex/types/calendar/recurrence.types";
import { fromLuxonWeekday } from "@parallel/vertex/util/datetime.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";

const RecurrenceInput = ({
  recurrence,
  onChange,
  eventStartTime,
}: {
  recurrence: RecurrenceBody | undefined;
  onChange: (change: RecurrenceBody | undefined) => void;
  eventStartTime: DateTime;
}) => {
  const [currentEventWeekDay, setCurrentEventWeekDay] = useState<DayOfWeek>(fromLuxonWeekday(eventStartTime.weekday));

  // update day of week as event start time updates
  useEffect(() => {
    const nextEventWeekDay = fromLuxonWeekday(eventStartTime.weekday);
    setCurrentEventWeekDay(nextEventWeekDay);
    if (!recurrence) return;
    onChange({
      ...recurrence,
      daysOfWeek: [...recurrence.daysOfWeek.filter(d => d !== currentEventWeekDay), nextEventWeekDay],
    });
  }, [eventStartTime]);

  const defaultRecurrenceBody = (periodUnit: RecurrenceUnit): RecurrenceBody => ({
    periodUnit,
    periodSize: 1,
    daysOfWeek: [currentEventWeekDay],
    endsOn: eventStartTime.plus({ months: 2 }),
  });

  const processUpdate = (update: Partial<RecurrenceBody>) => {
    const updated = { ...recurrence, ...update };
    const validUpdated = updated.periodUnit ? { ...defaultRecurrenceBody(updated.periodUnit), ...updated } : undefined;

    // ensure `currentEventWeekDay` is not unset
    if (validUpdated?.periodUnit === "WEEK" && !validUpdated.daysOfWeek.includes(currentEventWeekDay))
      validUpdated.daysOfWeek.unshift(currentEventWeekDay);

    onChange(validUpdated);
  };

  return (
    <Stack gap={1}>
      <FormControl>
        <InputLabel id="recurrence-unit-select-label">Repeat</InputLabel>
        <Select
          value={recurrence?.periodUnit || ""}
          onChange={e => processUpdate({ periodUnit: e.target.value as RecurrenceUnit })}
          labelId="recurrence-unit-select-label"
          label="Repeat"
        >
          <MenuItem value="">Never</MenuItem>
          <MenuItem value={"WEEK"}>Weekly</MenuItem>
          <MenuItem value={"MONTH"}>Monthly</MenuItem>
        </Select>
      </FormControl>

      {recurrence && (
        <>
          <Grid container mt={1}>
            <Grid xs={3} alignSelf={"center"}>
              <Typography variant="body2" width={120}>
                Repeat Every
              </Typography>
            </Grid>
            <Grid xs={9}>
              <TextField
                value={recurrence.periodSize}
                onChange={e => processUpdate({ periodSize: parseInt(e.target.value) || undefined })}
                fullWidth
                InputProps={{
                  id: "recurrence-numerator-input",
                  endAdornment: (
                    <InputAdornment position="end">
                      {recurrence.periodUnit.toLowerCase()}
                      {recurrence.periodSize > 1 ? "s" : ""}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {recurrence.periodUnit === "WEEK" && (
            <Grid container>
              <Grid xs={3} alignSelf={"center"}>
                <Typography variant="body2" width={120}>
                  Repeat On
                </Typography>
              </Grid>
              <Grid xs={9}>
                <ToggleButtonGroup
                  value={recurrence.daysOfWeek}
                  onChange={(_, daysOfWeek) => processUpdate({ daysOfWeek })}
                  fullWidth
                >
                  {WEEK_DAYS.map(day => (
                    <Tooltip
                      title={
                        day === currentEventWeekDay &&
                        `If this session should not occur on ${toTitleCase(day)}s, update the 'Date' field above to a different day.`
                      }
                      key={day}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <ToggleButton value={day} disabled={day === currentEventWeekDay}>
                          {day[0]}
                        </ToggleButton>
                      </div>
                    </Tooltip>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid xs={3} alignSelf={"center"}>
              <Typography variant="body2" width={120}>
                Ends On
              </Typography>
            </Grid>
            <Grid xs={9}>
              <DatePicker
                value={recurrence.endsOn}
                onChange={endsOn => processUpdate({ endsOn: endsOn || undefined })}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

export default RecurrenceInput;
