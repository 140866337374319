import { useContext, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { pick } from "lodash";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import {
  CreateFacilitatorBody,
  ExtendedFacilitatorUser,
  createFacilitatorBodySchema,
} from "@parallel/vertex/types/user/facilitator.types";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import NameInput from "@/components/user/input/NameInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type CreateFacilitatorParams = Partial<Omit<CreateFacilitatorBody, "districtId">> & { district: SelectOption | null };

const logger = initLogger("FacilitatorForm", getLoggerContext);

const FacilitatorForm = ({
  editing,
  onWrite,
  onClose,
}: {
  editing?: ExtendedFacilitatorUser;
  onWrite: () => void;
  onClose: () => void;
}) => {
  const {
    apiStore: { institutionApi, userApi },
  } = useContext(StoreContext);

  const initialDistrictOption =
    editing?.districtId && editing?.districtName ? { key: editing.districtId, label: editing.districtName } : null;

  const [params, setParams] = useState<CreateFacilitatorParams>({
    ...pick(editing, "firstName", "lastName"),
    email: editing?.email || undefined,
    district: initialDistrictOption,
  });

  const formContent = (
    <>
      <NameInput params={params} setParams={p => setParams({ ...params, ...p })} />
      <TextField
        label="Email"
        value={params.email || ""}
        onChange={e => setParams({ ...params, email: e.target.value })}
      />
      <AutoCompletePageSearchInput
        label="District"
        search={name =>
          institutionApi
            .searchInstitutions({ name, institutionType: "DISTRICT" })
            .catch(logger.handleFailure("searchInstitutions"))
        }
        getOption={i => ({ key: i.institutionId, label: i.salesforceName })}
        selected={params.district}
        onSelect={district => setParams({ ...params, district })}
      />
      {!editing && (
        <FormControlLabel
          label="Send Welcome Email"
          control={
            <Checkbox
              checked={params.sendWelcomeEmail}
              onChange={e => setParams({ ...params, sendWelcomeEmail: e.target.checked })}
            />
          }
        />
      )}
    </>
  );

  const validate = (params: CreateFacilitatorParams) =>
    createFacilitatorBodySchema.safeParse({ ...params, districtId: params.district?.key })?.data;

  const onSubmit = async (body: CreateFacilitatorBody) => {
    if (editing) {
      await userApi
        .updateFacilitator(editing.userId, body)
        .catch(logger.handleFailureAndThrow("updateFacilitator", { level: "warning" }));
    } else {
      const result = await userApi
        .createFacilitator(body)
        .catch(logger.handleFailureAndThrow("createFacilitator", { level: "warning" }));

      if (result.welcomeEmailFailed) {
        return {
          warning: `Successfully created facilitator, but failed to send welcome email`,
        };
      }
    }
    onWrite();
  };

  return (
    <SubmitForm
      recordName="facilitator"
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default FacilitatorForm;
