import type { ApprovalStatus, FormSubmission, FormTemplate } from "@prisma/client";
import { isArray, isNumber, isString, isUndefined } from "lodash";
import { DateTime } from "luxon";
import { z } from "zod";
import { approvalStatusEnum } from "../enums/calendar.enums";
import { Override, type FirestoreTimestamp } from "./shared.types";

type FormTemplateQuestion = { key: string; question: string };

export type ExtendedFormTemplate = Override<FormTemplate, { formQuestions?: FormTemplateQuestion[] }>;

export type { FormTemplate };

type FormAnswerValue = string | string[] | number | null;

export type FormAnswers = Record<string, FormAnswerValue>;

export type ExtendedFormSubmission = Override<
  FormSubmission,
  {
    featheryFormId: string;
    formAnswers?: FormAnswers;
    appointment?: { id: string; startTime: DateTime };
    latestApproval?: { approvalId: string; approvalStatus: ApprovalStatus };
  }
>;

export type FeedbackFormAnswerResponse = { questionId?: string | undefined; answerText?: string | undefined };

export type FeedbackFormReceipt = {
  userId: string;
  receivedAt: FirestoreTimestamp;
  shownInTelehealth: boolean;
};

export enum FormType {
  BehavioralTherapyRom = "Behavioral Therapy ROM",
  CaregiverInterview = "Caregiver Interview",
  PsyTestingPlan = "PSY Testing Plan",
  SlpaObservation = "SLPA Observation",
  SlpTestingPlan = "SLP Testing Plan",
  SpecialEducationRom = "Special Education ROM",
  SpeechTherapyRom = "Speech Therapy ROM",
  StudentInterview = "Student Interview",
  TeacherInterview = "Teacher Interview",
  TestingSessionRom = "Testing Session ROM",
}

export const FEEDBACK_FORM_TITLES: string[] = [
  FormType.BehavioralTherapyRom,
  FormType.SpecialEducationRom,
  FormType.SpeechTherapyRom,
  FormType.TestingSessionRom,
];

export const searchFormSubmissionQuerySchema = z.object({
  userId: z.string().uuid(),
  clientId: z.string().uuid().optional(),
  appointmentId: z.string().uuid().optional(),
});

const transformFormAnswerValue = (value: any): FormAnswerValue | undefined => {
  if (isArray(value)) return value.map(v => v.toString());
  if (isString(value) || isNumber(value)) return value;
  return value;
};

export const formAnswersSchema = z.record(z.any()).transform(answers =>
  Object.keys(answers).reduce((currentAnswers, nextKey) => {
    const nextAnswer = transformFormAnswerValue(answers[nextKey]);
    return isUndefined(nextAnswer) ? currentAnswers : { ...currentAnswers, [nextKey]: nextAnswer };
  }, {} as FormAnswers),
);

export const createFormSubmissionBodySchema = z.object({
  clientId: z.string().uuid().optional(),
  appointmentId: z.string().uuid().optional(),
  formAnswers: formAnswersSchema.optional(),
  createdBy: z.string().uuid().optional(),
});

export type CreateFormSubmissionBody = z.infer<typeof createFormSubmissionBodySchema> & {
  approvalStatus?: ApprovalStatus;
};

export const updateFormSubmissionBodySchema = z.object({
  formAnswers: formAnswersSchema.optional(),
  approvalStatus: approvalStatusEnum.optional(),
});

export type UpdateFormSubmissionBody = z.infer<typeof updateFormSubmissionBodySchema>;
