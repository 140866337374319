import { FrontendLogBody } from "@parallel/vertex/types/logging.types";
import { BaseAPI } from "@/api/base.api";

export class LoggingAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }
  public postLog = (request: FrontendLogBody): Promise<void> => this.instance.post("/logging/frontend", request);
}

export const loggingApi = new LoggingAPI();
