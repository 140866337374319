import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EscalatorWarning from "@mui/icons-material/EscalatorWarning";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { observer } from "mobx-react-lite";
import { InterviewFormType } from "@parallel/vertex/enums/report.enums";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import { ReportContentLayout } from "@/components/report/ReportContent";
import FeatheryForm from "@/components/shared/display/FeatheryForm";
import { StyledMenu } from "@/components/shared/display/menu";
import SubHeader from "@/components/shared/layout/SubHeader";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportTestingPlan", getLoggerContext);

const ReportInterviewNotes = () => {
  const {
    reportStore: {
      currentReport: report,
      createInitialInterviewSubmission,
      submitInterviewSubmission,
      deleteInterviewSubmission,
    },
  } = useContext(StoreContext);

  const [currentTab, setCurrentTab] = useState<InterviewFormType>("teacher");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [addInterviewAnchorEl, setAddInterviewAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setAddInterviewAnchorEl(null);
  }, [currentTab, report?.formSubmissions]);

  if (!report) return <></>;

  const { formSubmissions } = report;
  const currentFormSubmission = formSubmissions[`${currentTab}Interview`];

  const onSubmit = async (formAnswers: FormAnswers) => {
    if (!currentFormSubmission) return;
    await submitInterviewSubmission({ formAnswers, currentFormSubmission, interviewType: currentTab })
      .then(() => {
        toast.success(`Successfully submitted ${currentTab} interview`);
      })
      .catch(logger.handleFailureAndThrow("submitInterviewNotes", { level: "warning" }));
  };

  const handleDeleteInterviewSubmission = () => {
    if (!currentFormSubmission) return;
    deleteInterviewSubmission({ submissionId: currentFormSubmission.submissionId, interviewType: currentTab })
      .then(() => {
        toast.success(`Successfully deleted ${currentTab} interview`);
      })
      .catch(logger.handleFailureAndThrow("deleteInterviewSubmission", { level: "warning" }));
  };
  const handleAddInterviewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddInterviewAnchorEl(event.currentTarget);
  };

  const { caregiverInterview, teacherInterview, studentInterview } = report.formSubmissions;

  const handleCreateInitialInterviewSubmission = async (interviewType: InterviewFormType) =>
    createInitialInterviewSubmission(interviewType)
      .then(() => {
        setCurrentTab(interviewType);
        setAddInterviewAnchorEl(null);
      })
      .catch(logger.handleFailureAndThrow("createInitialInterviewSubmission", { level: "warning" }));

  return (
    <ReportContentLayout title="Interviews">
      <SubHeader
        navigation={{
          tabs: [
            { key: "teacher", label: "Teacher", icon: <SchoolIcon /> },
            { key: "caregiver", label: "Parent", icon: <EscalatorWarning /> },
            { key: "student", label: "Student", icon: <PersonIcon /> },
          ],
          onChange: e => setCurrentTab(e),
          currentKey: currentTab,
        }}
        actions={
          <>
            <Button onClick={handleAddInterviewClick} endIcon={<ArrowDropDownIcon />}>
              Add Interview
            </Button>
            <StyledMenu
              anchorEl={addInterviewAnchorEl}
              open={addInterviewAnchorEl !== null}
              onClose={() => setAddInterviewAnchorEl(null)}
            >
              <MenuItem
                disabled={!!teacherInterview}
                onClick={() => handleCreateInitialInterviewSubmission("teacher")}
                disableRipple
              >
                Teacher
              </MenuItem>
              <MenuItem
                disabled={!!caregiverInterview}
                onClick={() => handleCreateInitialInterviewSubmission("caregiver")}
                disableRipple
              >
                Parent
              </MenuItem>
              <MenuItem
                disabled={!!studentInterview}
                onClick={() => handleCreateInitialInterviewSubmission("student")}
                disableRipple
              >
                Student
              </MenuItem>
            </StyledMenu>
          </>
        }
      />
      {currentFormSubmission ? (
        <>
          <Stack direction="row" justifyContent={"flex-end"} display="flex" marginTop={1}>
            <Button
              color="error"
              onClick={() => setShowDeleteConfirmation(true)}
              startIcon={<DeleteIcon />}
              size="large"
            >
              Delete Interview
            </Button>
          </Stack>
          <FeatheryForm
            featheryFormId={currentFormSubmission.featheryFormId}
            submissionId={currentFormSubmission.submissionId}
            onSubmit={onSubmit}
            isReadOnly={currentFormSubmission.hasAnswers}
            key={currentFormSubmission.submissionId}
          />
        </>
      ) : (
        <Box justifyContent={"center"} display={"flex"} paddingY={8}>
          <Typography variant="body1">No interview notes have been added yet.</Typography>
        </Box>
      )}

      <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
        <DialogContent>
          <DialogContentText>
            <Typography>Are you sure you want to delete this interview?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteInterviewSubmission();
              setShowDeleteConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setShowDeleteConfirmation(false)}>No</Button>
        </DialogActions>
      </Dialog>
    </ReportContentLayout>
  );
};

export default observer(ReportInterviewNotes);
