import { useContext, useEffect, useState } from "react";
import { isNull, isUndefined } from "lodash";
import AutoCompleteInput from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { TYPE_CATEGORY_CONFIG } from "@parallel/vertex/enums/calendar.enums";
import { TaskType } from "@parallel/vertex/types/calendar/time.types";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("TaskTypeInput", getLoggerContext);

const toSelectOption = (t: TaskType): SelectOption => ({
  key: t.taskTypeId,
  label: t.title,
  groupName: TYPE_CATEGORY_CONFIG[t.category].title,
});

const TaskTypeInput = ({
  providerId,
  selected,
  onSelect,
}: {
  providerId?: string;
  selected: TaskType | null;
  onSelect: (t: TaskType) => void;
}) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);

  const [taskTypes, setTaskTypes] = useState<TaskType[] | null>();
  useEffect(() => {
    setTaskTypes(undefined);
    calendarApi
      .searchIndirectTimeTypeCategories({ providerId })
      .then(categories => categories.flatMap(c => c.types))
      .catch(logger.handleFailure("searchIndirectTimeTypeCategories"))
      .then(setTaskTypes);
  }, [providerId]);

  return (
    <AutoCompleteInput
      label="Task Type"
      options={taskTypes?.map(toSelectOption)}
      selected={selected ? toSelectOption(selected) : null}
      onSelect={option => {
        const selected = taskTypes?.find(t => t.taskTypeId === option?.key);
        selected && onSelect(selected);
      }}
      loadingText={isUndefined(taskTypes) ? "Loading" : isNull(taskTypes) ? "Error Fetching Task Types" : undefined}
    />
  );
};

export default TaskTypeInput;
