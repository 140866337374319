import { ReactNode, useContext, useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupsIcon from "@mui/icons-material/Groups";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { isEmpty, isNull, sortBy } from "lodash";
import { LargeValueInput } from "@parallel/polygon/components/shared/input/value.input";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { displayInputFieldStyles } from "@parallel/polygon/util/style.util";
import { AppointmentEventSummary, UserEventSummary } from "@parallel/vertex/types/calendar/appointment.event.types";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import AppointmentEventsDataTable from "@/components/calendar/appointment/events/AppointmentEventsDataTable";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const TotalInput = styled(LargeValueInput)({
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  ...displayInputFieldStyles,
});

const ParticipantAccordionBase = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.surface.light,
  "& .Mui-disabled": {
    backgroundColor: theme.palette.surface.light,
    color: "black",
  },
  "& .MuiAccordionSummary-root.Mui-disabled": {
    opacity: 1,
  },
}));

const ParticipantAccordion = ({
  appointment,
  icon,
  title,
  events,
}: {
  appointment: ExtendedAppointment;
  icon: ReactNode;
  title: string;
  events?: UserEventSummary;
}) => {
  const allEvents = events ? sortBy([...events.errors, ...events.warnings], e => e.timestamp.toMillis()) : [];
  return (
    <ParticipantAccordionBase disabled={isEmpty(allEvents)}>
      <AccordionSummary>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row" gap={1}>
            {icon}
            <Typography variant="body1">{title}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography variant="body1">
              {events?.errors.length || 0} Errors / {events?.warnings.length || 0} Warnings
            </Typography>
            <ExpandMoreIcon sx={{ opacity: isEmpty(allEvents) ? 0 : 1 }} />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {events && <AppointmentEventsDataTable appointment={appointment} events={allEvents} omitUser={true} />}
      </AccordionDetails>
    </ParticipantAccordionBase>
  );
};

const logger = initLogger("AppointmentEventsSummary", getLoggerContext);

const AppointmentEventsSummary = ({ appointment }: { appointment: ExtendedAppointment }) => {
  const {
    apiStore: { calendarApi },
  } = useContext(StoreContext);

  const [eventSummary, setEventSummary] = useState<AppointmentEventSummary | null>();
  useEffect(() => {
    calendarApi
      .getAppointmentEventSummary(appointment.appointmentId)
      .catch(logger.handleFailure("getAppointmentEventSummary"))
      .then(setEventSummary);
  }, [appointment.appointmentId]);

  if (isNull(eventSummary))
    return (
      <Typography variant="body2" pt={2}>
        There was an unexpected error loading the appointment log summary. Please refresh the page and contact support
        if the error persists.
      </Typography>
    );

  if (!eventSummary) return <LoadingScreen />;

  return (
    <FullStack pt={2} gap={6}>
      <Box>
        <Typography variant="h3" pb={2}>
          Totals
        </Typography>
        <Paper elevation={1} sx={{ py: 2, px: 8, bgcolor: "surface.light", borderRadius: "6px" }}>
          <Stack direction="row" gap={8} justifyContent="space-around">
            <Box>
              <Typography variant="h3">All Events</Typography>
              <TotalInput value={eventSummary.eventTotals.all} disabled={true} />
            </Box>
            <Box>
              <Typography variant="h3">Errors</Typography>
              <TotalInput value={eventSummary.eventTotals.errors} disabled={true} />
            </Box>
            <Box>
              <Typography variant="h3">Warnings</Typography>
              <TotalInput value={eventSummary.eventTotals.warnings} disabled={true} />
            </Box>
          </Stack>
        </Paper>
      </Box>

      <Box>
        <Typography variant="h3" pb={2}>
          Participants
        </Typography>
        <ParticipantAccordion
          appointment={appointment}
          icon={<AccountCircleIcon />}
          title={appointment.provider.fullName}
          events={eventSummary.providerEvents}
        />
        {appointment.students.map(student => (
          <ParticipantAccordion
            appointment={appointment}
            icon={<GroupsIcon />}
            title={student.fullName}
            events={eventSummary.studentEvents[student.userId]}
            key={student.userId}
          />
        ))}
      </Box>
    </FullStack>
  );
};

export default AppointmentEventsSummary;
