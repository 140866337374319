import { useContext, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty, partition } from "lodash";
import { observer } from "mobx-react-lite";
import {
  ReportEditorSection,
  ReportEditorSubsection,
  SingleReport,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import AddSubsectionInput from "@/components/report/editor/AddSubsectionInput";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";
import { getDataSectionTitle, isSectionVisible, REPORT_DATA_SECTIONS, ReportMenuSelection } from "@/util/report.util";

type SidebarMenuItem = {
  id: string;
  label: string;
  onSelect?: () => void;
  isSelected?: boolean;
  isHidden?: boolean;
  onAdd?: () => void;
};

type ReportSidebarMenuProps = { header: SidebarMenuItem; items: SidebarMenuItem[]; isSortable?: boolean };

const ReportSidebarItem = ({
  item,
  isHeader,
  isSortable,
}: {
  item: SidebarMenuItem;
  isHeader?: boolean;
  isSortable?: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable(item);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={isSortable ? "center" : "start"}
      sx={{
        p: 1,
        pl: isHeader ? 1 : 2,
        borderLeft: 2,
        bgcolor: item.isSelected ? "highlight.main" : undefined,
        borderColor: item.isSelected ? "primary.main" : "background.default",
        "&:hover": !item.isSelected && item.onSelect ? { borderColor: "primary.light" } : undefined,
        cursor: item.onSelect ? "pointer" : undefined,
      }}
      onClick={item.onSelect}
      ref={setNodeRef}
      style={style}
    >
      <Typography
        variant={isHeader ? "subtitle2" : "body2"}
        sx={{ textDecoration: item.isHidden ? "line-through" : undefined }}
      >
        {item.label}
      </Typography>
      {item.onAdd && (
        <IconButton size="small" onClick={item.onAdd}>
          <AddIcon />
        </IconButton>
      )}
      {isSortable && (
        <button {...listeners} {...attributes}>
          <DragHandleIcon sx={{ color: "grey.600" }} />
        </button>
      )}
    </Stack>
  );
};

const ReportSidebarMenu = ({ header, items, isSortable }: ReportSidebarMenuProps) => {
  const {
    reportStore: { updateSubsectionOrder },
  } = useContext(StoreContext);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) return;
    updateSubsectionOrder(active.id.toString(), over.id.toString());
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Stack>
        <ReportSidebarItem isHeader item={{ ...header, label: header.label.toUpperCase() }} />

        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <Stack>
            {items.map((item, i) => (
              <ReportSidebarItem item={item} isSortable={isSortable} key={i} />
            ))}
          </Stack>
        </SortableContext>
      </Stack>
    </DndContext>
  );
};

const getEditorMenuItem = (
  report: SingleReport,
  section: ReportEditorSection,
  onMenuSelect: (s: ReportMenuSelection) => void,
  selectedSectionTemplateId: string | undefined,
): SidebarMenuItem | undefined => {
  if (!isSectionVisible(report, section)) return;
  return {
    id: section.reportSectionTemplateId,
    label: section.title,
    onSelect: () => onMenuSelect({ type: "editor", sectionTemplateId: section.reportSectionTemplateId }),
    isSelected: section.reportSectionTemplateId === selectedSectionTemplateId,
    isHidden: section.custom?.isHidden,
  };
};

const ReportSidebar = () => {
  const {
    reportStore: { currentReport, menuSelection, setMenuSelection },
  } = useContext(StoreContext);

  const [addSubsectionOptions, setAddSubsectionOptions] = useState<ReportEditorSubsection[]>();

  if (!currentReport) return <></>;

  const dataMenuItems = REPORT_DATA_SECTIONS.map(section => ({
    id: section,
    label: getDataSectionTitle(section),
    onSelect: () => setMenuSelection({ type: "data", section }),
    isSelected: menuSelection.type === "data" && menuSelection.section === section,
  }));

  const selectedSectionTemplateId = menuSelection.type === "editor" ? menuSelection.sectionTemplateId : undefined;

  const editorMenuProps: ReportSidebarMenuProps[] = mapExists(currentReport?.editorSections, section => {
    const parentItem = getEditorMenuItem(currentReport, section, setMenuSelection, selectedSectionTemplateId);
    if (!parentItem) return;

    const subsections = mapExists(
      section.children,
      child =>
        child.type === "subsection" && {
          subsection: child,
          menuItem: getEditorMenuItem(currentReport, child, setMenuSelection, selectedSectionTemplateId),
        },
    );
    const [showSubsections, hideSubsections] = partition(subsections, s => !!s.menuItem);
    const onAdd = !isEmpty(hideSubsections)
      ? () => setAddSubsectionOptions(hideSubsections.map(s => s.subsection))
      : undefined;

    return {
      header: { ...parentItem, onAdd },
      items: mapExists(showSubsections, s => s.menuItem),
      isSortable: true,
    };
  });

  return (
    <Stack gap={2}>
      <ReportSidebarMenu header={{ id: "data-header", label: "Data" }} items={dataMenuItems} />

      {!isEmpty(editorMenuProps) && !isEmpty(currentReport?.testUploads) && (
        <>
          <Divider />
          {editorMenuProps.map((menuProps, i) => (
            <ReportSidebarMenu {...menuProps} key={i} />
          ))}
        </>
      )}

      <CenterModal isOpen={!!addSubsectionOptions} onClose={() => setAddSubsectionOptions(undefined)}>
        {addSubsectionOptions && (
          <AddSubsectionInput options={addSubsectionOptions} onFinished={() => setAddSubsectionOptions(undefined)} />
        )}
      </CenterModal>
    </Stack>
  );
};

export default observer(ReportSidebar);
