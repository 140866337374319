import { captureMessage } from "@sentry/react";
import { AxiosError } from "axios";
import { DateTime } from "luxon";
import { FrontendLogBody } from "@parallel/vertex/types/logging.types";
import { FriendlyApiError, NexusError } from "@parallel/vertex/util/nexus.base.api";

type WriteLogOptions = {
  context?: any;
  causedBy?: any;
  sentryIssueLevel?: "error" | "warning";
};

type WriteLogFn = (message: string, options?: WriteLogOptions) => void;

type HandleFailureOptions = {
  context?: any;
  level?: "error" | "warning";
};

type BasicClientLogger = {
  info: WriteLogFn;
  warn: WriteLogFn;
  error: WriteLogFn;
};

export type ClientLogger = BasicClientLogger & {
  logFailure: (operationName: string, error: any, options?: HandleFailureOptions) => void;
  handleFailure: (operationName: string, options?: HandleFailureOptions) => (error: any) => null;
  handleFailureAndReturn: <A>(
    operationName: string,
    returnValue: A,
    options?: HandleFailureOptions,
  ) => (error: any) => A;
  handleFailureAndThrow: (operationName: string, options?: HandleFailureOptions) => (error: any) => never;
};

type ClientLoggerOptions = {
  source: "Pathway" | "Telehealth" | "Org";
  tag: string;
  postLog: (log: FrontendLogBody) => Promise<void>;
  getCommonContext?: () => any;
};

const getWriteLogFn =
  (level: "info" | "warn" | "error", { source, tag, postLog, getCommonContext = () => {} }: ClientLoggerOptions) =>
  (message: string, options?: WriteLogOptions) => {
    const context = {
      ...getCommonContext(),
      ...options?.context,
    };
    if (options?.causedBy) context.causedBy = options.causedBy.message;

    const taggedMessage = `[${tag}] ${message}`;
    console[level](taggedMessage, context);

    postLog({
      source,
      level,
      tag,
      message,
      payload: {
        timestamp: DateTime.utc(),
        ...context,
      },
    }).catch(e => {
      console.warn(`failed to post log: ${message}`);
      captureMessage("failed to post log", {
        level: "warning",
        contexts: { log: { message }, logger: context, cause: { message: e.message } },
      });
    });

    if (options?.sentryIssueLevel) {
      captureMessage(taggedMessage, {
        level: options.sentryIssueLevel,
        contexts: { logger: context },
      });
    }
  };

const getFailureLogFn =
  (logger: BasicClientLogger) =>
  (operationName: string, error: any, { context, level = "error" }: HandleFailureOptions = {}) => {
    if (error instanceof FriendlyApiError || error instanceof NexusError) error = error.causedBy;
    if (error instanceof AxiosError || error?.name === "AxiosError") {
      logger.warn(`http error performing ${operationName}: ${error.message}`, {
        ...context,
        response: error.response,
        operationName,
      });
    } else {
      const message = `unexpected error performing ${operationName}`;
      const logContext = { ...context, operationName };
      switch (level) {
        case "warning":
          return logger.warn(message, { context: logContext, causedBy: error?.message });
        case "error":
          return logger.error(message, { context: logContext, causedBy: error?.message, sentryIssueLevel: "error" });
      }
    }
  };

export const initClientLogger = (options: ClientLoggerOptions): ClientLogger => {
  const basicLogger = {
    info: getWriteLogFn("info", options),
    warn: getWriteLogFn("warn", options),
    error: getWriteLogFn("error", options),
  };

  const logFailure = getFailureLogFn(basicLogger);

  const handleFailureAndReturn =
    <A>(operationName: string, returnValue: A, options?: HandleFailureOptions) =>
    (error: any) => {
      logFailure(operationName, error, options);
      return returnValue;
    };

  const handleFailure = (operationName: string, options?: HandleFailureOptions) =>
    handleFailureAndReturn(operationName, null, options);

  const handleFailureAndThrow = (operationName: string, options?: HandleFailureOptions) => (error: any) => {
    logFailure(operationName, error, options);
    throw error;
  };

  return {
    ...basicLogger,
    logFailure,
    handleFailure,
    handleFailureAndReturn,
    handleFailureAndThrow,
  };
};
