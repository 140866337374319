import { makeAutoObservable, runInAction } from "mobx";
import { ProgressUpdateOperation, resolveObjectiveCompletionToggleUpdate } from "@parallel/polygon/util/progress.util";
import { StudentGoal, StudentObjective, UpdateGoalBody } from "@parallel/vertex/types/progress.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { ProgressAPI } from "@/api/progress.api";

type ProgressUpdate = {
  operation: ProgressUpdateOperation;
  goal: { record: StudentGoal; displayIndex: number };
  objective?: { record: StudentObjective; displayIndex: number };
};

export class ProgressStore {
  pendingUpdate?: ProgressUpdate = undefined;

  constructor(private progressApi: ProgressAPI) {
    makeAutoObservable(this);
  }

  clearPendingUpdate = () => {
    this.pendingUpdate = undefined;
  };

  startPendingUpdate = (
    operation: ProgressUpdateOperation,
    goal: { record: StudentGoal; displayIndex: number },
    objective?: { record: StudentObjective; displayIndex: number },
  ) => {
    this.pendingUpdate = { operation, goal, objective };
  };

  public updateGoal = async (studentId: string, goalId: string, update: UpdateGoalBody) => {
    const updated = await this.progressApi.updateStudentGoal(studentId, goalId, update);
    runInAction(() => (this.pendingUpdate = undefined));

    return updated;
  };

  public setGoalCompleted = async ({
    goal,
    studentId,
    isCompleted,
  }: {
    studentId: string;
    goal: StudentGoal;
    isCompleted: boolean;
  }) =>
    this.updateGoal(studentId, goal.goalId, {
      isCompleted,
      objectives: mapExists(
        goal.objectives,
        o => !!o.completedAt !== isCompleted && { objectiveId: o.objectiveId, isCompleted },
      ),
    });

  public setObjectiveCompleted = async ({
    goal,
    objectiveId,
    studentId,
    isCompleted,
  }: {
    goal: StudentGoal;
    objectiveId: string;
    studentId: string;
    isCompleted: boolean;
  }) => {
    const update = resolveObjectiveCompletionToggleUpdate(objectiveId, isCompleted, goal);
    return this.updateGoal(studentId, goal.goalId, update);
  };

  public setGoalArchived = async ({
    goal,
    studentId,
    isArchived,
  }: {
    goal: StudentGoal;
    studentId: string;
    isArchived: boolean;
  }) =>
    this.updateGoal(studentId, goal.goalId, {
      isArchived,
      objectives: mapExists(
        goal.objectives,
        o => (o.isArchived ?? false) !== isArchived && { objectiveId: o.objectiveId, isArchived },
      ),
    });

  public setObjectiveArchived = async ({
    goal,
    objectiveId,
    studentId,
    isArchived,
  }: {
    goal: StudentGoal;
    objectiveId: string;
    studentId: string;
    isArchived: boolean;
  }) =>
    this.updateGoal(studentId, goal.goalId, {
      objectives: [{ objectiveId, isArchived }],
    });
}
