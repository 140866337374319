import { isEmpty, isNull, isUndefined } from "lodash";
import { DateTime } from "luxon";
import { RefinementCtx, z, ZodIssueCode } from "zod";
import { AVAILABLE_LANGUAGES, ORDERED_REQUIREMENT_CADENCES } from "../enums/user.enums";
import { filterExists } from "../util/collection.util";
import { dateTimeClass, requiredNameSchema, positiveIntegerSchema, optionalEmailSchema } from "./shared.types";

// student info fields (common across all service types)

const parseDateString = (s: string) => {
  if (!s) return;

  const iso = DateTime.fromISO(s);
  if (iso.isValid) return iso;

  const slashedString = s.replace(/-/g, "/");

  const noPaddingNumericDate = DateTime.fromFormat(slashedString, "M/d/yyyy");
  if (noPaddingNumericDate.isValid) return noPaddingNumericDate;

  const paddedNumericDate = DateTime.fromFormat(slashedString, "MM/dd/yyyy");
  if (paddedNumericDate.isValid) return paddedNumericDate;

  return null;
};

const dateField = z
  .union([dateTimeClass, z.string().transform(parseDateString)])
  .superRefine((maybeDate, ctx): maybeDate is DateTime => {
    if (isNull(maybeDate)) {
      ctx.addIssue({
        message: "Date is incorrectly formatted - supported formats: 2/9/2024, 02/09/20204, 09-02-2024",
        code: z.ZodIssueCode.invalid_date,
      });
    } else if (isUndefined(maybeDate)) {
      ctx.addIssue({
        message: "Required",
        code: ZodIssueCode.custom,
      });
    }
    return z.NEVER;
  });

export const studentInfoRowFields = {
  firstName: requiredNameSchema,
  lastName: requiredNameSchema,
  birthDate: dateField,
  campusId: z.string().uuid(),
  email: optionalEmailSchema,
  facilitatorId: z.string().uuid().optional(),
  providerId: z.string().uuid().optional(),
  language: z.enum(AVAILABLE_LANGUAGES),
};

export const studentInfoRowSchema = z.object(studentInfoRowFields);
export type StudentInfoRow = z.infer<typeof studentInfoRowSchema>;

const studentInfoRelatedUserRefinement = ({ facilitatorId, providerId }: StudentInfoRow, ctx: RefinementCtx) => {
  if (!facilitatorId && !providerId) {
    // throw new Error("Either facilitator or provider email address is required");
    return ctx.addIssue({
      message: "Either facilitator or provider is required",
      code: z.ZodIssueCode.custom,
    });
  }
};

// hourly service row fields

export const hourlyServiceRowFields = {
  serviceLineId: z.string().uuid(),
  serviceLanguage: z.enum(AVAILABLE_LANGUAGES),
  reevaluationDueDate: dateField,
  parallelServiceStart: dateField,
  parallelServiceEnd: dateField,
  annualIepDueDate: dateField,
};

export const cadenceFields = {
  minutes: positiveIntegerSchema,
  unit: z.enum(ORDERED_REQUIREMENT_CADENCES),
  frequency: positiveIntegerSchema,
};

export const cadenceParamsSchema = z.object(cadenceFields);
export type CadenceParams = z.infer<typeof cadenceParamsSchema>;

export const CADENCE_TYPES = ["compensantory", "directService", "consult"] as const;
export type CadenceType = (typeof CADENCE_TYPES)[number];

const serviceCadencesSchema = z.record(z.enum(CADENCE_TYPES), cadenceParamsSchema).superRefine((data, ctx) => {
  if (isEmpty(filterExists(Object.values(data)))) {
    return ctx.addIssue({
      message: "Must provide at least one valid minutes cadence configuration",
      code: z.ZodIssueCode.custom,
    });
  }
});
export const hourlyServiceStudentRowSchema = studentInfoRowSchema
  .extend(hourlyServiceRowFields)
  .extend({ cadences: serviceCadencesSchema })
  .superRefine(studentInfoRelatedUserRefinement);

export type HourlyServiceStudentRow = z.infer<typeof hourlyServiceStudentRowSchema>;

// assessment service row fields

export const assessmentServiceRowFields = {
  serviceLineId: z.string().uuid(),
  assessmentLanguage: z.enum(AVAILABLE_LANGUAGES),
  assessmentDueDate: dateField,
  consentSignedDate: dateField.optional(),
};

export const assessmentServiceStudentRowSchema = studentInfoRowSchema
  .extend(assessmentServiceRowFields)
  .extend({ providerId: z.string().uuid() });

export type AssessmentServiceStudentRow = z.infer<typeof assessmentServiceStudentRowSchema>;

// submission request/response types

export const studentRowUploadBodySchema = z.union([
  z.object({
    serviceType: z.literal("HOURLY"),
    data: hourlyServiceStudentRowSchema,
  }),
  z.object({
    serviceType: z.literal("ASSESSMENT"),
    data: assessmentServiceStudentRowSchema,
  }),
]);
export type StudentRowUploadBody = z.infer<typeof studentRowUploadBodySchema>;

export const studentUploadBodySchema = z.object({ rows: studentRowUploadBodySchema.array() });
export type StudentUploadBody = z.infer<typeof studentUploadBodySchema>;

type StudentUploadSuccess = { type: "success"; studentId: string };

type StudentUploadError = { type: "error" };

export type StudentUploadResult = StudentUploadSuccess | StudentUploadError;

export type StudentUploadResponse = {
  results: StudentUploadResult[];
};

// validation response types

export type ValidationFailure = {
  message: string;
  columnKey?: string;
  metadata?: object;
};

// source data types (for parsing /  displaying google sheets data and requesting validation)

const rowValueField = z.union([z.string(), z.number(), z.boolean(), z.undefined(), z.null()]);
export type RowValue = z.infer<typeof rowValueField>;

export const studentRowSchema = z.record(z.string(), rowValueField);
export type StudentRow = z.infer<typeof studentRowSchema>;

export type StudentRowValidation = {
  failures: ValidationFailure[];
  validUploadBody?: StudentRowUploadBody;
};

export type ValidatedStudentRow = { data: StudentRow; validation: StudentRowValidation };

export type EntityReference = { id: string; name: string };

export type StudentUploadSourceResponse = {
  rows: ValidatedStudentRow[];
  campuses: EntityReference[];
  serviceLines: EntityReference[];
};
