import { useContext, useState } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { DatePicker } from "@mui/x-date-pickers";
import { pick } from "lodash";
import {
  CreateReportBody,
  createReportBodySchema,
  ExtendedAssessmentEnrollment,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import AutoCompleteFetchInput from "@/components/shared/input/AutoCompleteFetchInput";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";
import SelectInput from "@/components/shared/input/SelectInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import LanguageInput from "@/components/user/input/LanguageInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type ReportParams = Partial<CreateReportBody> & { serviceLine: SelectOption | null };

const logger = initLogger("CreateReportForm", getLoggerContext);

const CreateReportForm = ({
  enrollment,
  onCreated,
  onClose,
}: {
  enrollment: ExtendedAssessmentEnrollment;
  onCreated: (reportId: string) => void;
  onClose: () => void;
}) => {
  const {
    apiStore: { serviceApi },
    reportStore: { createReport },
  } = useContext(StoreContext);

  const { serviceLine, eligibleReviewers } = enrollment;

  const [params, setParams] = useState<ReportParams>({
    ...pick(enrollment, "serviceLineClientId", "assessmentDueDate", "consentSignedAt"),
    languages: serviceLine.languages,
    serviceLine: { key: serviceLine.serviceLineId, label: serviceLine.title },
  });

  const formContent = (
    <>
      <AutoCompleteFetchInput
        label="Service Type"
        params={undefined}
        fetchOptions={() =>
          serviceApi
            .searchServiceLines({ serviceType: "ASSESSMENT" })
            .then(ss => ss.map(s => ({ key: s.serviceLineId, label: s.title })))
            .catch(logger.handleFailure("searchServiceLines"))
        }
        selected={params.serviceLine}
        onSelect={serviceLine => setParams({ ...params, serviceLine })}
      />
      <LanguageInput params={params} setParams={setParams} />
      <SelectInput
        label="Reviewer"
        options={eligibleReviewers.map(r => ({ key: r.userId, label: r.name }))}
        value={params.reviewerId || ""}
        onChange={reviewerId => setParams({ ...params, reviewerId })}
      />
      <DatePicker
        label="Assessment Due Date"
        value={params.assessmentDueDate}
        onChange={d => setParams({ ...params, assessmentDueDate: d || undefined })}
      />
      <DatePicker
        label="Consent Signed Date (Optional)"
        value={params.consentSignedAt}
        onChange={d => setParams({ ...params, consentSignedAt: d || undefined })}
      />
    </>
  );

  const validate = (params: ReportParams) => {
    return createReportBodySchema.safeParse({
      ...params,
      serviceLineId: params.serviceLine?.key,
    })?.data;
  };

  const onSubmit = async (body: CreateReportBody) => {
    const report = await createReport(body).catch(logger.handleFailureAndThrow("createReport", { level: "warning" }));
    onCreated(report.reportId);
  };

  return (
    <SubmitForm
      recordName="report"
      operationName="create"
      subheaderContent={
        <Stack direction="row" gap={1}>
          <GroupsIcon />
          <Typography variant="body1">{enrollment.client.fullName}</Typography>
        </Stack>
      }
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
    />
  );
};

export default CreateReportForm;
