import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { grey } from "@mui/material/colors";
import { isEqual } from "lodash";

export type FilterProperty = {
  propertyName: string;
  searchParamKey: string;
};

export type FilterOption = {
  filterName: string;
  searchParamKey: string;
  searchParamValue: string;
};

const FilterSearchInput = ({
  properties,
  selectedOptions,
  setSelectedOptions,
  width,
}: {
  properties: FilterProperty[];
  selectedOptions: FilterOption[];
  setSelectedOptions: (fs: FilterOption[]) => void;
  width?: number;
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!inputValue);
  }, [inputValue]);

  let options: FilterOption[] = [];
  if (inputValue) {
    options = properties.map(({ propertyName, searchParamKey }) => ({
      filterName: `${propertyName} contains "${inputValue}"`,
      searchParamKey,
      searchParamValue: inputValue,
    }));
  }

  const [filterAnchor, setFilterAnchor] = useState<HTMLElement>();

  return (
    <Autocomplete
      multiple
      disableClearable
      forcePopupIcon={false}
      sx={{ width: width ?? 350, bgcolor: "white" }}
      size="small"
      open={isOpen}
      options={options}
      getOptionLabel={option => option.filterName}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      value={selectedOptions}
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      onChange={(_event, _selected, _reason, details) => {
        const newOption = details?.option;
        if (!newOption) return;
        setSelectedOptions([...selectedOptions.filter(o => o.searchParamKey !== newOption?.searchParamKey), newOption]);
      }}
      renderTags={() => null}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Search ..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  edge="end"
                  onClick={e => selectedOptions.length > 0 && setFilterAnchor(e.currentTarget)}
                >
                  <Badge
                    badgeContent={selectedOptions.length}
                    invisible={selectedOptions.length === 0}
                    overlap="circular"
                    color="primary"
                    variant="dot"
                  >
                    <Tooltip title={`${selectedOptions.length || "No"} Active Filters`}>
                      <FilterAltIcon sx={{ color: grey[400] }} />
                    </Tooltip>
                  </Badge>
                </IconButton>
                <Menu anchorEl={filterAnchor} open={!!filterAnchor} onClose={() => setFilterAnchor(undefined)}>
                  {selectedOptions.map(option => (
                    <ListItem
                      key={`${option.searchParamKey}:${option.searchParamValue}`}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          onClick={() => {
                            const updated = selectedOptions.filter(o => !isEqual(o, option));
                            if (updated.length === 0) setFilterAnchor(undefined);
                            setSelectedOptions(updated);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <Typography variant="body1">{option.filterName}</Typography>
                    </ListItem>
                  ))}
                </Menu>
              </InputAdornment>
            ),
            endAdornment: !!inputValue && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setInputValue("")}>
                  <CloseIcon fontSize="small" sx={{ color: grey[400] }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default FilterSearchInput;
