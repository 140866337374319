import { useContext } from "react";
import { FadeLoader } from "react-spinners";
import { FullBox, FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import { StoreContext } from "@/stores";

const LoadingScreen = () => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const content = (
    <FullCenterBox>
      <FadeLoader />
    </FullCenterBox>
  );

  if (!currentUser) return <FullBox p={2}>{content}</FullBox>;

  return <PrimaryLayout>{content}</PrimaryLayout>;
};

export default LoadingScreen;
