import type { LanguageType, ProviderType, RequirementCadence } from "@prisma/client";
import { z } from "zod";

export type { LanguageType, UserType, ProviderType } from "@prisma/client";

export const userTypeEnum = z.enum(["ADMIN", "FACILITATOR", "PROVIDER", "SLPA", "STUDENT"]);

export const ALL_PROVIDER_TYPES = ["PSY", "SEI", "SSW", "SLP"] as const;

export const providerTypeEnum = z.enum(ALL_PROVIDER_TYPES);

export const FRIENDLY_PROVIDER_TYPES: Record<ProviderType, string> = {
  PSY: "School Psychologist",
  SEI: "Special Education Teacher",
  SSW: "School Social Worker",
  SLP: "Speech Language Pathologist",
};

export const ALL_CONTRACT_TYPES = ["CONTRACTOR", "EMPLOYEE"] as const;

export const contractTypeEnum = z.enum(ALL_CONTRACT_TYPES);

export const ORDERED_LANGUAGES = [
  "en_US",
  "es_US",
  "ar_AR",
  "de_DE",
  "fil_PH",
  "fr_FR",
  "it_IT",
  "ru_RU",
  "zh_CN",
] as const;

export const AVAILABLE_LANGUAGES = ["en_US", "es_US"] as const;

type LanguageNames = {
  [key in LanguageType]: string;
};

export const LANGUAGE_NAMES: LanguageNames = {
  ar_AR: "Arabic",
  de_DE: "German",
  en_US: "English",
  es_US: "Spanish",
  fil_PH: "Filipino",
  fr_FR: "French",
  it_IT: "Italian",
  ru_RU: "Russian",
  zh_CN: "Chinese",
} as const;

export const AVAILABLE_LANGUAGE_NAMES = ["English", "Spanish"] as const;

export const ORDERED_REQUIREMENT_CADENCES = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"] as const;

export const REQUIREMENT_CADENCE_NOUNS: Record<RequirementCadence, string> = {
  DAILY: "day",
  WEEKLY: "week",
  MONTHLY: "month",
  YEARLY: "year",
};
