import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { capitalize } from "lodash";
import { RecurrenceEditMode } from "@parallel/vertex/enums/calendar.enums";
import { RecordName } from "@/util/shared.util";

const RecurrenceEditModeInput = <P extends { recurrenceEditMode?: RecurrenceEditMode }>({
  params,
  setParams,
  actionName,
  recordName,
}: {
  params: P;
  setParams: (p: P) => void;
  actionName: "update" | "delete";
  recordName: RecordName;
}) => {
  return (
    <RadioGroup
      value={params.recurrenceEditMode}
      onChange={e => setParams({ ...params, recurrenceEditMode: e.target.value as RecurrenceEditMode })}
    >
      <FormControlLabel
        value="single"
        control={<Radio />}
        label={`${capitalize(actionName)} this ${recordName.singular}`}
      />
      <FormControlLabel
        value="future"
        control={<Radio />}
        label={`${capitalize(actionName)} this and all following ${recordName.plural}`}
      />
    </RadioGroup>
  );
};

export default RecurrenceEditModeInput;
