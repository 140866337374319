import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { min } from "lodash";
import { PaginateState } from "@parallel/vertex/types/shared.types";

const PaginationControl = ({
  pageState,
  setPageState,
  totalCount,
}: {
  pageState: PaginateState;
  setPageState: (s: PaginateState) => void;
  totalCount: number;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      sx={{ borderTop: 1, borderColor: "grey.300", py: 1 }}
    >
      <Typography variant="subtitle2" pr={1}>
        {1 + pageState.offset}-{min([pageState.pageSize + pageState.offset, totalCount])} of {totalCount}
      </Typography>
      <IconButton
        onClick={() => setPageState({ ...pageState, offset: pageState.offset - pageState.pageSize })}
        disabled={pageState.offset === 0}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        onClick={() => setPageState({ ...pageState, offset: pageState.offset + pageState.pageSize })}
        disabled={pageState.offset + pageState.pageSize > totalCount}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Stack>
  );
};

export default PaginationControl;
