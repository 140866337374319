import { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { get, isNull, isUndefined } from "lodash";
import { DateTime } from "luxon";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { ORDERED_REQUIREMENT_CADENCES, REQUIREMENT_CADENCE_NOUNS } from "@parallel/vertex/enums/user.enums";
import { RequirementCadence, ServiceLine, ServiceRequirementType } from "@parallel/vertex/types/service.types";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { StudentServiceCadenceRequest } from "@parallel/vertex/types/user/student.types";
import { toUtcDate } from "@parallel/vertex/util/datetime.util";
import { getProductCodeProviderType, getTotalCadenceMinutes } from "@parallel/vertex/util/service.util";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import {
  DEFAULT_CADENCE_SIZE,
  DEFAULT_CADENCE_UNIT,
  StudentServiceParams,
  StudentParams,
  getDuplicateAssessmentProviderIds,
} from "@/util/student.form.util";

const logger = initLogger("StudentServiceRequirementInput", getLoggerContext);

const getFieldLabel = (requirementType: ServiceRequirementType) =>
  `${requirementType.title}${requirementType.isRequired ? "" : " (optional)"}`;

const CadenceRequirementInput = ({
  requirementType,
  value,
  onChange,
  timeRange,
}: {
  requirementType: ServiceRequirementType;
  value: Partial<StudentServiceCadenceRequest> | null;
  onChange: (value: Partial<StudentServiceCadenceRequest> | null) => void;
  timeRange?: DateTimeRange;
}) => {
  const totalMinutes =
    value?.minutes && timeRange
      ? getTotalCadenceMinutes(
          value.minutes,
          value.unit || DEFAULT_CADENCE_UNIT,
          value.size || DEFAULT_CADENCE_SIZE,
          timeRange,
        )
      : null;

  return (
    <Stack direction="row" alignItems="center" gap={2} width="100%">
      <TextField
        label={getFieldLabel(requirementType)}
        value={value?.minutes || ""}
        onChange={e => onChange({ minutes: parseInt(e.target.value) })}
        type="number"
        error={(value?.minutes || NaN) < 0}
        sx={{ flex: 1 }}
        disabled={isNull(value) || !!value.totalMinutesOverride}
      />
      <Typography>every</Typography>
      <TextField
        value={value?.size || DEFAULT_CADENCE_SIZE}
        onChange={e => onChange({ size: parseInt(e.target.value) })}
        type="number"
        error={(value?.size || NaN) < 0}
        sx={{ width: 80 }}
        disabled={isNull(value) || !!value.totalMinutesOverride}
      />
      <Select
        value={value?.unit || DEFAULT_CADENCE_UNIT}
        onChange={e => onChange({ unit: e.target.value as RequirementCadence })}
        sx={{ width: 100 }}
        disabled={isNull(value) || !!value.totalMinutesOverride}
      >
        {ORDERED_REQUIREMENT_CADENCES.map(cadence => (
          <MenuItem value={cadence} key={cadence}>
            {REQUIREMENT_CADENCE_NOUNS[cadence]}s
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Total Minutes"
        value={(isUndefined(value?.totalMinutesOverride) ? totalMinutes : value.totalMinutesOverride) || " "}
        sx={{ width: 120 }}
        onChange={e => onChange({ totalMinutesOverride: parseInt(e.target.value) })}
        InputProps={{
          endAdornment: !isUndefined(value?.totalMinutesOverride) ? (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => onChange({ totalMinutesOverride: undefined })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Stack>
  );
};

const DateRequirementInput = ({
  requirementType,
  date,
  onChange,
}: {
  requirementType: ServiceRequirementType;
  date: DateTime | null;
  onChange: (d: DateTime | null) => void;
}) => {
  return (
    <DatePicker label={getFieldLabel(requirementType)} value={date} onChange={d => onChange(d ? toUtcDate(d) : null)} />
  );
};

const ProviderRequirementInput = ({
  serviceLine,
  requirementType,
  selected,
  onSelect,
  errorMessage,
}: {
  serviceLine?: ServiceLine;
  requirementType: ServiceRequirementType;
  selected: SelectOption | null;
  onSelect: (selected: SelectOption | null) => void;
  errorMessage?: string;
}) => {
  const {
    apiStore: { userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const providerType = serviceLine ? getProductCodeProviderType(serviceLine.productCode) : undefined;

  return (
    <AutoCompletePageSearchInput
      label={getFieldLabel(requirementType)}
      search={keyword =>
        userApi.searchProviders({ keyword, providerType }).catch(logger.handleFailure("searchProviders"))
      }
      getOption={p => ({ key: p.userId, label: p.fullName })}
      selected={selected}
      onSelect={onSelect}
      disabled={currentUser?.userType === "PROVIDER"}
      error={errorMessage}
    />
  );
};

const SlpaRequirementInput = ({
  requirementType,
  campus,
  selected,
  onSelect,
}: {
  requirementType: ServiceRequirementType;
  campus: SelectOption | null;
  selected: SelectOption | null;
  onSelect: (selected: SelectOption | null) => void;
}) => {
  const {
    apiStore: { userApi },
  } = useContext(StoreContext);
  return (
    <AutoCompletePageSearchInput
      label={getFieldLabel(requirementType)}
      search={keyword =>
        userApi.searchSlpas({ keyword, campusId: campus?.key }).catch(logger.handleFailure("searchSlpas"))
      }
      getOption={p => ({ key: p.userId, label: p.fullName })}
      selected={selected}
      onSelect={onSelect}
    />
  );
};

const StudentServiceRequirementInput = ({
  serviceLine,
  requirementType,
  serviceParams: serviceRequest,
  updateServiceParams: updateServiceRequest,
  allParams,
  allRequirementTypes,
  timeRange,
}: {
  serviceLine?: ServiceLine;
  requirementType: ServiceRequirementType;
  serviceParams: StudentServiceParams;
  updateServiceParams: (update: StudentServiceParams) => void;
  allParams: StudentParams;
  allRequirementTypes: ServiceRequirementType[];
  timeRange?: DateTimeRange;
}) => {
  const typeId = requirementType.serviceRequirementTypeId;
  switch (requirementType.dataType) {
    case "CADENCE":
      return (
        <CadenceRequirementInput
          requirementType={requirementType}
          value={get(serviceRequest.cadences, typeId, {})}
          onChange={update =>
            updateServiceRequest({
              cadences: {
                ...serviceRequest.cadences,
                [typeId]: isNull(update) ? update : { ...get(serviceRequest.cadences, typeId), ...update },
              },
            })
          }
          timeRange={timeRange}
        />
      );
    case "DATE":
    case "END_DATE":
    case "START_DATE":
      return (
        <DateRequirementInput
          requirementType={requirementType}
          date={get(serviceRequest.dates, typeId, null)}
          onChange={date => updateServiceRequest({ dates: { ...serviceRequest.dates, [typeId]: date } })}
        />
      );
    case "PROVIDER":
      const selected = get(serviceRequest, `users.${typeId}`, null);
      const duplicateProviderIds = getDuplicateAssessmentProviderIds(allParams, allRequirementTypes);
      const isDuplicate = selected && duplicateProviderIds.includes(selected.key);
      return (
        <ProviderRequirementInput
          serviceLine={serviceLine}
          requirementType={requirementType}
          selected={selected}
          onSelect={selected => updateServiceRequest({ users: { ...serviceRequest.users, [typeId]: selected } })}
          errorMessage={
            isDuplicate
              ? "This provider already has an assessment service line with this student. Please edit the existing service line, or archive it before adding a new one."
              : undefined
          }
        />
      );
    case "SLPA":
      return (
        <SlpaRequirementInput
          requirementType={requirementType}
          campus={allParams.campus}
          selected={get(serviceRequest, `users.${typeId}`, null)}
          onSelect={selected => updateServiceRequest({ users: { ...serviceRequest.users, [typeId]: selected } })}
        />
      );
  }
};

export default StudentServiceRequirementInput;
