import { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-premium";
import { isEqual } from "lodash";
import { StatusButton } from "@parallel/polygon/components/shared/input/status.input";
import { EntityReference, StudentRow, StudentUploadBody } from "@parallel/vertex/types/bulk.upload.types";
import { ServiceType } from "@parallel/vertex/types/service.types";
import {
  BulkUploadField,
  HOURLY_SERVICES_UPLOAD_FIELDS,
  ASSESSMENT_UPLOAD_FIELDS,
  getDataGridFieldKey,
} from "@parallel/vertex/util/bulk.upload.util";

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const EditTooltipInputCell = ({
  cellParams,
  error,
}: {
  cellParams: GridRenderEditCellParams;
  error: string | undefined;
}) => (
  <ErrorTooltip open={!!error} title={error}>
    <GridEditInputCell {...cellParams} />
  </ErrorTooltip>
);

const renderCustomTooltipEditCell = ({
  cellParams,
  error,
}: {
  cellParams: GridRenderEditCellParams;
  error: string | undefined;
}) => <EditTooltipInputCell cellParams={cellParams} error={error} />;

const StudentUploadDataGrid = ({
  initialRows,
  campuses,
  serviceLines,
  serviceType,
  onRowUpdate,
  onSubmit,
  allRowsUploadBody,
  validationErrorCount,
  toggleValidationErrors,
  isValidating,
}: {
  initialRows: StudentRow[];
  campuses: EntityReference[];
  serviceLines: EntityReference[];
  serviceType: ServiceType;
  onRowUpdate: (row: StudentRow) => void;
  onSubmit: () => void;
  allRowsUploadBody: StudentUploadBody | undefined;
  validationErrorCount: number;
  toggleValidationErrors: () => void;
  isValidating: boolean;
}) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const processRowUpdate = (updatedRow: StudentRow, oldRow: StudentRow) => {
    if (isEqual(updatedRow, oldRow)) return updatedRow;
    onRowUpdate(updatedRow);
    return updatedRow;
  };

  useEffect(() => {
    const formatColumns = (columnFields: BulkUploadField[]) =>
      columnFields.map((field: BulkUploadField): GridColDef => {
        const { headerName, editable, type, cellValidator } = field;
        let validationError = "";

        const commonGridColumn = {
          field: getDataGridFieldKey(field),
          headerName,
          editable: editable ?? true,
          preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            const validator = cellValidator;
            let hasError = false;

            if (validator) {
              try {
                validator(params.props.value);
              } catch (e: any) {
                hasError = true;
                validationError = e.issues[0]?.message;
              }

              if (!hasError) {
                validationError = "";
              }

              return {
                ...params.props,
                error: hasError,
              };
            }
            return params;
          },
        };

        if (type === "singleSelect") {
          return {
            ...commonGridColumn,
            type: "singleSelect",
            valueOptions: field.getValueOptions({ campuses, serviceLines }),
          };
        } else {
          return {
            ...commonGridColumn,
            type,
            renderEditCell: props => renderCustomTooltipEditCell({ cellParams: props, error: validationError }),
          };
        }
      });

    const columnSet = serviceType === "HOURLY" ? HOURLY_SERVICES_UPLOAD_FIELDS : ASSESSMENT_UPLOAD_FIELDS;
    setColumns(formatColumns(columnSet));
  }, [serviceType, campuses, serviceLines]);

  return (
    <DataGridPremium
      columns={columns}
      rows={initialRows}
      processRowUpdate={processRowUpdate}
      slots={{
        footer: () => (
          <CustomDataGridFooter
            allRowsUploadBody={allRowsUploadBody}
            onSubmit={onSubmit}
            validationErrorCount={validationErrorCount}
            toggleValidationErrors={toggleValidationErrors}
            isValidating={isValidating}
          />
        ),
      }}
    />
  );
};

type CustomDataGridFooterProps = {
  allRowsUploadBody: StudentUploadBody | undefined;
  onSubmit: () => void;
  validationErrorCount: number;
  toggleValidationErrors: () => void;
  isValidating: boolean;
};

const CustomDataGridFooter = ({
  allRowsUploadBody,
  onSubmit,
  validationErrorCount,
  toggleValidationErrors,
  isValidating,
}: CustomDataGridFooterProps) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
      <Stack direction="row" width="220px" alignItems="center">
        {isValidating ? (
          <Box width="130px" justifyContent={"center"} display="flex">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <StatusButton
            onClick={toggleValidationErrors}
            variant="outlined"
            color={"error"}
            disabled={validationErrorCount < 1}
          >
            Show {validationErrorCount} {validationErrorCount === 1 ? "Issue" : "Issues"}
          </StatusButton>
        )}
      </Stack>
      <StatusButton onClick={onSubmit} variant="contained" disabled={!allRowsUploadBody}>
        Submit
      </StatusButton>
    </Box>
  );
};

export default StudentUploadDataGrid;
