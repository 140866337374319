import { Container, Typography, Button, Box } from "@mui/material";

const ErrorBoundaryScreen = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "surface.main",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We are sorry for the inconvenience.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please try again later or go back to the home page.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => (window.location.href = "/")} sx={{ marginTop: 2 }}>
          Go to Home Page
        </Button>{" "}
      </Box>
    </Container>
  );
};

export default ErrorBoundaryScreen;
