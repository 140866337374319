import { useNavigate, useSearchParams } from "react-router-dom";

type UrlNavigation = {
  navigate: (toUrl: string, options?: { keepQuery?: boolean }) => void;
  navigateBack: () => void;
  query: Record<string, string>;
  setQuery: (q: Record<string, string>) => void;
};

export const useUrlNavigation = (): UrlNavigation => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  return {
    navigate: (toUrl, { keepQuery } = {}) => navigate(keepQuery ? `${toUrl}?${searchParams}` : toUrl),
    navigateBack: () => navigate(-1),
    query: Object.fromEntries(searchParams),
    setQuery: setSearchParams,
  };
};
