import { ParallelEnv } from "../config/env.config";
import { getStaticConfig, StaticConfig } from "../config/static.config";
import { ExtendedAppointment } from "../types/calendar/appointment.types";

export const getShortCode = (appointment: ExtendedAppointment, studentUserId?: string) => {
  const { shortCode } = appointment.students.find(s => s.userId === studentUserId) || appointment.provider;
  return shortCode;
};

export const getTelehealthJoinDetails = (
  env: ParallelEnv,
  appointment: ExtendedAppointment,
  studentUserId?: string,
) => {
  const { telehealthUrl } = getStaticConfig(env);
  const shortCode = getShortCode(appointment, studentUserId);
  return { shortCode, url: `${telehealthUrl}/s/${shortCode}` };
};

export const isConsultAppointment = (appointment: ExtendedAppointment) =>
  appointment.appointmentType.complianceType === "CONSULT";

export const isProgressAppointment = (appointment: ExtendedAppointment, config: StaticConfig) =>
  config.progressAppointmentTypeIds.includes(appointment.appointmentTypeId);
