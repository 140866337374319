import { useContext } from "react";
import Box from "@mui/material/Box";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorProvider } from "@tiptap/react";
import { useDebouncedProcessStatus } from "@parallel/polygon/components/shared/input/status.input";
import { ReportEditorBlock } from "@parallel/vertex/types/assessment/assessment.report.types";
import BlockContentMenu from "@/components/report/editor/content/BlockContentMenu";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getContentHtmlString, InjectedValueMark } from "@/util/tiptap.util";

const extensions = [
  Document,
  Paragraph,
  Text,
  InjectedValueMark,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
];

const logger = initLogger("BlockContentInput", getLoggerContext);

const BlockContentInput = ({ block }: { block: ReportEditorBlock }) => {
  const {
    reportStore: { upsertCustomBlock },
  } = useContext(StoreContext);

  const { reportBlockTemplateId } = block;
  const { status: saveStatus, perform: upsertCustomContent } = useDebouncedProcessStatus(
    (content: string) =>
      upsertCustomBlock(reportBlockTemplateId, { content }).catch(logger.handleFailureAndThrow("upsertCustomContent")),
    { saveKey: reportBlockTemplateId },
  );

  const onUpdate = ({ editor }: { editor: Editor }) => {
    const content = getContentHtmlString(editor);
    if (!content) return;
    upsertCustomContent(content);
  };

  return (
    <Box
      width="100%"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: 1,
        "&:focus-within": { borderColor: "primary.main" },
        "& .tiptap": {
          minHeight: 200,
          p: 1,
          outline: 0,
        },
      }}
    >
      <EditorProvider
        extensions={extensions}
        content={block.custom?.content || block.content}
        slotBefore={<BlockContentMenu saveStatus={saveStatus} />}
        onUpdate={onUpdate}
      />
    </Box>
  );
};

export default BlockContentInput;
