import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FullBox, FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import { ExtendedFormSubmission, FormAnswers } from "@parallel/vertex/types/form.types";
import FeatheryForm from "@/components/shared/display/FeatheryForm";
import ApprovalControls from "@/components/shared/input/ApprovalControls";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { useUrlNavigation } from "@/util/router.util";

type UrlParams = { formId: string; submissionId: string };

const logger = initLogger("FormSubmissionScreen", getLoggerContext);

const FormSubmissionScreen = () => {
  const { submissionId } = useParams() as UrlParams;

  const {
    apiStore: { formApi },
    authStore: { currentUser },
    calendarStore: { currentPayPeriod },
  } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const [submission, setSubmission] = useState<ExtendedFormSubmission>();

  const isCurrentUserCreator = currentUser && submission?.createdBy === currentUser.userId;
  const isFormAppointmentOutsidePayPeriod =
    submission?.appointment && currentPayPeriod ? submission.appointment.startTime < currentPayPeriod.startTime : false;

  const isReadOnly = !isCurrentUserCreator || isFormAppointmentOutsidePayPeriod;

  useEffect(() => {
    setIsLoading(true);
    formApi
      .getSubmissionById(submissionId)
      .then(setSubmission)
      .catch(e => {
        logger.logFailure("getSubmissionById", e);
        toast.error("Failed to load form submission");
      })
      .finally(() => setIsLoading(false));
  }, [submissionId]);

  const { navigateBack } = useUrlNavigation();

  const onSubmit = (formAnswers: FormAnswers) =>
    formApi.updateSubmission(submissionId, { formAnswers }).then(navigateBack);

  if (isLoading) return <LoadingScreen />;

  return (
    <PrimaryLayout>
      <FullBox position="relative">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
            pt: 2,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        >
          <Button startIcon={<ArrowBackIcon />} onClick={navigateBack}>
            <span>Back</span>
          </Button>

          {submission?.latestApproval && currentUser?.userType === "ADMIN" && (
            <Box>
              <ApprovalControls
                approvalStatus={submission.latestApproval.approvalStatus}
                onStatusUpdate={approvalStatus =>
                  formApi
                    .updateSubmission(submissionId, { approvalStatus })
                    .then(setSubmission)
                    .catch(logger.handleFailure("updateSubmission"))
                }
              />
            </Box>
          )}
        </Stack>

        <FullCenterBox
          sx={{ maxWidth: "max(1024px, calc(100% - 2 * 122px))", alignContent: "center", margin: "0 auto" }}
        >
          {!submission ? (
            <Typography variant="body1">Failed to load form submission</Typography>
          ) : (
            <FeatheryForm
              featheryFormId={submission.featheryFormId}
              submissionId={submission.submissionId}
              onSubmit={onSubmit}
              isReadOnly={isReadOnly}
            />
          )}
        </FullCenterBox>
      </FullBox>
    </PrimaryLayout>
  );
};

export default observer(FormSubmissionScreen);
