import AddIcon from "@mui/icons-material/Add";
import { StudentGoal } from "@parallel/vertex/types/progress.types";
import { SingleStudentUser } from "@parallel/vertex/types/user/student.types";
import ProgressDisplayLayout from "@/components/progress/ProgressDisplayLayout";

const StudentProgressConfiguration = ({
  student,
  onStudentUpdated,
  onGoalAdd,
  onGoalEdit,
}: {
  student: SingleStudentUser;
  onStudentUpdated: (s: SingleStudentUser) => void;
  onGoalAdd: () => void;
  onGoalEdit: (editing: { goal: StudentGoal; index: number }) => void;
}) => {
  return (
    <ProgressDisplayLayout
      studentId={student.userId}
      goals={student.goals}
      setGoals={goals => onStudentUpdated({ ...student, goals })}
      headerAction={{ text: "New Goal", icon: <AddIcon />, perform: onGoalAdd }}
      onEditGoal={onGoalEdit}
    />
  );
};

export default StudentProgressConfiguration;
