import { isNull } from "lodash";
import { AppointmentDetails, ExtendedAppointmentUser } from "@parallel/vertex/types/calendar/appointment.types";

export const ALL_STUDENT_VIEW_TYPES = ["info", "goals", "progress"] as const;

export type StudentViewType = (typeof ALL_STUDENT_VIEW_TYPES)[number];

export const getAttendanceState = (
  student: ExtendedAppointmentUser,
  appointmentDetails: AppointmentDetails,
  timezone: string,
) => {
  const meetingConnection = appointmentDetails.studentMeetingConnections.find(c => c.studentId === student.userId);
  return {
    hasAttended: isNull(student.hasAttended) ? !!meetingConnection : student.hasAttended,
    joinedAt: (student.joinedAt || meetingConnection?.joinedAt)?.setZone(timezone) || null,
    leftAt: (student.leftAt || meetingConnection?.leftAt)?.setZone(timezone) || null,
  };
};

export const ASSESSMENT_SERVICE_LINE_PRODUCT_CODES = [
  "PSY_AA",
  "PSY_CA",
  "PSY_RR",
  "PSY_SEA",
  "SLP_AH",
  "SLP_SA",
  "SSW_SEA",
  "SLP_CA",
];

export const isAssessmentServiceLine = (productCode: string) =>
  ASSESSMENT_SERVICE_LINE_PRODUCT_CODES.includes(productCode);
