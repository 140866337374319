import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const AppointmentRecordingSelect = ({ fileUrls, onBack }: { fileUrls: string[]; onBack: () => void }) => {
  return (
    <Stack gap={1}>
      {fileUrls.map((fileUrl, i) => (
        <Button href={fileUrl} target="_blank" variant="contained" key={i}>
          File {i + 1}
        </Button>
      ))}
      <Box pt={1}>
        <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
          Back
        </Button>
      </Box>
    </Stack>
  );
};

export default AppointmentRecordingSelect;
