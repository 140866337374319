import { useContext, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { SelectWithStatus } from "@parallel/polygon/components/shared/input/status.input";
import { GreyBox } from "@parallel/polygon/components/shared/layout/container";
import { displayInputFieldStyles } from "@parallel/polygon/util/style.util";
import { APPROVAL_STATUS_NAMES, TYPE_CATEGORY_CONFIG } from "@parallel/vertex/enums/calendar.enums";
import { ExtendedTimeEntry } from "@parallel/vertex/types/calendar/time.types";
import IndirectTimeForm from "@/components/calendar/time/IndirectTimeForm";
import CommonDisplay from "@/components/shared/layout/CommonDisplay";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("IndirectTimeDisplay", getLoggerContext);

const IndirectTimeDisplay = ({ time, onClose }: { time: ExtendedTimeEntry; onClose: () => void }) => {
  const {
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const [isEditing, setIsEditing] = useState(false);
  if (isEditing) return <IndirectTimeForm editing={time} onClose={() => setIsEditing(false)} />;

  const approvalStatusAllowsEdits =
    !time.latestApproval ||
    !["APPROVED", "DENIED"].includes(time.latestApproval.approvalStatus) ||
    (time.latestApproval.approvalStatus === "FOR_REVIEW" && currentUser?.userId === time.userId);

  const canEdit = approvalStatusAllowsEdits && calendarStore.canWriteCalendar(time.startTime);

  return (
    <CommonDisplay
      title="Indirect Time"
      record={time}
      recordName={{ singular: "indirect time", plural: "indirect time" }}
      deleteRecord={
        canEdit
          ? mode =>
              calendarStore
                .deleteIndirectTime(time.timeEntryId, mode)
                .catch(logger.handleFailureAndThrow("deleteIndirectTime"))
          : undefined
      }
      onEdit={canEdit ? () => setIsEditing(true) : undefined}
      onClose={onClose}
    >
      <>
        <Stack gap={2} sx={displayInputFieldStyles}>
          <Stack direction="row" gap={1}>
            <TextField
              label="Category"
              value={TYPE_CATEGORY_CONFIG[time.taskType.category].title}
              size="small"
              fullWidth
              disabled
            />
            <TextField label="Task Type" value={time.taskType.title} size="small" fullWidth disabled />
          </Stack>

          {time.description && (
            <TextField
              label="Description"
              value={time.description}
              size="small"
              fullWidth
              disabled
              multiline
              rows={2}
            />
          )}

          {time.latestApproval && (
            <>
              <SelectWithStatus
                label="Approval Status"
                options={[
                  {
                    key: APPROVAL_STATUS_NAMES[time.latestApproval.approvalStatus],
                    label: APPROVAL_STATUS_NAMES[time.latestApproval.approvalStatus],
                  },
                ]}
                fullWidth
                onSelect={async () => Promise<void>}
                selectedKey={APPROVAL_STATUS_NAMES[time.latestApproval.approvalStatus] || ""}
                minWidth={420}
                disabled
              />
            </>
          )}
        </Stack>

        <GreyBox>
          <Stack gap={2}>
            {currentUser?.userType === "ADMIN" && (
              <Stack gap={0.5}>
                <Typography variant="h3">User</Typography>
                <Stack direction="row" gap={2} alignItems="center">
                  <AccountCircleIcon />
                  <Typography variant="body1">{time.user.fullName}</Typography>
                </Stack>
              </Stack>
            )}

            {!isEmpty(time.students) && (
              <Stack gap={0.5}>
                <Typography variant="h3">Students</Typography>
                {time.students.map((student, i) => (
                  <Stack direction="row" gap={2} alignItems="center" key={i}>
                    <GroupsIcon />
                    <Typography variant="body1">{student.fullName}</Typography>
                  </Stack>
                ))}
              </Stack>
            )}

            {time.campus && (
              <Stack gap={0.5}>
                <Typography variant="h3">Campus</Typography>
                <Stack direction="row" gap={2} alignItems="center">
                  <BusinessIcon />
                  <Typography variant="body1">{time.campus.salesforceName}</Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </GreyBox>
      </>
    </CommonDisplay>
  );
};

export default IndirectTimeDisplay;
