import type { TaskTypeCategory } from "@prisma/client";
import { z } from "zod";

export const appointmentStatusEnum = z.enum(["OCCURRED", "CANCELLED", "LATE_CANCELLED", "NO_SHOW"]);

export type AppointmentStatus = z.infer<typeof appointmentStatusEnum>;

export const CANCEL_STATUSES = ["CANCELLED", "LATE_CANCELLED"] as const;
export type CancelStatus = (typeof CANCEL_STATUSES)[number];

export const recurrenceUnitEnum = z.enum(["WEEK", "MONTH"]);

export type RecurrenceUnit = z.infer<typeof recurrenceUnitEnum>;

export const WEEK_DAYS = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"] as const;

export const dayOfWeekEnum = z.enum(WEEK_DAYS);

export type DayOfWeek = z.infer<typeof dayOfWeekEnum>;

type TaskTypeGroupConfig = {
  title: string;
  student?: "required" | "single-optional";
  campus?: "required";
};

export const TYPE_CATEGORY_CONFIG: Record<TaskTypeCategory, TaskTypeGroupConfig> = {
  ASSESSMENT: {
    title: "Assessments and Evaluations",
    student: "required",
  },
  CASE_MANAGEMENT: {
    title: "Case Management",
    student: "required",
  },
  PARALLEL_ACTIVITY: {
    title: "Parallel Activities",
  },
  SCHOOL_MEETING: {
    title: "School Meetings",
    campus: "required",
  },
  THERAPY: {
    title: "Session Delivery",
    student: "single-optional",
    campus: "required",
  },
};

export const APPROVAL_STATUS_NAMES = {
  APPROVED: "Approved",
  DENIED: "Denied",
  PENDING: "Pending",
  FOR_REVIEW: "Provider Review",
};

export const ALL_APPROVAL_STATUSES = ["APPROVED", "DENIED", "PENDING", "FOR_REVIEW"] as const;

export const approvalStatusEnum = z.enum(ALL_APPROVAL_STATUSES);

export const recurrenceEditModeEnum = z.enum(["single", "future"]);

export type RecurrenceEditMode = z.infer<typeof recurrenceEditModeEnum>;
