import type { LanguageType, Provider } from "@prisma/client";
import { z } from "zod";
import { ORDERED_LANGUAGES, contractTypeEnum, providerTypeEnum } from "../../enums/user.enums";
import { paginateQueryFields, sortQueryFields } from "../shared.types";
import { ExtendedUser } from "./user.types";

export type ExtendedProviderUser = ExtendedUser &
  Omit<Provider, "providerId"> & {
    studentIds: string[];
    languages: LanguageType[];
    districts: { id: string; name: string }[];
  };

export const searchProviderQuerySchema = z.object({
  keyword: z.string().trim().optional(),
  facilitatorId: z.string().uuid().optional(),
  assignedStudentIds: z.string().uuid().array().optional(),
  eligibleStudentIds: z.string().uuid().array().optional(),
  providerType: providerTypeEnum.optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchProviderQuery = z.infer<typeof searchProviderQuerySchema>;

export const createProviderBodySchema = z.object({
  firstName: z.string().trim(),
  lastName: z.string().trim(),
  email: z.string().email().trim().toLowerCase(),
  providerType: providerTypeEnum,
  languages: z.enum(ORDERED_LANGUAGES).array().min(1),
  contractType: contractTypeEnum,
  districtIds: z.string().uuid().array().optional(),
});

export type CreateProviderBody = z.infer<typeof createProviderBodySchema>;

export const updateProviderBodySchema = createProviderBodySchema.partial();

export type UpdateProviderBody = z.infer<typeof updateProviderBodySchema>;
