import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { isUndefined } from "lodash";
import ListMenu from "@parallel/polygon/components/shared/layout/ListMenu";
import { ObjectiveType } from "@parallel/vertex/types/progress.types";
import { removeAtIndex, updateAtIndex } from "@parallel/vertex/util/collection.util";
import SingleGoalInput from "@/components/progress/form/SingleGoalInput";
import { MultipleGoalParams, SingleGoalParams, buildDefaultGoal } from "@/util/progress.form.util";

const MultipleGoalInput = <P extends MultipleGoalParams>({
  params,
  setParams,
  allObjectiveTypes,
}: {
  params: P;
  setParams: (updated: P) => void;
  allObjectiveTypes: ObjectiveType[];
}) => {
  const [selectedGoalIndex, setSelectedGoalIndex] = useState<number>();

  const currentGoal =
    !isUndefined(selectedGoalIndex) && params.goals[selectedGoalIndex]
      ? { params: params.goals[selectedGoalIndex], index: selectedGoalIndex }
      : undefined;

  const updateCurrentGoal = (update: Partial<SingleGoalParams>) => {
    if (!currentGoal) return;
    setParams({
      ...params,
      goals: updateAtIndex(params.goals, currentGoal.index, update),
    });
  };

  const goalListItems = params.goals.map((goal, i) => ({
    key: i,
    prefix: `${i + 1}`,
    content: goal.description || "New Goal",
  }));

  return (
    <Stack width={1000} height={500}>
      <Grid container height="100%">
        <Grid xs={4} sx={{ borderRight: 1, borderColor: "grey.200", height: "100%", overflowY: "auto", pr: 2 }}>
          <Stack direction="row" height={30} alignItems="center" mb={1}>
            <Typography variant="h3">Goals</Typography>
          </Stack>
          <ListMenu items={goalListItems} selectedKey={selectedGoalIndex} onSelect={setSelectedGoalIndex} />
          <Button
            fullWidth
            onClick={() => {
              setParams({ ...params, goals: [...params.goals, buildDefaultGoal()] });
              setSelectedGoalIndex(params.goals.length);
            }}
            startIcon={<AddIcon />}
            sx={{ mt: 1 }}
          >
            Add Goal
          </Button>
        </Grid>

        {currentGoal && (
          <Grid xs={8} height="100%" pl={2}>
            <SingleGoalInput
              goal={currentGoal.params}
              goalIndex={currentGoal.index + 1}
              updateGoal={updateCurrentGoal}
              allObjectiveTypes={allObjectiveTypes}
              onRemove={() => {
                const [goals, newIndex] = removeAtIndex(params.goals, currentGoal.index);
                setSelectedGoalIndex(newIndex);
                setParams({ ...params, goals });
              }}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default MultipleGoalInput;
