import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    paddingX: theme.spacing(1),
    backgroundColor: theme.palette.surface.light,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "& .MuiListItemText-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.primary.main,
  },
}));
