import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const NameInput = <P extends { firstName?: string; lastName?: string }>({
  params,
  setParams,
}: {
  params: P;
  setParams: (p: P) => void;
}) => (
  <Stack direction="row" gap={1} sx={{ width: "100%" }}>
    <TextField
      fullWidth
      label="First Name"
      value={params.firstName || ""}
      onChange={e => setParams({ ...params, firstName: e.target.value })}
    />
    <TextField
      fullWidth
      label="Last Name"
      value={params.lastName || ""}
      onChange={e => setParams({ ...params, lastName: e.target.value })}
    />
  </Stack>
);

export default NameInput;
