import {
  ExtendedFormSubmission,
  CreateFormSubmissionBody,
  UpdateFormSubmissionBody,
} from "@parallel/vertex/types/form.types";
import { BaseAPI } from "@/api/base.api";

export class FormAPI extends BaseAPI {
  public createSubmission = (formTemplateId: string, body: CreateFormSubmissionBody): Promise<ExtendedFormSubmission> =>
    this.instance.post(`form/${formTemplateId}/submission`, body).then(r => r.data);

  public getSubmissionById = (submissionId: string, userId?: string): Promise<ExtendedFormSubmission> =>
    this.instance.get(`form/submission/${submissionId}`, { params: { userId } }).then(r => r.data);

  public updateSubmission = (submissionId: string, body: UpdateFormSubmissionBody): Promise<ExtendedFormSubmission> =>
    this.instance.put(`form/submission/${submissionId}`, body).then(r => r.data);

  public deleteSubmission = (submissionId: string): Promise<void> =>
    this.instance.delete(`form/submission/${submissionId}`).then(r => r.data);
}
