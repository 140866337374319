import { useEffect, useRef, useState } from "react";
import { useMountEffect } from "@react-hookz/web";
import { capitalize, intersection, isEmpty } from "lodash";
import { ClientLogger } from "@parallel/polygon/util/logging.util";
import { ReportEditorSection, SingleReport } from "@parallel/vertex/types/assessment/assessment.report.types";
import { ReportAPI, ReportFileUploadRequest } from "@/api/report.api";
import { SelectOption } from "@/components/shared/input/AutoCompleteInput";

export const REPORT_DATA_SECTIONS = ["testing-plan", "tests-administered", "interview-notes"] as const;

export type ReportDataSection = (typeof REPORT_DATA_SECTIONS)[number];

export type ReportMenuSelection =
  | { type: "data"; section: ReportDataSection }
  | { type: "editor"; sectionTemplateId: string; skipAutoScroll?: boolean };

export const getDataSectionTitle = (s: ReportDataSection) => s.split("-").map(capitalize).join(" ");

export const useEditorAutoScroll = (
  sectionTemplateId: string,
  menuSelection: ReportMenuSelection,
  onVisibilityChange: (sectionTemplateId: string, isVisible: boolean) => void,
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      containerRef.current &&
      menuSelection.type === "editor" &&
      menuSelection.sectionTemplateId === sectionTemplateId &&
      !menuSelection.skipAutoScroll
    ) {
      containerRef.current.scrollIntoView();
    }
  }, [containerRef, menuSelection]);

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.boundingClientRect.y > 500) return;
      onVisibilityChange(sectionTemplateId, entry.isIntersecting);
    });
    observer.observe(containerRef.current);
  }, [containerRef]);

  return { containerRef };
};

export type FileState = { file: File; sourceId?: string; includeReasonId?: string };

export const isUploadCustom = (report: SingleReport, { sourceId }: FileState) => {
  if (!sourceId) return false;
  // uploading file is "custom" (i.e. was not included via testing plan)
  // if report doesn't have another non-custom upload record matching the selected source
  return !report.testUploads.some(u => u.reportTestUploadSourceId === sourceId && !u.includeReasonId);
};

export const resolveUploadRequest = (
  report: SingleReport,
  fileState: FileState,
  { requireCustomReason }: { requireCustomReason?: boolean } = {},
): ReportFileUploadRequest | undefined => {
  const { sourceId, includeReasonId } = fileState;
  if (!sourceId) return;
  if (requireCustomReason && !includeReasonId) return;
  if (isUploadCustom(report, fileState) && !fileState.includeReasonId) return;

  return { ...fileState, sourceId };
};

export const useUploadOptions = (
  reportApi: ReportAPI,
  logger: ClientLogger,
): { sourceOptions: SelectOption[]; includeReasonOptions: SelectOption[] } => {
  const [sourceOptions, setSourceOptions] = useState<SelectOption[]>([]);
  const [includeReasonOptions, setIncludeReasonOptions] = useState<SelectOption[]>([]);

  useMountEffect(() => {
    reportApi
      .getAllTestUploadSources()
      .then(sources => setSourceOptions(sources.map(s => ({ key: s.reportTestUploadSourceId, label: s.name }))))
      .catch(logger.handleFailure("getAllTestUploadSources"));

    reportApi
      .getAllTestUploadIncludeReasons()
      .then(reasons =>
        setIncludeReasonOptions(reasons.map(r => ({ key: r.reportTestUploadIncludeReasonId, label: r.title }))),
      )
      .catch(logger.handleFailure("getAllTestUploadIncludeReasons"));
  });

  return { sourceOptions, includeReasonOptions };
};

export const guessTestUploadSourceLabel = (file: File): string | undefined => {
  const fileName = file.name.toLowerCase();
  if (["wj", "woodcock", "johnson", ".html"].find(m => fileName.includes(m))) {
    return "Woodcock-Johnson";
  } else if (fileName.includes("wisc")) {
    return "WISC-V";
  } else if (fileName.includes("basc")) {
    if (fileName.includes("parent")) return "BASC Parent Report";
    if (fileName.includes("self")) return "BASC Self Report";
    if (fileName.includes("teacher")) return "Basc Teacher Report";
    if (fileName.includes("6-7") || fileName.includes("child") || fileName.includes("interview"))
      return "BASC Child Interview";
  } else if (fileName.includes("brief")) {
    if (fileName.includes("parent")) return "BRIEF Parent Report";
    if (fileName.includes("self")) return "BRIEF Self Report";
    if (fileName.includes("teacher")) return "BRIEF Teacher Report";
  } else if (fileName.includes("celf")) {
    if (["5-", "8"].some(s => fileName.includes(s))) return "CELF-5 Ages 5-8";
    if (["9", "21"].some(s => fileName.includes(s))) return "CELF-5 Ages 9-21";
    if (fileName.includes("pre")) return "Celf Preschool";
    if (fileName.includes("meta")) return "CELF-5 Meta";
  } else if (fileName.includes("evt")) {
    return "EVT-3";
  } else if (fileName.includes("ppvt")) {
    return "PPVT_5";
  } else if (fileName.includes("diva")) {
    return "Young DIVA";
  } else if (fileName.includes("gfta") || fileName.includes("klpa")) {
    return "GFTA / KLPA";
  } else if (fileName.includes("vineland")) {
    if (fileName.includes("parent")) return "Vineland Parent Report";
    if (fileName.includes("teacher")) return "Vineland Teacher Report";
  } else if (fileName.includes("srs")) {
    if (fileName.includes("parent")) return "SRS Parent Report";
    if (fileName.includes("teacher")) return "SRS Teacher Report";
  } else if (fileName.includes("wiat")) {
    return "WIAT-4";
  }
  return;
};

export const isSectionVisible = (report: SingleReport, section: ReportEditorSection): boolean => {
  // section is visible if it does not include any test upload source ids
  if (isEmpty(section.testUploadSourceIds)) return true;

  // section is visible if any of the test upload source ids are present on the report
  const enabledUploadSourceIds = report.testUploads.map(u => u.reportTestUploadSourceId);
  const matchingUploadSourceIds = intersection(enabledUploadSourceIds, section.testUploadSourceIds);
  return !isEmpty(matchingUploadSourceIds);
};
