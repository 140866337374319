import { z } from "zod";
import { paginateQueryFields, sortQueryFields } from "../shared.types";
import { ExtendedUser } from "./user.types";

export type ExtendedFacilitatorUser = ExtendedUser & {
  districtId?: string;
  districtName?: string;
};

export const searchFacilitatorQuerySchema = z.object({
  keyword: z.string().trim().optional(),
  districtName: z.string().trim().optional(),
  providerId: z.string().uuid().optional(),
  eligibleStudentIds: z.string().uuid().array().optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchFacilitatorQuery = z.infer<typeof searchFacilitatorQuerySchema>;

export const createFacilitatorBodySchema = z.object({
  firstName: z.string().trim(),
  lastName: z.string().trim(),
  email: z.string().email().trim().toLowerCase(),
  districtId: z.string().uuid(),
  sendWelcomeEmail: z.boolean().optional(),
});

export type CreateFacilitatorBody = z.infer<typeof createFacilitatorBodySchema>;

export const updateFacilitatorBodySchema = createFacilitatorBodySchema.partial();

export type UpdateFacilitatorBody = z.infer<typeof updateFacilitatorBodySchema>;
