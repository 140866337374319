import { isInteger, isUndefined, pick } from "lodash";
import {
  CreateGoalBody,
  CreateObjectiveBody,
  ObjectiveType,
  SetStudentGoalsBody,
  StudentGoal,
  setStudentGoalsBodySchema,
} from "@parallel/vertex/types/progress.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { roundToDecimal } from "@parallel/vertex/util/number.util";

export type SingleGoalParams = Override<
  Partial<CreateGoalBody>,
  { objectives: Partial<CreateObjectiveBody>[]; goalId?: string }
>;

export type MultipleGoalParams = { goals: SingleGoalParams[] };

export const buildDefaultGoal = (): SingleGoalParams => ({
  objectives: [],
});

export const getInitialGoalParams = (goal?: StudentGoal): SingleGoalParams => ({
  ...pick(goal, "goalId", "description"),
  objectives: goal
    ? goal.objectives.map(objective => ({
        ...pick(objective, "objectiveId", "description", "targetValue"),
        typeId: objective.objectiveTypeId,
      }))
    : [],
});

export const getValidObjectiveValue = (param: number | undefined, type: ObjectiveType): number | undefined | null => {
  switch (type.category) {
    case "ACCURACY":
      if (!param || param <= 0 || param > 100) return null;
      return param < 1 ? param : roundToDecimal(param * 0.01, 4);
    case "DURATION":
      return param && param > 0 ? param : null;
    case "DECREASING_FREQUENCY":
    case "INCREASING_FREQUENCY":
    case "PROMPTING_FREQUENCY":
      return !isUndefined(param) && isInteger(param) && param >= 0 ? param : null;
    case "ANECDOTAL":
      return undefined;
  }
};

export const transformGoalObjectiveValues = (goal: SingleGoalParams, allObjectiveTypes: ObjectiveType[]) => ({
  ...goal,
  objectives: goal.objectives.map(objective => {
    const type = allObjectiveTypes.find(t => t.objectiveTypeId === objective.typeId);
    return {
      ...objective,
      targetValue: type ? getValidObjectiveValue(objective.targetValue, type) : null,
    };
  }),
});

export const parseGoalsBody = (
  params: MultipleGoalParams,
  allObjectiveTypes: ObjectiveType[],
): SetStudentGoalsBody | undefined =>
  setStudentGoalsBodySchema.safeParse({
    goals: params.goals.map(goal => transformGoalObjectiveValues(goal, allObjectiveTypes)),
  })?.data;
