import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isInteger } from "lodash";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { roundToDecimal } from "@parallel/vertex/util/number.util";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import ObjectiveActions from "@/components/progress/ObjectiveActions";

const getDisplayTargetValue = (objective: StudentObjective) => {
  const { targetValue } = objective;
  if (!targetValue) return undefined;

  switch (objective.category) {
    case "ACCURACY":
      return targetValue < 1 ? `${roundToDecimal(targetValue * 100, 2)}%` : `${targetValue}%`;
    case "DURATION":
      const minutes = targetValue / 60;
      return isInteger(minutes) ? `${minutes} minutes` : `${targetValue} seconds`;
    default:
      return targetValue;
  }
};

const ObjectiveDisplay = ({
  objective,
  goal,
  listIndex,
  studentId,
  onGoalUpdated,
}: {
  objective: StudentObjective;
  goal: StudentGoal;
  listIndex: { goal: number; objective: number };
  studentId: string;
  onGoalUpdated: (u: StudentGoal) => void;
}) => {
  const displayTargetValue = getDisplayTargetValue(objective);
  return (
    <FullStack>
      <Stack gap={2} pb={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color="primary.main">
            {toTitleCase(objective.category)} Objective {listIndex.goal}.{listIndex.objective}
          </Typography>

          <ObjectiveActions
            objective={objective}
            studentId={studentId}
            goal={goal}
            displayIndices={listIndex}
            onGoalUpdated={onGoalUpdated}
          />
        </Stack>

        <Stack direction="row">
          <Typography variant="body1" fontWeight="bold" width={120} sx={{ flexShrink: 0 }}>
            Description
          </Typography>
          <Typography>{objective.description}</Typography>
        </Stack>
        {displayTargetValue && (
          <Stack direction="row">
            <Typography variant="body1" fontWeight="bold" width={120}>
              Target Value
            </Typography>
            <Typography>{displayTargetValue}</Typography>
          </Stack>
        )}
      </Stack>
    </FullStack>
  );
};

export default ObjectiveDisplay;
