import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import { ReportContentLayout } from "@/components/report/ReportContent";
import BulkUploadInput from "@/components/report/data/upload/BulkUploadInput";
import CustomUploadInput from "@/components/report/data/upload/CustomUploadInput";
import SkipTestPrompt from "@/components/report/data/upload/SkipTestPrompt";
import TestUploadRow from "@/components/report/data/upload/TestUploadRow";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";

type UploadModal = "bulk" | "custom";

const ReportTestsAdministered = () => {
  const {
    reportStore: { currentReport: report },
  } = useContext(StoreContext);

  const [uploadModal, setUploadModal] = useState<UploadModal>();
  const [skippingUploadId, setSkippingUploadId] = useState("");

  const clearModal = () => {
    setUploadModal(undefined);
    setSkippingUploadId("");
  };

  if (!report) return <></>;

  if (isEmpty(report.testUploads))
    return (
      <ReportContentLayout title="Tests Administered">
        <Typography variant="body1">Must submit testing plan before uploading test results</Typography>
      </ReportContentLayout>
    );

  return (
    <ReportContentLayout
      title="Tests Administered"
      actions={
        <Button startIcon={<UploadIcon />} onClick={() => setUploadModal("bulk")}>
          Bulk Upload
        </Button>
      }
    >
      <Stack gap={1} pb={2}>
        {report.testUploads.map(upload => (
          <TestUploadRow
            upload={upload}
            onSkip={() => setSkippingUploadId(upload.reportTestUploadId)}
            key={upload.reportTestUploadId}
          />
        ))}
      </Stack>

      <Button onClick={() => setUploadModal("custom")} startIcon={<AddIcon />}>
        Add Custom Test Scores
      </Button>

      <CenterModal isOpen={!!uploadModal || !!skippingUploadId} onClose={clearModal}>
        {uploadModal === "bulk" && <BulkUploadInput onCancel={clearModal} />}
        {uploadModal === "custom" && <CustomUploadInput onClose={clearModal} />}
        {skippingUploadId && <SkipTestPrompt uploadId={skippingUploadId} onFinished={clearModal} />}
      </CenterModal>
    </ReportContentLayout>
  );
};

export default observer(ReportTestsAdministered);
