import { useContext } from "react";
import Box from "@mui/material/Box";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import CalendarBackground from "@/components/calendar/CalendarBackground";
import CalendarColumn from "@/components/calendar/CalendarColumn";
import { StoreContext } from "@/stores";

const CalendarDayView = ({ date }: { date: DateTime }) => {
  const {
    calendarStore: { zoomFactor },
  } = useContext(StoreContext);
  const height = percentString(zoomFactor);

  return (
    <Box sx={{ height, width: "100%", position: "relative" }} role="main">
      <CalendarBackground />
      <FullBox sx={{ position: "absolute", top: 0, paddingLeft: "6rem" }}>
        <CalendarColumn date={date} />
      </FullBox>
    </Box>
  );
};

export default observer(CalendarDayView);
