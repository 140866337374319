import Box from "@mui/material/Box";
import { CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import { ReportEditorBlock } from "@parallel/vertex/types/assessment/assessment.report.types";
import { getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getBlockMarkdown } from "@/util/report.markdown.util";

const logger = initLogger("StaticBlockContent", getLoggerContext);

const StaticBlockContent = ({ block }: { block: ReportEditorBlock }) => {
  return (
    <Box
      p={2}
      sx={{
        p: 1,
        borderRadius: 1,
        position: "relative",
        "&:hover": {
          outline: 1,
          outlineColor: "grey.300",
          "& .MuiIconButton-root": { display: "flex" },
        },
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: block.content || "" }} />
      <CopyIconButton
        text={getBlockMarkdown(block)}
        label="Block"
        sx={{
          display: "none",
          position: "absolute",
          top: -12,
          right: -12,
          bgcolor: "white",
          "&:hover": { bgcolor: "grey.100", opacity: 1 },
        }}
        logger={logger}
      />
    </Box>
  );
};

export default StaticBlockContent;
