import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SelectInput = <A extends string>({
  label,
  options,
  value,
  onChange,
  width,
  size,
  fullWidth,
}: {
  label?: string;
  options: { key: A; label: string }[];
  value: A | undefined;
  onChange: (newValue: A) => void;
  width?: number;
  size?: "medium" | "small";
  fullWidth?: boolean;
}) => {
  const labelId = `${(label || "select").toLowerCase().replace(/ /g, "-")}-label`;
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={labelId} size={size === "medium" ? "normal" : size}>
        {label}
      </InputLabel>
      <Select
        value={value || ""}
        onChange={e => onChange(e.target.value as A)}
        labelId={labelId}
        label={label}
        sx={{ width }}
        size={size}
      >
        {options.map(o => (
          <MenuItem value={o.key} key={o.key}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
