import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ObjectiveMetricInput, {
  UpsertMetricOptions,
} from "@parallel/polygon/components/progress/metric/ObjectiveMetricInput";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { ObjectiveMetricBody, StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import ObjectiveActions from "@/components/progress/ObjectiveActions";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ObjectiveMetricDisplay", getLoggerContext);

const ObjectiveMetricDisplay = ({
  objective,
  goal,
  listIndex,
  studentId,
  appointmentId,
  onGoalUpdated,
  onMetricWritten,
  isReadOnly,
}: {
  objective: StudentObjective;
  goal: StudentGoal;
  listIndex: { goal: number; objective: number };
  studentId: string;
  appointmentId: string;
  onGoalUpdated: (g: StudentGoal) => void;
  onMetricWritten: () => void;
  isReadOnly?: boolean;
}) => {
  const {
    apiStore: { progressApi },
  } = useContext(StoreContext);

  const upsertMetric = async (body: ObjectiveMetricBody & UpsertMetricOptions) => {
    const updated = await progressApi
      .upsertObjectiveAppointmentMetric(objective.objectiveId, appointmentId, body)
      .catch(logger.handleFailureAndThrow("upsertObjectiveAppointmentMetric"));

    if (!body.skipStateUpdate) {
      onGoalUpdated({
        ...goal,
        objectives: goal.objectives.map(o => (o.objectiveId === updated.objectiveId ? updated : o)),
      });
    }

    onMetricWritten();
  };

  const onNoteUpdated = (note: string) => {
    const updatedObjectives: StudentObjective[] = goal.objectives.map(o =>
      o.objectiveId === objective.objectiveId && o.metric
        ? { ...o, metric: { ...o.metric, type: "string", value: note } }
        : o,
    );
    onGoalUpdated({
      ...goal,
      objectives: updatedObjectives,
    });
  };

  return (
    <FullStack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          {toTitleCase(objective.category)} Objective {listIndex.goal}.{listIndex.objective}
        </Typography>

        <ObjectiveActions
          objective={objective}
          studentId={studentId}
          goal={goal}
          displayIndices={listIndex}
          onGoalUpdated={onGoalUpdated}
        />
      </Stack>

      <Typography>{objective.description}</Typography>

      <ObjectiveMetricInput
        objective={objective}
        upsertMetric={upsertMetric}
        onNoteUpdated={onNoteUpdated}
        isReadOnly={isReadOnly}
      />
    </FullStack>
  );
};

export default ObjectiveMetricDisplay;
