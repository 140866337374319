import { useContext, useState } from "react";
import { toast } from "react-toastify";
import RestoreIcon from "@mui/icons-material/Restore";
import { useMountEffect } from "@react-hookz/web";
import { ServiceRequirementType } from "@parallel/vertex/types/service.types";
import { SingleStudentUser } from "@parallel/vertex/types/user/student.types";
import FormLayout, { getCancelAction } from "@/components/shared/layout/FormLayout";
import StudentServiceInput from "@/components/user/student/form/StudentServiceInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getInitialStudentParams, parseServicesBody } from "@/util/student.form.util";

const logger = initLogger("StudentRestoreForm", getLoggerContext);

const StudentRestoreForm = ({
  student,
  onFinished,
}: {
  student: SingleStudentUser;
  onFinished: (s: SingleStudentUser) => void;
}) => {
  const {
    apiStore: { serviceApi, userApi },
  } = useContext(StoreContext);

  const [params, setParams] = useState(getInitialStudentParams(student));

  const [allRequirementTypes, setAllRequirementTypes] = useState<ServiceRequirementType[]>([]);
  useMountEffect(() => {
    serviceApi
      .searchServiceRequirementTypes()
      .then(setAllRequirementTypes)
      .catch(logger.handleFailure("searchServiceRequirementTypes"));
  });

  const restoreBody = parseServicesBody(params, allRequirementTypes);
  const performRestore = restoreBody
    ? () => {
        const studentId = student.userId;
        return userApi
          .restoreStudent(studentId, restoreBody)
          .then(updated => {
            toast.success(`Successfully Restored ${student.fullName}`);
            onFinished(updated);
          })
          .catch(error => {
            logger.logFailure("restoreStudent", error);
            toast.error(`Failed to Archive Student ${student.fullName}`);
          });
      }
    : undefined;

  return (
    <FormLayout
      headerText={`Restore ${student.fullName}`}
      formContent={
        <StudentServiceInput params={params} setParams={setParams} allRequirementTypes={allRequirementTypes} />
      }
      primaryAction={{ onClick: performRestore, label: "Restore", icon: <RestoreIcon /> }}
      secondaryAction={getCancelAction(() => onFinished(student))}
    />
  );
};

export default StudentRestoreForm;
