import { DateTime } from "luxon";
import { z } from "zod";
import {
  DateTimeRange,
  Override,
  datetimeQueryField,
  datetimeRequestField,
  paginateQueryFields,
} from "../shared.types";
import { ExtendedAppointment } from "./appointment.types";
import { ExtendedCalendarBlock } from "./calendar.block.types";
import { ExtendedTimeEntry } from "./time.types";

export type ExtendedCalendarItem<A> = Override<A, { startTime: DateTime; endTime: DateTime }>;

export type CalendarItems = {
  appointments: ExtendedAppointment[];
  blocks: ExtendedCalendarBlock[];
  indirectTime: ExtendedTimeEntry[];
};

export const timeRangeFields = {
  startTime: datetimeRequestField,
  endTime: datetimeRequestField,
};

export const timeRangeQuerySchema = z.object({
  startTime: datetimeQueryField.optional(),
  endTime: datetimeQueryField.optional(),
});

export const timeRangeRefine: [(range: Partial<DateTimeRange>) => any, { message: string }] = [
  range => !range.startTime || !range.endTime || range.endTime > range.startTime,
  { message: "endTime must come after startTime" },
];

const searchUserCalendarItemQuerySchema = timeRangeQuerySchema.extend({
  userIds: z.string().uuid().array().optional(),
  recurrenceId: z.string().uuid().optional(),
  ...paginateQueryFields,
});

export type SearchUserCalendarItemQuery = z.infer<typeof searchUserCalendarItemQuerySchema>;

export const searchCalendarQuerySchema = timeRangeQuerySchema.extend({
  userId: z.string().uuid().optional(),
  studentId: z.string().uuid().optional(),
  providerId: z.string().uuid().optional(),
  slpaId: z.string().uuid().optional(),
  facilitatorId: z.string().uuid().optional(),
});

export type SearchCalendarQuery = z.infer<typeof searchCalendarQuerySchema>;
