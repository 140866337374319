import { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadIcon from "@mui/icons-material/Upload";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import FileInputBox from "@parallel/polygon/components/shared/input/FileInputBox";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { ReportFileUploadRequest } from "@/api/report.api";
import SelectInput from "@/components/shared/input/SelectInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { guessTestUploadSourceLabel, isUploadCustom, resolveUploadRequest, useUploadOptions } from "@/util/report.util";

type FileState = { file: File; sourceId?: string; includeReasonId?: string };

const logger = initLogger("BulkUploadInput", getLoggerContext);

const BulkUploadInput = ({ onCancel }: { onCancel: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport, uploadTestFiles },
  } = useContext(StoreContext);

  const { sourceOptions, includeReasonOptions } = useUploadOptions(reportApi, logger);

  const [files, setFiles] = useState<FileState[]>([]);

  const appendFiles = (newFiles: File[]) => {
    const newFileState = newFiles.map(file => {
      const sourceLabel = guessTestUploadSourceLabel(file);
      const source = sourceLabel ? sourceOptions.find(s => s.label === sourceLabel) : undefined;
      return { file, sourceId: source?.key };
    });
    setFiles([...files, ...newFileState]);
  };

  const updateFileAtIndex = (index: number, update: Partial<FileState>) =>
    setFiles(files.map((f, i) => (i === index ? { ...f, ...update } : f)));

  const deleteFileAtIndex = (index: number) => setFiles(files.filter((_, i) => i !== index));

  if (!currentReport) return <></>;

  const uploadRequests: ReportFileUploadRequest[] = mapExists(
    files,
    fileState => currentReport && resolveUploadRequest(currentReport, fileState),
  );
  const isValid = !isEmpty(uploadRequests) && uploadRequests.length === files.length;

  const uploadFiles = async () => {
    if (!isValid) return;
    return uploadTestFiles(uploadRequests).catch(logger.handleFailureAndThrow("uploadTestFiles"));
  };

  return (
    <Stack gap={2} width={800}>
      <Typography variant="h2">Bulk Upload Scores</Typography>

      <FileInputBox onFileSelect={appendFiles}>
        <Stack justifyContent="center" alignItems="center" height={200}>
          <NoteAddIcon sx={{ fontSize: 80, color: "primary.light" }} />
          <Link component="label" sx={{ cursor: "pointer" }}>
            Select files to upload
            <VisuallyHiddenInput
              type="file"
              multiple
              onChange={e => e.target.files && appendFiles([...e.target.files])}
            />
          </Link>
          <Typography variant="body2">or drag and drop here</Typography>
        </Stack>
      </FileInputBox>

      <Stack width="100%" maxHeight={400} sx={{ overflowY: "auto" }}>
        {files.map((fileState, i) => {
          const isCustom = isUploadCustom(currentReport, fileState);
          return (
            <Stack direction="row" width="100%" p={1} gap={2} alignItems="center" key={i}>
              <NoteAddIcon sx={{ color: isCustom ? "warning.main" : "primary.main" }} />
              <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="body1">{fileState.file.name}</Typography>
                {isCustom && (
                  <Typography variant="body2" sx={{ color: isCustom ? "warning.main" : undefined }}>
                    Scores not included in test plan
                  </Typography>
                )}
              </Stack>
              {isCustom && (
                <SelectInput
                  label="Reason Added"
                  options={includeReasonOptions}
                  value={fileState.includeReasonId}
                  onChange={includeReasonId => updateFileAtIndex(i, { includeReasonId })}
                  width={150}
                  size="small"
                />
              )}
              <SelectInput
                label="Test"
                options={sourceOptions}
                value={fileState.sourceId}
                onChange={sourceId => updateFileAtIndex(i, { sourceId })}
                width={230}
                size="small"
              />
              <IconButton onClick={() => deleteFileAtIndex(i)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>

      <Stack direction="row" width="100%" justifyContent="space-between">
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <ProcessButton variant="contained" disabled={!isValid} process={uploadFiles} startIcon={<UploadIcon />}>
          Upload
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default BulkUploadInput;
