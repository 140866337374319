import { ClientLogger, initClientLogger } from "@parallel/polygon/util/logging.util";
import { loggingApi } from "@/api/logging.api";

export const initLogger = (tag: string, getCommonContext: () => any): ClientLogger =>
  initClientLogger({
    source: "Pathway",
    tag,
    postLog: loggingApi.postLog,
    getCommonContext,
  });
