import { useState } from "react";
import { debounce, isNull, sortBy, uniqBy } from "lodash";
import AutoCompleteInput, {
  BaseAutoCompleteProps,
  SelectOption,
} from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { mapExists, toArray } from "@parallel/vertex/util/collection.util";
import { getLoggerContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const EMPTY_INPUT_TEXT = "Type to Search";
const SEARCH_DEBOUNCE_MS = 250;

const logger = initLogger("AutoCompletePageSearchInput", getLoggerContext);

export const AutoCompletePageSearchInput = <S extends SelectOption | null | SelectOption[], R>(
  props: BaseAutoCompleteProps<S> & {
    search: (input: string) => Promise<PaginatedResult<R> | null>;
    getOption: (record: R) => SelectOption | null;
  },
) => {
  const [loadingText, setLoadingText] = useState<string | undefined>(EMPTY_INPUT_TEXT);
  const [options, setOptions] = useState<SelectOption[]>();
  const [totalCount, setTotalCount] = useState<number>();

  const selectedOptions: SelectOption[] = toArray(props.selected);

  const loadOptions = debounce(
    (input: string) =>
      props
        .search(input)
        .then(page => {
          if (isNull(page)) {
            setLoadingText("Error Searching Options");
            return;
          }
          const options = sortBy(mapExists(page.records, props.getOption), "groupName");
          setLoadingText(options.length > 0 ? undefined : "No Matches Found");
          setOptions(uniqBy([...options, ...selectedOptions], "key"));
          setTotalCount(page.totalCount);
        })
        .catch(e => {
          logger.error("error searching input options", { causedBy: e });
          setLoadingText("Error Searching Options");
        }),
    SEARCH_DEBOUNCE_MS,
  );

  const handleInput = (input: string) => {
    if (props.disabled) return;
    setLoadingText("Loading");
    setOptions(undefined);
    setTotalCount(undefined);
    loadOptions(input);
  };

  return (
    <AutoCompleteInput
      {...props}
      options={options}
      onInput={handleInput}
      loadingText={loadingText}
      totalMatchCount={totalCount}
    />
  );
};

export default AutoCompletePageSearchInput;
