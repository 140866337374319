import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReportEditorBlock } from "@parallel/vertex/types/assessment/assessment.report.types";
import BlockContentInput from "@/components/report/editor/content/BlockContentInput";
import BlockTable from "@/components/report/editor/content/BlockTable";
import StaticBlockContent from "@/components/report/editor/content/StaticBlockContent";

const ReportEditorBlockComponent = ({ block }: { block: ReportEditorBlock }) => {
  return (
    <Stack gap={2}>
      <Box width="100%">
        {block.canEdit ? <BlockContentInput block={block} /> : <StaticBlockContent block={block} />}
      </Box>

      {block.table && <BlockTable block={block} />}
    </Stack>
  );
};

export default ReportEditorBlockComponent;
