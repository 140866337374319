import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import {
  CreateFacilitatorBody,
  ExtendedFacilitatorUser,
  SearchFacilitatorQuery,
  UpdateFacilitatorBody,
} from "@parallel/vertex/types/user/facilitator.types";
import {
  CreateProviderBody,
  ExtendedProviderUser,
  SearchProviderQuery,
  UpdateProviderBody,
} from "@parallel/vertex/types/user/provider.types";
import { ExtendedSlpaUser, SearchSlpaQuery } from "@parallel/vertex/types/user/slpa.types";
import { CreateSlpaBody, UpdateSlpaBody } from "@parallel/vertex/types/user/slpa.types";
import {
  CreateStudentBody,
  ExtendedStudentUser,
  SearchStudentQuery,
  SingleStudentUser,
  UpdateStudentAssignmentBody,
  UpdateStudentBody,
} from "@parallel/vertex/types/user/student.types";
import {
  CreateAdminBody,
  ExtendedUser,
  SearchUserQuery,
  UpdateUserBody,
  UserArchiveReason,
} from "@parallel/vertex/types/user/user.types";
import { BaseAPI } from "@/api/base.api";

export class UserAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public getUser = (userId: string): Promise<ExtendedUser> => this.instance.get(`user/${userId}`).then(r => r.data);

  public searchUsers = (params: SearchUserQuery): Promise<PaginatedResult<ExtendedUser>> =>
    this.instance.get("user", { params }).then(r => r.data);

  public updateUser = (userId: string, body: UpdateUserBody): Promise<ExtendedUser> =>
    this.instance.put(`user/${userId}`, body).then(r => r.data);

  public searchStudents = (params: SearchStudentQuery): Promise<PaginatedResult<ExtendedStudentUser>> =>
    this.instance.get("student", { params }).then(r => r.data);

  public getStudent = (studentId: string): Promise<SingleStudentUser> =>
    this.instance.get(`student/${studentId}`).then(r => r.data);

  public createStudent = (body: CreateStudentBody): Promise<ExtendedStudentUser> =>
    this.instance.post("student", body).then(r => r.data);

  public updateStudent = (studentId: string, body: UpdateStudentBody): Promise<SingleStudentUser> =>
    this.instance.put(`student/${studentId}`, body).then(r => r.data);

  public updateStudentAssignments = (body: UpdateStudentAssignmentBody): Promise<ExtendedStudentUser[]> =>
    this.instance.put("student/assignment", body).then(r => r.data.students);

  public archiveStudent = (studentId: string, reasonId: string): Promise<SingleStudentUser> =>
    this.instance.delete(`student/${studentId}`, { data: { reasonId } }).then(r => r.data);

  public restoreStudent = (studentId: string, body: UpdateStudentBody): Promise<SingleStudentUser> =>
    this.instance.post(`student/${studentId}/restore`, body).then(r => r.data);

  public searchProviders = (params: SearchProviderQuery): Promise<PaginatedResult<ExtendedProviderUser>> =>
    this.instance.get("provider", { params }).then(r => r.data);

  public getProvider = (providerId: string): Promise<ExtendedProviderUser> =>
    this.instance.get(`provider/${providerId}`).then(r => r.data);

  public createProvider = (body: CreateProviderBody): Promise<ExtendedProviderUser> =>
    this.instance.post("provider", body).then(r => r.data);

  public updateProvider = (providerId: string, body: UpdateProviderBody): Promise<ExtendedProviderUser> =>
    this.instance.put(`provider/${providerId}`, body).then(r => r.data);

  public searchFacilitators = (params: SearchFacilitatorQuery): Promise<PaginatedResult<ExtendedFacilitatorUser>> =>
    this.instance.get("facilitator", { params }).then(r => r.data);

  public createFacilitator = (
    body: CreateFacilitatorBody,
  ): Promise<ExtendedFacilitatorUser & { welcomeEmailFailed?: boolean }> =>
    this.instance.post("facilitator", body).then(r => r.data);

  public updateFacilitator = (facilitatorId: string, body: UpdateFacilitatorBody): Promise<ExtendedFacilitatorUser> =>
    this.instance.put(`facilitator/${facilitatorId}`, body).then(r => r.data);

  public deleteFacilitator = (facilitatorId: string): Promise<void> =>
    this.instance.delete(`facilitator/${facilitatorId}`);

  public sendFacilitatorWelcomeEmail = (facilitatorId: string): Promise<void> =>
    this.instance.post(`facilitator/${facilitatorId}/welcome-email`);

  public searchSlpas = (params: SearchSlpaQuery): Promise<PaginatedResult<ExtendedSlpaUser>> =>
    this.instance.get("slpa", { params }).then(r => r.data);

  public createSlpa = (body: CreateSlpaBody): Promise<ExtendedSlpaUser & { welcomeEmailFailed?: boolean }> =>
    this.instance.post("slpa", body).then(r => r.data);

  public updateSlpa = (slpaId: string, body: UpdateSlpaBody): Promise<ExtendedSlpaUser> =>
    this.instance.put(`slpa/${slpaId}`, body).then(r => r.data);

  public deleteSlpa = (slpaId: string): Promise<void> => this.instance.delete(`slpa/${slpaId}`);

  public sendSlpaWelcomeEmail = (slpaId: string): Promise<void> => this.instance.post(`slpa/${slpaId}/welcome-email`);

  public createAdmin = (body: CreateAdminBody): Promise<ExtendedUser> =>
    this.instance.post(`user/admin`, body).then(r => r.data);

  public getAllUserArchiveReasons = (): Promise<UserArchiveReason[]> =>
    this.instance.get("user/archive-reason").then(r => r.data.reasons);
}
