import { isEmpty } from "lodash";
import TurndownService from "turndown";
import {
  ReportEditorBlock,
  ReportEditorParentSection,
  ReportEditorSubsection,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { filterExists } from "@parallel/vertex/util/collection.util";

const turndownService = new TurndownService();

export const getBlockTableMarkdown = (block: ReportEditorBlock): string => {
  if (!block.table) return "";

  const headerValues = block.table.columns.map(c => c.header);
  const headerLine = headerValues.join(" | ");

  const tableRows = block.custom?.tableRows || block.table.defaultRows;
  const rowsLines = tableRows?.map(row => row.cellValues.join(" | ")) || [];

  return [headerLine, ...rowsLines].join("\n");
};

export const getBlockContentMarkdown = (contentHtml: string): string => turndownService.turndown(contentHtml);

export const getBlockMarkdown = (block: ReportEditorBlock, withTable: boolean = false): string => {
  const contentHtml = block.custom?.content || block.content;

  const contentLines = filterExists([
    contentHtml && getBlockContentMarkdown(contentHtml),
    withTable && getBlockTableMarkdown(block),
  ]);

  return !isEmpty(contentLines) ? contentLines.join("\n\n") : "";
};

export const getSubsectionMarkdown = (section: ReportEditorSubsection): string => {
  const blockStrings = section.blocks.map(b => getBlockMarkdown(b, true));
  return [`### ${section.title}`, ...blockStrings].join("\n\n");
};

export const getParentSectionMarkdown = (section: ReportEditorParentSection): string => {
  const childrenStrings = section.children.map(child => {
    switch (child.type) {
      case "subsection":
        return getSubsectionMarkdown(child);
      case "block":
        return getBlockMarkdown(child);
    }
  });
  return [`## ${section.title}`, ...childrenStrings].join("\n\n");
};
