import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { capitalize, isInteger, isNaN } from "lodash";
import { ObjectiveType } from "@parallel/vertex/types/progress.types";
import SelectInput from "@/components/shared/input/SelectInput";
import { getValidObjectiveValue } from "@/util/progress.form.util";

const NumberInput = ({
  label,
  value,
  setValue,
  errorText,
}: {
  label: string;
  value?: number;
  setValue: (n?: number) => void;
  errorText?: string;
}) => {
  const [inputValue, setInputValue] = useState(value?.toString() || "");

  const onChange = (valueString: string) => {
    if (valueString.match(/[^\d\.]/)) return;
    setInputValue(valueString);
    const value = parseFloat(valueString);
    isNaN(value) || value < 0 ? setValue(undefined) : setValue(value);
  };

  return (
    <TextField
      fullWidth
      label={label}
      value={inputValue}
      onChange={e => onChange(e.target.value)}
      error={!!errorText}
      helperText={errorText}
    />
  );
};

const ALL_DURATION_UNITS = ["minutes", "seconds"] as const;
type DurationUnit = (typeof ALL_DURATION_UNITS)[number];

const DURATION_CONVERSIONS = {
  minutes: {
    toDisplay: (seconds: number) => seconds / 60,
    toValue: (minutes: number) => minutes * 60,
  },
  seconds: {
    toDisplay: (seconds: number) => seconds,
    toValue: (seconds: number) => seconds,
  },
};

const DurationInput = ({ value, setValue }: { value?: number; setValue: (n?: number) => void }) => {
  const [unit, setUnit] = useState<DurationUnit>(!value || isInteger(value / 60) ? "minutes" : "seconds");

  const onChange = (valueString: string) => {
    const value = parseFloat(valueString);
    if (isNaN(value) || value < 0) {
      setValue(undefined);
      return;
    }
    setValue(DURATION_CONVERSIONS[unit].toValue(value));
  };

  return (
    <Stack direction="row" gap={1}>
      <Box sx={{ flex: "1 1 0%" }}>
        <TextField
          fullWidth
          label="Target Duration"
          value={value ? DURATION_CONVERSIONS[unit].toDisplay(value) : ""}
          onChange={e => onChange(e.target.value)}
        />
      </Box>
      <SelectInput
        options={ALL_DURATION_UNITS.map(key => ({ key, label: capitalize(key) }))}
        value={unit}
        onChange={setUnit}
        width={150}
      />
    </Stack>
  );
};

const ObjectiveTargetInput = ({
  objectiveType,
  value,
  setValue,
}: {
  objectiveType: ObjectiveType;
  value?: number;
  setValue: (n?: number) => void;
}) => {
  const validValue = getValidObjectiveValue(value, objectiveType);
  switch (objectiveType.category) {
    case "ACCURACY":
      return (
        <NumberInput
          label="Target Accuracy"
          value={value}
          setValue={setValue}
          errorText={value && !validValue ? "Invalid Percentage" : undefined}
        />
      );
    case "DURATION":
      return <DurationInput value={value} setValue={setValue} />;
    case "DECREASING_FREQUENCY":
    case "INCREASING_FREQUENCY":
    case "PROMPTING_FREQUENCY":
      return (
        <NumberInput
          label="Target Frequency"
          value={value}
          setValue={setValue}
          errorText={value && !validValue ? "Invalid Count" : undefined}
        />
      );
  }
};

export default ObjectiveTargetInput;
