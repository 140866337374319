import { useEffect, useContext, useState } from "react";
import HeadsetMic from "@mui/icons-material/HeadsetMic";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { initLogger } from "@parallel/vertex/util/logging.util";
import { StoreContext } from "@/stores";

const logger = initLogger("ZendeskWidget");

const ZendeskWidget = () => {
  const {
    apiStore: { authApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    const setupZendesk = async () => {
      if (window.zE && currentUser) {
        window.zE("messenger", "loginUser", async (callback: (_: string) => void) => {
          try {
            const token = await authApi.zendeskSignIn(currentUser);
            callback(token);
          } catch (error) {
            logger.error("Error signing in to Zendesk:", error);
          }
        });

        window.zE("messenger:set", "cookies", true);
        window.zE("messenger", "show");

        window.zE("messenger:on", "unreadMessages", function (count: number) {
          setUnreadMessages(count);
        });

        logger.info("Zendesk widget loaded");
      } else if (window.zE) {
        window.zE("messenger", "logoutUser");
        window.zE("messenger:set", "cookies", false);

        logger.info("Zendesk widget unloaded");
      }
    };

    if (window.zE) {
      setupZendesk();
    } else {
      logger.warn("Zendesk widget is not available");
    }

    return () => {
      if (window.zE) {
        window.zE("messenger", "logoutUser");
        window.zE("messenger:set", "cookies", false);

        logger.info("Zendesk widget unloaded (cleanup)");
      }
    };
  }, [currentUser, authApi]);

  return (
    <Tooltip title={<Typography variant="body2"> In-App Messaging </Typography>} placement="bottom">
      <Button
        sx={{
          px: 3,
          color: "white",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        }}
        onClick={() => window.zE("messenger", "open")}
      >
        <Badge badgeContent={unreadMessages} color="error" invisible={unreadMessages === 0}>
          <HeadsetMic fontSize="small" />
        </Badge>
      </Button>
    </Tooltip>
  );
};

export default observer(ZendeskWidget);
