import { ReactNode, useContext } from "react";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { observer } from "mobx-react-lite";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import SubHeader, { HeaderNavigation } from "@/components/shared/layout/SubHeader";
import { StoreContext } from "@/stores";

const CalendarItemSubHeader = <A extends string>({
  appointment,
  navigation,
  actions,
}: {
  appointment: ExtendedAppointment;
  navigation: HeaderNavigation<A>;
  actions?: ReactNode;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  return (
    <SubHeader
      icon={<EventIcon />}
      title={appointment.title}
      subtitle={appointment.startTime.setZone(timezone).toFormat("EEE MM/dd, h:mm a")}
      badges={[
        {
          label: appointment.startTime.setZone(timezone).toFormat("cccc LLLL d, yyyy"),
          icon: <EventIcon />,
        },
        {
          label: appointment.startTime.setZone(timezone).toFormat("h:mm a ZZZZ"),
          icon: <ScheduleIcon />,
        },
      ]}
      navigation={navigation}
      actions={actions}
    />
  );
};

export default observer(CalendarItemSubHeader);
