import { InterviewFormType } from "@parallel/vertex/enums/report.enums";
import {
  CreateReportBody,
  ExtendedAssessmentEnrollment,
  ExtendedReport,
  ExtendedReportTestUpload,
  ReportSectionCustom,
  ReportTestUploadIncludeReason,
  ReportTestUploadSkipReason,
  ReportTestUploadSource,
  SearchAssessmentEnrollmentQuery,
  SearchReportQuery,
  SingleReport,
  UpdateReportBlockBody,
  UpdateReportSectionBody,
  UpdateReportSectionOrderBody,
  UpdateReportUploadBody,
  UpdateTestingPlanBody,
} from "@parallel/vertex/types/assessment/assessment.report.types";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "@/api/base.api";

export type ReportFileUploadRequest = {
  sourceId: string;
  file: File;
  includeReasonId?: string;
};

export class ReportAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true, parseQueryNulls: true });
  }

  public searchAssessmentEnrollments = (
    params: SearchAssessmentEnrollmentQuery,
  ): Promise<PaginatedResult<ExtendedAssessmentEnrollment>> =>
    this.instance.get("assessment/report/enrollment", { params }).then(r => r.data);

  public getAssessmentEnrollment = (serviceLineClientId: string): Promise<ExtendedAssessmentEnrollment> =>
    this.instance.get(`assessment/report/enrollment/${serviceLineClientId}`).then(r => r.data);

  public getReport = (reportId: string): Promise<SingleReport> =>
    this.instance.get(`assessment/report/${reportId}`).then(r => r.data);

  public createReport = (body: CreateReportBody): Promise<SingleReport> =>
    this.instance.post("assessment/report", body).then(r => r.data);

  public searchReports = (query: SearchReportQuery): Promise<PaginatedResult<ExtendedReport>> =>
    this.instance.get("assessment/report", { params: query }).then(r => r.data);

  public updateTestingPlan = (reportId: string, body: UpdateTestingPlanBody): Promise<SingleReport> =>
    this.instance.put(`assessment/report/${reportId}/testing-plan`, body).then(r => r.data);

  public uploadTestFiles = (
    reportId: string,
    files: ReportFileUploadRequest[],
  ): Promise<ExtendedReportTestUpload[]> => {
    const formData = new FormData();
    files.forEach(({ file, sourceId, includeReasonId }) =>
      formData.append(includeReasonId ? `${sourceId}~${includeReasonId}` : sourceId, file, file.name),
    );
    return this.instance.post(`assessment/report/${reportId}/test`, formData).then(r => r.data.uploads);
  };

  public updateTestUpload = (
    reportId: string,
    testUploadId: string,
    body: UpdateReportUploadBody,
  ): Promise<ExtendedReportTestUpload> =>
    this.instance.put(`assessment/report/${reportId}/test/${testUploadId}`, body).then(r => r.data);

  public getTestUploadFileUrl = (reportId: string, testUploadId: string): Promise<string> =>
    this.instance.get(`assessment/report/${reportId}/test/${testUploadId}/file`).then(r => r.data.url);

  public upsertCustomSection = (
    reportId: string,
    sectionTemplateId: string,
    body: UpdateReportSectionBody,
  ): Promise<ReportSectionCustom> =>
    this.instance.put(`assessment/report/${reportId}/section/${sectionTemplateId}`, body).then(r => r.data);

  public updateSubsectionOrder = (
    reportId: string,
    sectionTemplateId: string,
    body: UpdateReportSectionOrderBody,
  ): Promise<void> =>
    this.instance.put(`assessment/report/${reportId}/section/${sectionTemplateId}/subsection-order`, body);

  public upsertCustomBlock = (
    reportId: string,
    blockTemplateId: string,
    body: UpdateReportBlockBody,
  ): Promise<SingleReport> =>
    this.instance.put(`assessment/report/${reportId}/block/${blockTemplateId}`, body).then(r => r.data);

  public getAllTestUploadSources = (): Promise<ReportTestUploadSource[]> =>
    this.instance.get("assessment/report/test/source").then(r => r.data.sources);

  public getAllTestUploadIncludeReasons = (): Promise<ReportTestUploadIncludeReason[]> =>
    this.instance.get("assessment/report/test/include-reason").then(r => r.data.reasons);

  public getAllTestUploadSkipReasons = (): Promise<ReportTestUploadSkipReason[]> =>
    this.instance.get("assessment/report/test/skip-reason").then(r => r.data.reasons);

  public createInterviewNotesSubmission = ({
    interviewType,
    reportId,
  }: {
    interviewType: InterviewFormType;
    reportId: string;
  }) => this.instance.post(`assessment/report/${reportId}/interview-form/${interviewType}`).then(r => r.data);
}
