import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { FullBox, FullCenterBox, FullStack } from "@parallel/polygon/components/shared/layout/container";
import ReportContent from "@/components/report/ReportContent";
import ReportSidebar from "@/components/report/ReportSidebar";
import PrimaryLayout from "@/components/shared/layout/PrimaryLayout";
import SubHeader from "@/components/shared/layout/SubHeader";
import LoadingScreen from "@/screens/LoadingScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { StoreContext } from "@/stores";
import { getShortServiceLineTitle } from "@/util/service.util";

const AssessmentReportScreen = () => {
  const {
    reportStore: { currentReport: report, fetchStatus, loadReport },
  } = useContext(StoreContext);

  const { reportId } = useParams();
  useEffect(() => {
    loadReport(reportId);
  }, [reportId]);

  if (fetchStatus === "not-found") return <NotFoundScreen />;
  if (["loading", "waiting"].includes(fetchStatus) || !report) return <LoadingScreen />;

  const shortName = getShortServiceLineTitle(report.serviceLine).replace("Assessment - ", "");
  const header = (
    <SubHeader
      icon={<AssessmentIcon />}
      title={report.client.fullName}
      subtitle={`${shortName} due ${report.assessmentDueDate.toLocaleString({ month: "2-digit", day: "numeric" })}`}
    />
  );

  return (
    <PrimaryLayout headerContent={header} containerStyles={{ p: 0 }}>
      <FullStack direction="row">
        <Box width={220} p={2} sx={{ borderRight: 1, borderColor: "grey.300" }}>
          <ReportSidebar />
        </Box>
        <FullCenterBox flex="1 1 0%" sx={{ overflowY: "auto" }}>
          <FullBox height="100%" maxWidth={1200} p={2}>
            <ReportContent />
          </FullBox>
        </FullCenterBox>
      </FullStack>
    </PrimaryLayout>
  );
};

export default observer(AssessmentReportScreen);
