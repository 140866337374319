import { useContext } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { getOperationResultName, ProgressUpdateOperation } from "@parallel/polygon/util/progress.util";
import { StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("UpdateObjectivePrompt", getLoggerContext);

const UpdateObjectivePrompt = ({
  operation,
  objective,
  goal,
  studentId,
  onGoalUpdated,
}: {
  operation: ProgressUpdateOperation;
  objective: { record: StudentObjective; displayIndex: number };
  goal: { record: StudentGoal; displayIndex: number };
  studentId: string;
  onGoalUpdated: (goal: StudentGoal) => void;
}) => {
  const { progressStore } = useContext(StoreContext);

  const performUpdate = () => {
    const common = {
      goal: goal.record,
      objectiveId: objective.record.objectiveId,
      studentId,
    };
    switch (operation) {
      case "complete":
        return progressStore
          .setObjectiveCompleted({ ...common, isCompleted: true })
          .then(onGoalUpdated)
          .catch(logger.handleFailureAndThrow("setObjectiveCompleted"));
      case "archive":
        return progressStore
          .setObjectiveArchived({ ...common, isArchived: true })
          .then(onGoalUpdated)
          .catch(logger.handleFailureAndThrow("setObjectiveArchived"));
    }
  };

  const resultName = getOperationResultName(operation);

  return (
    <PromptLayout
      headerText={`Mark Objective ${capitalize(resultName)}`}
      leftAction={{ fn: progressStore.clearPendingUpdate, label: "Back" }}
      rightAction={{
        fn: performUpdate,
        label: `Objective ${capitalize(resultName)}`,
        icon: <TaskAltIcon />,
      }}
    >
      <Stack gap={2} width={550}>
        {operation === "complete" && (
          <Typography variant="body1">
            The student has met this objective. No further data will be captured for this objective.
          </Typography>
        )}
        <Stack p={2} gap={1} sx={{ bgcolor: "highlight.main", borderRadius: 1 }}>
          <Typography variant="subtitle1">
            {goal.displayIndex}.{objective.displayIndex} {toTitleCase(objective.record.category)} Objective
          </Typography>
          <Typography variant="body1">{objective.record.description}</Typography>
        </Stack>
        <Typography variant="body1">Are you sure you want to mark this objective {resultName}?</Typography>
      </Stack>
    </PromptLayout>
  );
};

export default UpdateObjectivePrompt;
