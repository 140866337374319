import type { CalendarBlock } from "@prisma/client";
import { z } from "zod";
import { recurrenceEditModeEnum } from "../../enums/calendar.enums";
import { ExtendedCalendarItem, timeRangeFields, timeRangeRefine } from "./calendar.types";
import { ExtendedRecurrence, recurrenceBody } from "./recurrence.types";

export type ExtendedCalendarBlock = ExtendedCalendarItem<CalendarBlock> & {
  userName: string;
  recurrence?: ExtendedRecurrence;
};

const calendarBlockBody = z.object({
  blockType: z.enum(["Availability", "FocusTime"]),
  title: z.string().optional(),
  userId: z.string().uuid().optional(),
  ...timeRangeFields,
  recurrence: recurrenceBody.optional(),
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export const createCalendarBlockBodySchema = calendarBlockBody.refine(...timeRangeRefine);

export type CreateCalendarBlockBody = z.infer<typeof createCalendarBlockBodySchema>;

export const updateCalendarBlockBodySchema = calendarBlockBody.partial().refine(...timeRangeRefine);

export type UpdateCalendarBlockBody = z.infer<typeof updateCalendarBlockBodySchema>;

export const deleteCalendarBlockBodySchema = z.object({
  recurrenceEditMode: recurrenceEditModeEnum.optional(),
});

export type DeleteCalendarBlockBody = z.infer<typeof deleteCalendarBlockBodySchema>;
