import { useContext, useState } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { debounce } from "lodash";
import { DateTime } from "luxon";
import { Status, StatusIcon } from "@parallel/polygon/components/shared/input/status.input";
import {
  AppointmentDetails,
  ExtendedAppointment,
  ExtendedAppointmentUser,
  UpdateAppointmentUserBody,
} from "@parallel/vertex/types/calendar/appointment.types";
import { getLoggerContext, StoreContext } from "@/stores";
import { resolveShouldSkipAttendance } from "@/util/calendar.util";
import { initLogger } from "@/util/logging.util";
import { getAttendanceState } from "@/util/student.util";

const logger = initLogger("StudentAttendanceDisplay", getLoggerContext);

const StudentAttendanceDisplay = ({
  appointment,
  appointmentDetails,
  student,
  canEdit,
}: {
  appointment: ExtendedAppointment;
  appointmentDetails: AppointmentDetails;
  student: ExtendedAppointmentUser;
  canEdit: boolean;
}) => {
  const {
    authStore: { shortTimezone, timezone },
    calendarStore,
  } = useContext(StoreContext);

  const initialAttendanceState = getAttendanceState(student, appointmentDetails, timezone);
  const [stagedAttendanceState, setStagedAttendanceState] = useState(initialAttendanceState);

  const [status, setStatus] = useState<Status>();
  const updateStudent = (request: UpdateAppointmentUserBody) => {
    setStagedAttendanceState({ ...stagedAttendanceState, ...request });
    setStatus({ value: "loading" });
    calendarStore
      .updateAppointmentStudent(appointment.appointmentId, student.userId, request)
      .then(() => {
        setStatus({ value: "success" });
        setTimeout(() => setStatus(undefined), 5000);
      })
      .catch(e => {
        logger.logFailure("updateAppointmentStudent", e);
        setStatus({ value: "failure", message: "Student update failed" });
        setStagedAttendanceState(initialAttendanceState);
      });
  };

  const setValidTime = (key: "joinedAt" | "leftAt", time: DateTime | null) => {
    if (!time?.isValid) return;
    const updateTime = appointment.startTime.setZone(timezone).set({ hour: time.hour, minute: time.minute });
    if (stagedAttendanceState[key]?.equals(updateTime)) return;
    updateStudent({ [key]: updateTime });
  };
  const onJoinedAtChange = debounce((time: DateTime | null) => setValidTime("joinedAt", time), 1000);
  const onLeftAtChange = debounce((time: DateTime | null) => setValidTime("leftAt", time), 1000);

  const shouldSkipAttendance = resolveShouldSkipAttendance(appointment);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Link href={`/user/student/${student.userId}`}>
          <Stack direction="row" alignItems="center" gap={2}>
            <GroupsIcon />
            <Typography variant="body1">{student.fullName}</Typography>
          </Stack>
        </Link>

        <FormControl disabled={!canEdit}>
          <Stack direction="row" alignItems="center" gap={1}>
            <InputAdornment position="start">
              <StatusIcon status={status} size={20} />
            </InputAdornment>
            {!shouldSkipAttendance && (
              <FormControlLabel
                label={
                  <Typography variant="body2" sx={{ marginLeft: 1.5, marginRight: "-16px" }}>
                    {stagedAttendanceState.hasAttended ? "Present" : "Absent"}
                  </Typography>
                }
                control={
                  <Switch
                    size="small"
                    checked={stagedAttendanceState.hasAttended}
                    onChange={e => updateStudent({ hasAttended: e.target.checked })}
                  />
                }
              />
            )}
          </Stack>
        </FormControl>
      </Stack>

      {stagedAttendanceState.hasAttended && !shouldSkipAttendance && (
        <Stack direction="row" gap={1} mt={2} mb={1}>
          <TimePicker
            label={`Joined At (${shortTimezone})`}
            value={stagedAttendanceState.joinedAt}
            onChange={onJoinedAtChange}
            slotProps={{
              textField: {
                size: "small",
                sx: { bgcolor: "white" },
                fullWidth: true,
              },
            }}
            disabled={!canEdit}
          />
          <TimePicker
            label={`Left At (${shortTimezone})`}
            value={stagedAttendanceState.leftAt}
            onChange={onLeftAtChange}
            slotProps={{
              textField: {
                size: "small",
                sx: { bgcolor: "white" },
                fullWidth: true,
              },
            }}
            disabled={!canEdit}
          />
        </Stack>
      )}
    </Box>
  );
};

export default StudentAttendanceDisplay;
