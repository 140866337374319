import { ReactNode, useContext, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import ReportStudentForm from "@/components/report/editor/content/ReportStudentForm";
import CenterModal from "@/components/shared/layout/CenterModal";
import { StoreContext } from "@/stores";

const InfoRow = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <Stack direction="row" height="40px" width="100%" sx={{ borderTop: 1, borderColor: "grey.200" }}>
      <Stack direction="row" height="100%" width="25%" pl={2} alignItems="center" sx={{ bgcolor: "highlight.main" }}>
        <Typography variant="subtitle1">{label}</Typography>
      </Stack>

      <Stack direction="row" height="100%" width="75%" pl={2} alignItems="center">
        {value}
      </Stack>
    </Stack>
  );
};

const GeneralInformationTable = () => {
  const {
    reportStore: { currentReport, testingSessions },
  } = useContext(StoreContext);

  const [isEditing, setIsEditing] = useState(false);

  if (!currentReport) return <></>;

  const birthDate = currentReport.client.birthDate;

  return (
    <Stack sx={{ borderBottom: 1, borderColor: "grey.200", position: "relative" }}>
      <InfoRow label="Student Name" value={<Typography variant="body1">{currentReport.client.fullName}</Typography>} />
      <InfoRow
        label="Date of Birth"
        value={
          birthDate && (
            <Typography variant="body1">
              {birthDate.toLocaleString(DateTime.DATE_FULL)} (Age{" "}
              {Math.floor(Math.abs(birthDate.diffNow("years").years))})
            </Typography>
          )
        }
      />
      <InfoRow
        label="Gender Pronouns"
        value={<Typography variant="body1">{currentReport.client.pronouns}</Typography>}
      />
      <InfoRow label="Campus" value={<Typography variant="body1">{currentReport.client.campus?.name}</Typography>} />
      <InfoRow label="Grade" value={<Typography variant="body1">{currentReport.client.grade}</Typography>} />
      <Box height="20px" sx={{ borderTop: 1, borderColor: "grey.200" }} />
      <InfoRow label="Clinician" value={<Typography variant="body1">{currentReport.provider.fullName}</Typography>} />
      <InfoRow
        label="Assessment Dates"
        value={
          <Typography variant="body1">
            {testingSessions?.map(a => a.startTime.toLocaleString(DateTime.DATE_SHORT)).join(", ")}
          </Typography>
        }
      />
      {/* TODO show date report was finished + approved here once available */}
      <InfoRow label="Report Date" value={<Typography variant="body1">{""}</Typography>} />

      <Button
        endIcon={<ChevronRightIcon />}
        sx={{ position: "absolute", right: -230 }}
        onClick={() => setIsEditing(true)}
      >
        Edit Student Information
      </Button>

      <CenterModal isOpen={isEditing} onClose={() => setIsEditing(false)} minWidth={500}>
        {isEditing && <ReportStudentForm report={currentReport} onFinished={() => setIsEditing(false)} />}
      </CenterModal>
    </Stack>
  );
};

export default GeneralInformationTable;
