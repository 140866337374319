import { cloneElement, useContext } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import { flatMap, groupBy, max, min, partition, pick, sortBy } from "lodash";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import { percentString } from "@parallel/polygon/util/style.util";
import { ExtendedCalendarBlock } from "@parallel/vertex/types/calendar/calendar.block.types";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { isOverlap, isWithin } from "@parallel/vertex/util/datetime.util";
import { getInitials } from "@parallel/vertex/util/string.util";
import CalendarItem, { CalendarItemData } from "@/components/calendar/CalendarItem";
import { StoreContext } from "@/stores";
import { useUrlNavigation } from "@/util/router.util";

type OverlapGroup = {
  items: CalendarItemData[];
  startTime: DateTime;
  endTime: DateTime;
};

type BoxProps = {
  top?: string;
  left?: string;
  width?: string;
  height?: string;
};

type PositionedItemData = {
  data: CalendarItemData;
  boxProps: BoxProps;
  offsetCount: number;
};

const groupOverlaps = (data: CalendarItemData[]): OverlapGroup[] =>
  sortBy(data, "startTime").reduce((currGroups, nextItem) => {
    const overlapGroupIndex = currGroups.findIndex(group => isOverlap(group, nextItem));
    if (overlapGroupIndex === -1)
      return [...currGroups, { items: [nextItem], ...pick(nextItem, "startTime", "endTime") }];

    const overlapGroup = currGroups[overlapGroupIndex];
    const updatedGroup: OverlapGroup = {
      items: [...overlapGroup.items, nextItem],
      startTime: min([overlapGroup.startTime, nextItem.startTime]) || overlapGroup.startTime,
      endTime: max([overlapGroup.endTime, nextItem.endTime]) || overlapGroup.endTime,
    };
    return currGroups.map((g, i) => (i === overlapGroupIndex ? updatedGroup : g));
  }, [] as OverlapGroup[]);

const CalendarBlockBox = ({
  blockType,
  boxProps,
  onSelect,
  text,
}: {
  blockType: "availability" | "focus-time";
  boxProps: BoxProps;
  onSelect: () => void;
  text?: string;
}) => {
  const theme = useTheme();
  const icon = blockType === "availability" ? <EventAvailableIcon /> : <HeadphonesIcon />;
  return (
    <Stack
      direction="row"
      gap={0.5}
      {...boxProps}
      position="absolute"
      left={0}
      width="100%"
      padding="1px"
      sx={{
        bgcolor: blockType === "focus-time" ? alpha(theme.palette.primary.main, 0.25) : undefined,
        border: blockType == "focus-time" ? undefined : 2,
        borderColor: "primary.main",
        padding: blockType === "focus-time" ? "2px" : undefined,
      }}
      borderRadius="4px"
    >
      <Tooltip title={text} placement="top">
        <Stack
          width="28px"
          height="100%"
          borderRadius="4px"
          bgcolor="grey.100"
          alignItems="center"
          py={0.75}
          onClick={onSelect}
          sx={{ cursor: "pointer" }}
        >
          {cloneElement(icon, { color: "primary", sx: { width: "0.875em", height: "0.875em" } })}
        </Stack>
      </Tooltip>
      {text && (
        <Typography variant="body2" p={1}>
          {text}
        </Typography>
      )}
    </Stack>
  );
};

const CalendarColumn = ({ date }: { date: DateTime }) => {
  const {
    calendarStore: { calendarItems: items, hourRange },
  } = useContext(StoreContext);

  const { navigate } = useUrlNavigation();

  const startTime = date.set({ hour: hourRange.min }).startOf("hour");
  const endTime = date.set({ hour: hourRange.max }).startOf("hour");
  const totalMinutes = (hourRange.max - hourRange.min) * 60;

  // TODO add some UX for these instead of just hiding them
  const filterItems = <A extends DateTimeRange>(items: A[]) => items.filter(i => isWithin({ startTime, endTime }, i));

  const appointmentData: CalendarItemData[] = filterItems(items.appointments).map(a => ({
    ...a,
    key: a.appointmentId,
    status: a.appointmentStatus || undefined,
    attendees: a.students.map(s => ({ ...s, initials: getInitials(s) })),
    onClick: () => navigate(`/calendar/appointment/${a.appointmentId}`, { keepQuery: true }),
  }));
  const indirectTimeData: CalendarItemData[] = filterItems(items.indirectTime).map(t => ({
    ...t,
    title: t.taskType.title,
    key: t.timeEntryId,
    status: t.latestApproval?.approvalStatus || "APPROVED",
    attendees: [],
    onClick: () => navigate(`/calendar/time/${t.timeEntryId}`, { keepQuery: true }),
  }));

  const relativeVerticalLength = (startTime: DateTime, endTime: DateTime) => {
    const proportion = endTime.diff(startTime, "minutes").minutes / totalMinutes;
    return percentString(proportion);
  };

  const getVerticalBoxProps = (data: { startTime: DateTime; endTime: DateTime }) => ({
    top: relativeVerticalLength(startTime, data.startTime),
    height: relativeVerticalLength(data.startTime, data.endTime),
  });

  const filteredBlocks = filterItems(items.blocks);
  const getBlockData = (b: ExtendedCalendarBlock) => ({
    key: b.calendarBlockId,
    boxProps: getVerticalBoxProps(b),
    onClick: () => navigate(`/calendar/block/${b.calendarBlockId}`, { keepQuery: true }),
    block: b,
  });

  const availabilityBlocks = filteredBlocks.filter(b => b.calendarBlockType === "Availability");
  const availabilityData = availabilityBlocks.map(getBlockData);

  const focusTimeData = filteredBlocks
    .filter(b => b.calendarBlockType === "FocusTime")
    .map(b => ({
      ...getBlockData(b),
      isOffset: availabilityBlocks.some(a => isOverlap(a, b)),
      text: b.title || undefined,
    }));

  const [offsetFocusTime, flushFocusTime] = partition(focusTimeData, "isOffset");

  const getOffsetCount = (item: CalendarItemData): 0 | 1 | 2 => {
    if (offsetFocusTime.some(t => isOverlap(t.block, item))) return 2;
    if (items.blocks.some(b => isOverlap(b, item))) return 1;
    return 0;
  };

  const positionedItemData: PositionedItemData[] = flatMap(
    groupOverlaps([...appointmentData, ...indirectTimeData]),
    dataGroup => {
      const widthProportion = 1 / dataGroup.items.length;
      const sortedItems = sortBy(dataGroup.items, "startTime");
      return sortedItems.map((data, i) => ({
        data,
        boxProps: {
          ...getVerticalBoxProps(data),
          left: percentString(widthProportion * i),
          width: percentString(widthProportion),
        },
        offsetCount: getOffsetCount(data),
      }));
    },
  );

  const itemsByOffset = groupBy(positionedItemData, "offsetCount");

  const renderItem = ({ data, boxProps }: PositionedItemData) => (
    <Box {...boxProps} position="absolute" paddingY="3px" paddingX="1px" key={data.key}>
      <CalendarItem data={data} />
    </Box>
  );

  return (
    <FullBox sx={{ paddingLeft: 4, paddingRight: "2px" }}>
      {availabilityData.map(({ boxProps, onClick, key }) => (
        <CalendarBlockBox blockType="availability" boxProps={boxProps} onSelect={onClick} key={key} />
      ))}
      {flushFocusTime.map(({ boxProps, onClick, text, key }) => (
        <CalendarBlockBox blockType="focus-time" boxProps={boxProps} onSelect={onClick} text={text} key={key} />
      ))}
      <FullBox position="relative" pl={4}>
        {offsetFocusTime.map(({ boxProps, onClick, text, key }) => (
          <CalendarBlockBox blockType="focus-time" boxProps={boxProps} onSelect={onClick} text={text} key={key} />
        ))}
        <FullBox position="relative">{itemsByOffset[2]?.map(renderItem)}</FullBox>
        {itemsByOffset[1]?.map(renderItem)}
      </FullBox>
      {itemsByOffset[0]?.map(renderItem)}
    </FullBox>
  );
};

export default observer(CalendarColumn);
