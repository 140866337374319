import { cloneElement, ReactElement, useContext } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReportIcon from "@mui/icons-material/Report";
import Schedule from "@mui/icons-material/Schedule";
import ScienceIcon from "@mui/icons-material/Science";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FRIENDLY_PROVIDER_TYPES, UserType } from "@parallel/vertex/enums/user.enums";
import { PaginateState, PaginatedResult } from "@parallel/vertex/types/shared.types";
import { ExtendedFacilitatorUser } from "@parallel/vertex/types/user/facilitator.types";
import { ExtendedProviderUser } from "@parallel/vertex/types/user/provider.types";
import { ExtendedSlpaUser } from "@parallel/vertex/types/user/slpa.types";
import { ExtendedStudentUser } from "@parallel/vertex/types/user/student.types";
import { ExtendedUser } from "@parallel/vertex/types/user/user.types";
import { UnconstrainedTooltip } from "@/components/shared/display/tooltip";
import DataTable, { DataTableAction, DataTableColumn } from "@/components/shared/layout/DataTable";
import { StoreContext } from "@/stores";
import { getShortServiceLineTitle } from "@/util/service.util";

type StudentUserRow = ExtendedStudentUser & {
  campusName?: string;
  districtName?: string;
  providerNames: string[];
  facilitatorNames: string[];
  activeServiceNames: string[];
  tags?: { icon: ReactElement; label: string; color: string }[];
};

export const toStudentRow = (user: ExtendedStudentUser): StudentUserRow => ({
  ...user,
  fullName: user.fullName,
  campusName: user.campus?.name,
  districtName: user.district?.name,
  providerNames: user.providers.map(p => p.fullName),
  facilitatorNames: user.facilitators.map(f => f.fullName),
  activeServiceNames: user.activeServices.map(s => s.title),
  tags: [
    ...(user.campus?.name === "Parallel Campus"
      ? [{ icon: <ScienceIcon />, label: "Test Student", color: "primary.main" }]
      : []),
    ...(user.providers.length === 0
      ? [{ icon: <ReportIcon />, label: "No Assigned Provider", color: "error.main" }]
      : []),
    ...(user.archivedAt ? [{ icon: <InventoryIcon />, label: "Archived Student", color: "surface.dark" }] : []),
  ],
});

type ProviderUserRow = ExtendedProviderUser & {
  friendlyProviderType: string;
};

export const toProviderRow = (user: ExtendedProviderUser): ProviderUserRow => ({
  ...user,
  friendlyProviderType: FRIENDLY_PROVIDER_TYPES[user.providerType],
});

export type UserState = {
  STUDENT?: PaginatedResult<StudentUserRow>;
  FACILITATOR?: PaginatedResult<ExtendedFacilitatorUser>;
  SLPA?: PaginatedResult<ExtendedSlpaUser>;
  PROVIDER?: PaginatedResult<ProviderUserRow>;
  ADMIN?: PaginatedResult<ExtendedUser>;
};

const renderFullName = (row: any) => {
  const linkedName = (
    <Link href={`/user/student/${row.userId}`}>
      <button onClick={e => e.stopPropagation()} style={{ all: "unset", cursor: "pointer" }}>
        {row.fullName}
      </button>
    </Link>
  );

  if (!(row.tags || []).length) return linkedName;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {linkedName}
      {row.tags.map((tag: { icon: ReactElement; label: string; color: string }, index: number) => (
        <UnconstrainedTooltip
          title={<Typography variant="body2">{tag.label}</Typography>}
          key={index}
          placement="right"
        >
          <Chip
            label={cloneElement(tag.icon, {
              sx: { width: "16px", height: "16px", color: tag.color },
            })}
            sx={{
              borderRadius: "100%",
              width: "24px",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 0.5,
              "& .MuiChip-label": { padding: "0 0 1px 0" },
            }}
          />
        </UnconstrainedTooltip>
      ))}
    </Stack>
  );
};

const renderChipList = (items: string[], { isServiceLine }: { isServiceLine?: boolean } = {}) => {
  if (!items.length) return <></>;
  const mainLabel = isServiceLine ? getShortServiceLineTitle({ title: items[0] }) : items[0];

  return (
    <Stack direction="row" alignItems="center" height="100%" gap={1}>
      <UnconstrainedTooltip title={<Typography variant="body2">{items[0]}</Typography>} placement="left">
        <Chip label={mainLabel} sx={{ borderRadius: 1 }} />
      </UnconstrainedTooltip>
      {items.length > 1 && (
        <UnconstrainedTooltip
          title={
            <Stack>
              {items.slice(1).map((item, index) => (
                <Typography variant="body2" key={index}>
                  {item}
                </Typography>
              ))}
            </Stack>
          }
          placement="right"
        >
          <Chip label={`+${items.length - 1}`} sx={{ cursor: "pointer", borderRadius: 1 }} />
        </UnconstrainedTooltip>
      )}
    </Stack>
  );
};

const STUDENT_COLUMN_PROPS: DataTableColumn<StudentUserRow>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  {
    key: "fullName",
    header: "Name",
    renderCell: (params: any) => renderFullName(params.row),
    width: 200,
  },
  { key: "birthDate", header: "Date of Birth", type: "date", flexWidth: 0.6 },
  { key: "districtName", header: "District", hidden: true, sortable: false },
  { key: "campusName", header: "Campus", sortable: false },
  {
    key: "activeServiceNames",
    header: "Parallel Services",
    sortable: false,
    renderCell: (params: any) => renderChipList(params.value, { isServiceLine: true }),
    flexWidth: 1.4,
  },
  {
    key: "providerNames",
    header: "Providers",
    sortable: false,
    renderCell: (params: any) => renderChipList(params.value),
    flexWidth: 0.8,
  },
  {
    key: "facilitatorNames",
    header: "Facilitators",
    sortable: false,
    renderCell: (params: any) => renderChipList(params.value),
    flexWidth: 0.8,
  },
];

const FACILITATOR_COLUMN_PROPS: DataTableColumn<ExtendedFacilitatorUser>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
  { key: "districtName", header: "District", sortable: false },
];

const SLPA_COLUMN_PROPS: DataTableColumn<ExtendedSlpaUser>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
  { key: "districtName", header: "District", sortable: false },
  {
    key: "supervisorNames",
    header: "Supervisors",
    sortable: false,
    renderCell: (params: any) => renderChipList(params.value),
  },
];

const PROVIDER_COLUMN_PROPS: DataTableColumn<ProviderUserRow>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
  { key: "friendlyProviderType", header: "Type" },
];

const ADMIN_COLUMN_PROPS: DataTableColumn<ExtendedUser>[] = [
  { key: "userId", header: "ID", hidden: true, isID: true },
  { key: "fullName", header: "Name", width: 200 },
  { key: "email", header: "Email" },
];

export const INITIAL_PAGE_SIZE = 25;

const StudentDataTable = ({
  data,
  pagination,
  actions,
  onSelect,
  onSort,
  footer,
}: {
  data: StudentUserRow[] | undefined;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: StudentUserRow[]) => void;
  onSort: (s: any) => void;
  footer?: () => ReactElement;
}) => {
  return (
    <DataTable
      columns={STUDENT_COLUMN_PROPS}
      data={data}
      pagination={pagination}
      onSelect={onSelect}
      onSortModelChange={onSort}
      actions={actions}
      footer={footer}
    />
  );
};

const FacilitatorDataTable = ({
  data,
  pagination,
  actions,
  onSelect,
  onSort,
  onFacilitatorEdit,
  onFacilitatorEmail,
  onFacilitatorDelete,
}: {
  data: ExtendedFacilitatorUser[] | undefined;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: ExtendedFacilitatorUser[]) => void;
  onSort: (s: any) => void;
  onFacilitatorEdit: (f: ExtendedFacilitatorUser) => void;
  onFacilitatorEmail: (f: ExtendedFacilitatorUser) => void;
  onFacilitatorDelete: { fn: (f: ExtendedFacilitatorUser) => void; isEnabled: (f: ExtendedFacilitatorUser) => boolean };
}) => {
  const withFacilitator = <A,>(id: string, fn: (f?: ExtendedFacilitatorUser) => A) => {
    const facilitator = data?.find(f => f.userId === id);
    return fn(facilitator);
  };

  const buildAction = (fn: (f: ExtendedFacilitatorUser) => string | void, icon: ReactElement, label?: string) => ({
    onClick: (facilitatorId: string) => withFacilitator(facilitatorId, f => (f ? fn(f) : undefined)),
    icon,
    label,
  });

  const facilitatorActions: DataTableAction[] = [
    buildAction(onFacilitatorEdit, <EditIcon />, "Edit Facilitator"),
    buildAction(onFacilitatorEmail, <EmailIcon />, "Send Welcome Email"),
    ...(actions || []),
    {
      ...buildAction(onFacilitatorDelete.fn, <DeleteForeverIcon />, "Delete Facilitator"),
      isEnabled: (id: string) => withFacilitator(id, f => (f ? onFacilitatorDelete.isEnabled(f) : false)),
    },
  ];

  return (
    <DataTable
      columns={FACILITATOR_COLUMN_PROPS}
      data={data}
      pagination={pagination}
      onSelect={onSelect}
      onSortModelChange={onSort}
      actions={facilitatorActions}
    />
  );
};

const SlpaDataTable = ({
  data,
  pagination,
  actions,
  onSelect,
  onSort,
  onSlpaEdit,
  onSlpaEmail,
  onSlpaDelete,
}: {
  data: ExtendedSlpaUser[] | undefined;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: ExtendedSlpaUser[]) => void;
  onSort: (s: any) => void;
  onSlpaEdit: (f: ExtendedSlpaUser) => void;
  onSlpaEmail: (f: ExtendedSlpaUser) => void;
  onSlpaDelete: { fn: (f: ExtendedSlpaUser) => void; isEnabled: (f: ExtendedSlpaUser) => boolean };
}) => {
  const withSlpa = <A,>(id: string, fn: (f?: ExtendedSlpaUser) => A) => {
    const slpa = data?.find(f => f.userId === id);
    return fn(slpa);
  };

  const buildAction = (fn: (f: ExtendedSlpaUser) => string | void, icon: ReactElement, label?: string) => ({
    onClick: (slpaId: string) => withSlpa(slpaId, f => (f ? fn(f) : undefined)),
    icon,
    label,
  });

  const slpaActions: DataTableAction[] = [
    buildAction(onSlpaEdit, <EditIcon />, "Edit Slpa"),
    buildAction(onSlpaEmail, <EmailIcon />, "Send Welcome Email"),
    ...(actions || []),
    {
      ...buildAction(onSlpaDelete.fn, <DeleteForeverIcon />, "Delete Slpa"),
      isEnabled: (id: string) => withSlpa(id, f => (f ? onSlpaDelete.isEnabled(f) : false)),
    },
  ];

  return (
    <DataTable
      columns={SLPA_COLUMN_PROPS}
      data={data}
      pagination={pagination}
      onSelect={onSelect}
      onSortModelChange={onSort}
      actions={slpaActions}
    />
  );
};

const ProviderDataTable = ({
  data,
  pagination,
  actions,
  onSelect,
  onSort,
  onProviderEdit,
}: {
  data: ProviderUserRow[] | undefined;
  pagination: any;
  actions?: DataTableAction[];
  onSelect: (s: ProviderUserRow[]) => void;
  onSort: (s: any) => void;
  onProviderEdit: (p: ProviderUserRow) => void;
}) => {
  const withProvider = <A,>(id: string, fn: (f: ProviderUserRow) => A) => {
    const provider = data?.find(f => f.userId === id);
    return provider && fn(provider);
  };

  const providerActions: DataTableAction[] = [
    {
      onClick: (providerId: string) => withProvider(providerId, onProviderEdit),
      icon: <EditIcon />,
      label: "Edit Provider",
    },
    ...(actions || []),
  ];

  return (
    <DataTable
      columns={PROVIDER_COLUMN_PROPS}
      data={data}
      pagination={pagination}
      onSelect={onSelect}
      onSortModelChange={onSort}
      actions={providerActions}
    />
  );
};

const UserDataTable = ({
  userType,
  data,
  onPageChange,
  onSortModelChange,
  onStudentSelect,
  onFacilitatorEdit,
  onFacilitatorEmail,
  onFacilitatorDelete,
  onSlpaEdit,
  onSlpaEmail,
  onSlpaDelete,
  onProviderEdit,
  footer,
}: {
  userType: UserType;
  data: UserState;
  onPageChange: (p: PaginateState) => void;
  onSortModelChange: (s: any) => void;
  onStudentSelect: (s: StudentUserRow[]) => void;
  onFacilitatorEdit: (f: ExtendedFacilitatorUser) => void;
  onFacilitatorEmail: (f: ExtendedFacilitatorUser) => void;
  onFacilitatorDelete: { fn: (f: ExtendedFacilitatorUser) => void; isEnabled: (f: ExtendedFacilitatorUser) => boolean };
  onSlpaEdit: (s: ExtendedSlpaUser) => void;
  onSlpaEmail: (s: ExtendedSlpaUser) => void;
  onSlpaDelete: { fn: (s: ExtendedSlpaUser) => void; isEnabled: (s: ExtendedSlpaUser) => boolean };
  onProviderEdit: (p: ExtendedProviderUser) => void;
  footer?: () => ReactElement;
}) => {
  const {
    authStore: { currentUser },
  } = useContext(StoreContext);

  const getPaginationProps = (page?: PaginatedResult<unknown>) => ({
    initialPageSize: INITIAL_PAGE_SIZE,
    onPageChange,
    totalCount: page?.totalCount || 0,
  });

  const calendarAction =
    currentUser?.userType === "ADMIN"
      ? {
          href: (id: string) => `/calendar/${userType.toLowerCase()}/${id}`,
          icon: <Schedule />,
          label: "View Calendar",
        }
      : undefined;

  switch (userType) {
    case "STUDENT":
      return (
        <StudentDataTable
          data={data.STUDENT?.records}
          pagination={getPaginationProps(data.STUDENT)}
          onSelect={onStudentSelect}
          onSort={onSortModelChange}
          actions={calendarAction ? [calendarAction] : undefined}
          footer={footer}
        />
      );
    case "FACILITATOR":
      return (
        <FacilitatorDataTable
          data={data.FACILITATOR?.records}
          pagination={getPaginationProps(data.FACILITATOR)}
          onSelect={() => {}}
          onSort={onSortModelChange}
          onFacilitatorEdit={onFacilitatorEdit}
          onFacilitatorEmail={onFacilitatorEmail}
          onFacilitatorDelete={onFacilitatorDelete}
          actions={calendarAction ? [calendarAction] : undefined}
        />
      );
    case "SLPA":
      return (
        <SlpaDataTable
          data={data.SLPA?.records}
          pagination={getPaginationProps(data.SLPA)}
          onSelect={() => {}}
          onSort={onSortModelChange}
          onSlpaEdit={onSlpaEdit}
          onSlpaEmail={onSlpaEmail}
          onSlpaDelete={onSlpaDelete}
          actions={calendarAction ? [calendarAction] : undefined}
        />
      );
    case "PROVIDER":
      return (
        <ProviderDataTable
          data={data.PROVIDER?.records}
          pagination={getPaginationProps(data.PROVIDER)}
          onSelect={() => {}}
          onSort={onSortModelChange}
          onProviderEdit={onProviderEdit}
          actions={calendarAction ? [calendarAction] : undefined}
        />
      );
    case "ADMIN":
      return (
        <DataTable
          columns={ADMIN_COLUMN_PROPS}
          data={data.ADMIN?.records}
          onSelect={() => {}}
          onSortModelChange={onSortModelChange}
          pagination={getPaginationProps(data.ADMIN)}
        />
      );
  }
};

export default UserDataTable;
