import { useContext, useState } from "react";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMountEffect } from "@react-hookz/web";
import { isUndefined } from "lodash";
import { DateTime } from "luxon";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { GreyBox } from "@parallel/polygon/components/shared/layout/container";
import { CancelStatus } from "@parallel/vertex/enums/calendar.enums";
import { AppointmentCancelReason, ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import { timeRangeString } from "@parallel/vertex/util/datetime.util";
import { AppointmentAbsenceMakeUpInput } from "@/components/calendar/appointment/AppointmentAbsenceMakeUpPrompt";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("CancelAppointmentForm", getLoggerContext);

const CancelAppointmentForm = ({
  appointment,
  cancelStatus,
  onBack,
  onSuccess,
  showMakeUpPrompt = true,
}: {
  appointment: ExtendedAppointment;
  cancelStatus: CancelStatus;
  onBack: () => void;
  onSuccess?: () => void;
  showMakeUpPrompt: boolean;
}) => {
  const {
    apiStore: { calendarApi },
    authStore: { timezone },
    calendarStore,
  } = useContext(StoreContext);

  const [allReasons, setAllReasons] = useState<AppointmentCancelReason[]>([]);
  const [selectedReason, setSelectedReason] = useState<AppointmentCancelReason>();
  const [willMakeUpAbsence, setWillMakeUpAbsence] = useState(showMakeUpPrompt ? true : undefined);

  useMountEffect(() =>
    calendarApi
      .getAllAppointmentCancelReasons()
      .then(setAllReasons)
      .catch(logger.handleFailure("getAllAppointmentCancelReasons")),
  );

  const performCancel = async () => {
    if (!selectedReason) return;
    await calendarStore
      .updateAppointment(appointment.appointmentId, {
        appointmentStatus: cancelStatus,
        cancelReasonId: selectedReason.appointmentCancelReasonId,
        willMakeUpAbsence,
      })
      .then(() => (onSuccess ? onSuccess() : onBack))
      .catch(logger.handleFailureAndThrow("updateAppointment"));
  };

  return (
    <Stack gap={4} width={500}>
      <Typography variant="h2">Cancel Session</Typography>

      <GreyBox>
        <Typography variant="body1">{appointment.title}</Typography>
        <Typography variant="body2">{appointment.startTime.toLocaleString(DateTime.DATE_HUGE)}</Typography>
        <Typography variant="body2">{timeRangeString(appointment, timezone, { withZone: true })}</Typography>
      </GreyBox>

      <Typography>If you are sure you want to cancel this session, select a reason and submit below.</Typography>

      <FormControl>
        <InputLabel id="cancel-reason-select">Cancellation Reason</InputLabel>
        <Select
          labelId="cancel-reason-select"
          label="Cancellation Reason"
          value={selectedReason?.appointmentCancelReasonId}
          onChange={e => setSelectedReason(allReasons?.find(r => r.appointmentCancelReasonId === e.target.value))}
        >
          {allReasons.map(({ appointmentCancelReasonId, title }) => (
            <MenuItem key={appointmentCancelReasonId} value={appointmentCancelReasonId}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!isUndefined(willMakeUpAbsence) && (
        <AppointmentAbsenceMakeUpInput
          willMakeUpAbsence={willMakeUpAbsence}
          setWillMakeUpAbsence={setWillMakeUpAbsence}
        />
      )}

      <Stack direction="row" justifyContent="space-between">
        <Button onClick={onBack}>Back</Button>
        <ProcessButton
          process={() => performCancel().then(onBack)}
          variant="contained"
          color="error"
          startIcon={<EventBusyIcon />}
          disabled={!selectedReason}
        >
          Cancel
        </ProcessButton>
      </Stack>
    </Stack>
  );
};

export default CancelAppointmentForm;
