import { isEqual, isNull, max, min, pick } from "lodash";
import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { ClientLogger } from "@parallel/polygon/util/logging.util";
import { RecurrenceEditMode } from "@parallel/vertex/enums/calendar.enums";
import { UserType } from "@parallel/vertex/enums/user.enums";
import {
  BookAppointmentBody,
  ExtendedAppointment,
  UpdateAppointmentBody,
  UpdateAppointmentUserBody,
} from "@parallel/vertex/types/calendar/appointment.types";
import {
  CreateCalendarBlockBody,
  ExtendedCalendarBlock,
  UpdateCalendarBlockBody,
} from "@parallel/vertex/types/calendar/calendar.block.types";
import { CalendarItems } from "@parallel/vertex/types/calendar/calendar.types";
import {
  CreateIndirectTimeBody,
  ExtendedTimeEntry,
  UpdateIndirectTimeBody,
} from "@parallel/vertex/types/calendar/time.types";
import { DateTimeRange } from "@parallel/vertex/types/shared.types";
import { isConsultAppointment, isProgressAppointment } from "@parallel/vertex/util/appointment.util";
import { filterExists } from "@parallel/vertex/util/collection.util";
import { isInRange, isWithin } from "@parallel/vertex/util/datetime.util";
import { roundToDecimal } from "@parallel/vertex/util/number.util";
import { CalendarAPI } from "@/api/calendar.api";
import { UserAPI } from "@/api/user.api";
import { UserOption } from "@/components/user/input/UserInput";
import config from "@/config";
import { AuthStore } from "@/stores/auth.store";
import { DEFAULT_MAX_CALENDAR_HOUR, DEFAULT_MIN_CALENDAR_HOUR } from "@/util/calendar.util";
import { FetchStatus } from "@/util/shared.util";

export const CALENDAR_PERIOD_TYPES = ["day", "week"] as const;
export type CalendarPeriodType = (typeof CALENDAR_PERIOD_TYPES)[number];

export const ITEM_KEYS = ["appointments", "indirectTime", "blocks"] as const;
export type CalendarItemKey = (typeof ITEM_KEYS)[number];

export const TYPE_FILTERS = [
  "appointments",
  "indirectTime",
  "availabilities",
  "focusTime",
  "consultAppointments",
  "progressMeetings",
] as const;
export type CalendarTypeFilter = (typeof TYPE_FILTERS)[number];

type CalendarPeriod = {
  type: CalendarPeriodType;
  startTime: DateTime;
  endTime: DateTime;
};

export type CalendarItemType = "appointment" | "block" | "indirect-time";

type CalendarItemSelection = { itemId: string; itemType: CalendarItemType };

export class CalendarStore {
  public currentPayPeriod?: DateTimeRange;

  public period: CalendarPeriod;
  public maybeCalendarItems?: CalendarItems;
  public fetchStatus: FetchStatus = "waiting";

  public partialTypeFilters: Partial<Record<CalendarTypeFilter, boolean>> = {
    appointments: true,
    availabilities: true,
    focusTime: true,
    indirectTime: true,
    consultAppointments: undefined,
    progressMeetings: undefined,
  };
  public isShowingCancelled = true;
  public isShowingWeekends = false;

  public currentSelection?: CalendarItemSelection;

  public selectedUser: UserOption | null = null;

  public zoomFactor = 1.6;

  constructor(
    private authStore: AuthStore,
    private calendarApi: CalendarAPI,
    private userApi: UserAPI,
    private logger: ClientLogger,
  ) {
    this.period = this.getPeriod();
    makeAutoObservable(this);
    makePersistable(this, {
      name: "CalendarStore",
      properties: ["period", "partialTypeFilters", "isShowingCancelled", "isShowingWeekends"],
      storage: window.localStorage,
    });
  }

  get typeFilters(): Record<CalendarTypeFilter, boolean> {
    const isFacilitator = this.authStore.currentUser?.userType === "FACILITATOR";
    return {
      consultAppointments: !isFacilitator,
      progressMeetings: !isFacilitator,
      appointments: true,
      availabilities: true,
      focusTime: true,
      indirectTime: true,
      ...this.partialTypeFilters,
    };
  }

  get calendarItems(): CalendarItems {
    if (!this.maybeCalendarItems) return { appointments: [], blocks: [], indirectTime: [] };

    const blockTypes = filterExists([
      this.typeFilters.availabilities && "Availability",
      this.typeFilters.focusTime && "FocusTime",
    ]);

    return {
      appointments: this.filteredAppointments,
      blocks: this.maybeCalendarItems.blocks.filter(b => blockTypes.includes(b.calendarBlockType)),
      indirectTime: this.typeFilters.indirectTime ? this.maybeCalendarItems.indirectTime : [],
    };
  }

  private get filteredAppointments() {
    if (!this.maybeCalendarItems) return [];
    return this.maybeCalendarItems.appointments.filter(a => {
      const filterCancelled =
        !this.isShowingCancelled && ["CANCELLED", "LATE_CANCELLED"].includes(a.appointmentStatus || "");

      const filterConsult = !this.typeFilters && isConsultAppointment(a);

      const filterProgress = !this.typeFilters.progressMeetings && isProgressAppointment(a, config);

      return !filterCancelled && !filterConsult && !filterProgress;
    });
  }

  get flattenedItems(): { startTime: DateTime; endTime: DateTime }[] {
    const items = this.calendarItems;
    return [...items.appointments, ...items.blocks, ...items.indirectTime];
  }

  get hourRange(): { min: number; max: number } {
    const items = this.calendarItems;
    const flattenedItems = [...items.appointments, ...items.blocks, ...items.indirectTime];
    return {
      min:
        min([
          ...flattenedItems.map(i => i.startTime.setZone(this.authStore.timezone).hour),
          DEFAULT_MIN_CALENDAR_HOUR,
        ]) ?? DEFAULT_MIN_CALENDAR_HOUR,
      max:
        max([
          ...flattenedItems.map(i => i.endTime.setZone(this.authStore.timezone).hour + 1),
          DEFAULT_MAX_CALENDAR_HOUR,
        ]) ?? DEFAULT_MAX_CALENDAR_HOUR,
    };
  }

  get selectedAppointment(): ExtendedAppointment | undefined {
    if (this.currentSelection?.itemType === "appointment")
      return this.calendarItems.appointments.find(a => a.appointmentId === this.currentSelection?.itemId);
  }

  get selectedBlock(): ExtendedCalendarBlock | undefined {
    if (this.currentSelection?.itemType === "block")
      return this.calendarItems.blocks.find(a => a.calendarBlockId === this.currentSelection?.itemId);
  }

  get selectedIndirectTime(): ExtendedTimeEntry | undefined {
    if (this.currentSelection?.itemType === "indirect-time")
      return this.calendarItems.indirectTime.find(a => a.timeEntryId === this.currentSelection?.itemId);
  }

  get defaultTimeRange() {
    const now = DateTime.utc();
    const startTimeBasis = this.period.type === "week" && isInRange(this.period, now) ? now : this.period.startTime;
    const startTime = startTimeBasis.setZone(this.authStore.timezone).set({ hour: 12 }).startOf("hour");
    return {
      startTime,
      endTime: startTime.plus({ hour: 1 }),
    };
  }

  get baseUrl() {
    return this.selectedUser
      ? `/calendar/${this.selectedUser.userType.toLowerCase()}/${this.selectedUser.userId}`
      : "/calendar";
  }

  public loadItem = async (itemSelection: CalendarItemSelection, periodType?: CalendarPeriodType) => {
    this.fetchStatus = this.maybeCalendarItems ? "loading" : "initiating";

    const item = await this.resolveTruncatedItem(itemSelection).catch(e => {
      this.logger.error("error resolving calendar item for initialization", {
        causedBy: e,
        context: { itemSelection },
      });
      return null;
    });
    if (!item) {
      this.fetchStatus = "not-found";
      return;
    }

    // if item found in cache, assume everything is configured correctly and simply set the selected calendar item
    if (item.source === "cache") {
      runInAction(() => {
        this.currentSelection = itemSelection;
        this.fetchStatus = "finished";
      });
      return item;
    }

    // resolve optional selected user update from fetched calendar item - fail if not found
    const selectedUserUpdate = await this.resolveSelectedUserUpdate(item.userIds).catch(e => {
      this.logger.error("error resolving item user", { causedBy: e, context: { item } });
      return null;
    });
    if (isNull(selectedUserUpdate)) {
      this.fetchStatus = "not-found";
      return item;
    }

    // resolve optional period update from fetched calendar item
    const periodUpdate = this.resolvePeriodUpdate(item.startTime, periodType);

    // set state + return if selected user and period both don't require update + data already been fetched
    if (!selectedUserUpdate && !periodUpdate && this.maybeCalendarItems) {
      runInAction(() => {
        this.currentSelection = itemSelection;
        this.fetchStatus = "finished";
      });
      return item;
    }

    // fetch data + set state
    const newItems = await this.fetchData(
      periodUpdate || this.period,
      selectedUserUpdate || this.selectedUser || undefined,
    ).catch(this.logger.handleFailureAndReturn("loadItemDataFetch", undefined));

    runInAction(() => {
      this.maybeCalendarItems = newItems;
      this.currentSelection = itemSelection;
      if (selectedUserUpdate) this.selectedUser = selectedUserUpdate;
      if (periodUpdate) this.period = periodUpdate;
      this.fetchStatus = "finished";
    });

    return item;
  };

  private resolveTruncatedItem = async ({
    itemId,
    itemType,
  }: CalendarItemSelection): Promise<{ userIds: string[]; startTime: DateTime; source: "cache" | "fetch" }> => {
    switch (itemType) {
      case "appointment":
        const existingAppointment = this.calendarItems.appointments.find(a => a.appointmentId === itemId);
        return existingAppointment
          ? this.truncateAppointment(existingAppointment, "cache")
          : this.calendarApi.getAppointment(itemId).then(a => this.truncateAppointment(a, "fetch"));
      case "block":
        const existingAvailability = this.calendarItems.blocks.find(a => a.calendarBlockId === itemId);
        return existingAvailability
          ? this.truncateBlock(existingAvailability, "cache")
          : this.calendarApi.getCalendarBlock(itemId).then(a => this.truncateBlock(a, "fetch"));
      case "indirect-time":
        const existingTime = this.calendarItems.indirectTime.find(t => t.timeEntryId === itemId);
        return existingTime
          ? this.truncateIndirectTime(existingTime, "cache")
          : this.calendarApi.getIndirectTime(itemId).then(t => this.truncateIndirectTime(t, "fetch"));
    }
  };

  private truncateAppointment = (a: ExtendedAppointment, source: "cache" | "fetch") => ({
    startTime: a.startTime,
    userIds: [a.provider.userId, ...a.students.map(s => s.userId)],
    source,
  });

  private truncateBlock = (a: ExtendedCalendarBlock, source: "cache" | "fetch") => ({
    startTime: a.startTime,
    userIds: [a.userId],
    source,
  });

  private truncateIndirectTime = (t: ExtendedTimeEntry, source: "cache" | "fetch") => ({
    startTime: t.startTime,
    userIds: [t.userId],
    source,
  });

  public loadSelectedUser = async (
    { userId, userType }: { userId: string; userType: UserType },
    periodDate?: DateTime,
    periodType?: CalendarPeriodType,
  ) => {
    this.fetchStatus = this.maybeCalendarItems ? "loading" : "initiating";

    const selectedUserUpdate = await this.resolveSelectedUserUpdate([userId]).catch(e => {
      this.logger.error("error resolving param user", { causedBy: e, context: { userId, userType } });
      return null;
    });
    if (isNull(selectedUserUpdate) || (selectedUserUpdate && selectedUserUpdate.userType !== userType)) {
      this.fetchStatus = "not-found";
      return;
    }

    // resolve optional period update from fetched calendar item
    const periodUpdate = this.resolvePeriodUpdate(periodDate, periodType);

    // return if selected user and period both don't require update + data already fetched
    if (!selectedUserUpdate && !periodUpdate && this.maybeCalendarItems) {
      runInAction(() => (this.fetchStatus = "finished"));
      return;
    }

    // fetch data + set state
    const newItems = await this.fetchData(
      periodUpdate || this.period,
      selectedUserUpdate || this.selectedUser || undefined,
    ).catch(this.logger.handleFailureAndReturn("loadSelectedUserDataFetch", undefined));

    runInAction(() => {
      this.maybeCalendarItems = newItems;
      if (selectedUserUpdate) this.selectedUser = selectedUserUpdate;
      if (periodUpdate) this.period = periodUpdate;
      this.fetchStatus = "finished";
    });
  };

  public loadCurrentUser = async (periodDate?: DateTime, periodType?: CalendarPeriodType) => {
    if (this.authStore.currentUser?.userType === "ADMIN") {
      this.fetchStatus = "waiting";
      this.selectedUser = null;
      this.period = this.getPeriod(periodType, periodDate);
      return;
    } else {
      this.fetchStatus = this.maybeCalendarItems ? "loading" : "initiating";
      this.selectedUser = this.authStore.currentUser || null;
    }

    const periodUpdate = this.resolvePeriodUpdate(periodDate, periodType);
    if (!periodUpdate && this.maybeCalendarItems) {
      this.fetchStatus = "finished";
      return;
    }

    // fetch data + set state
    const newItems = await this.fetchData(periodUpdate || this.period, this.selectedUser || undefined).catch(
      this.logger.handleFailureAndReturn("loadCurrentUserDataFetch", undefined),
    );

    runInAction(() => {
      this.maybeCalendarItems = newItems;
      if (periodUpdate) this.period = periodUpdate;
      this.fetchStatus = "finished";
    });
  };

  private resolveSelectedUserUpdate = async (availableUserIds: string[]) => {
    // selected calendar user only needs to be updated for admin users
    if (this.authStore.currentUser?.userType !== "ADMIN") return;

    // nothing to do if available user is already selected
    if (this.selectedUser && availableUserIds.includes(this.selectedUser.userId)) return;

    const user = await this.userApi.getUser(availableUserIds[0]);
    return pick(user, "userId", "userType", "fullName");
  };

  // generate new period including `periodDate` - if same as current state, return undefined
  private resolvePeriodUpdate = (periodDate?: DateTime, periodType?: CalendarPeriodType) => {
    const newPeriod = this.getPeriod(periodType, periodDate);
    return isEqual(newPeriod, this.period) ? undefined : newPeriod;
  };

  private getPeriod = (
    type: CalendarPeriodType = this.period?.type || "day",
    includesDate: DateTime = DateTime.utc(),
  ): CalendarPeriod => {
    const zonedDate = includesDate.setZone(this.authStore.timezone).plus({ days: type === "week" ? 1 : 0 });
    const period = {
      type,
      startTime: zonedDate.startOf(type),
      endTime: zonedDate.endOf(type),
    };
    if (type === "week" && this.isShowingWeekends) {
      period.startTime = period.startTime.minus({ day: 1 });
      period.endTime = period.endTime.plus({ day: 1 });
    }
    return period;
  };

  private fetchData = async (period: DateTimeRange, user?: { userId: string; userType: UserType }) => {
    return this.calendarApi.searchCalendarItems({
      ...period,
      facilitatorId: user?.userType === "FACILITATOR" ? user.userId : undefined,
      slpaId: user?.userType === "SLPA" ? user.userId : undefined,
      providerId: user?.userType === "PROVIDER" ? user.userId : undefined,
      studentId: user?.userType === "STUDENT" ? user.userId : undefined,
    });
  };

  public setPayPeriod = async () => {
    const payPeriod = await this.calendarApi.getCurrentPayPeriod();
    runInAction(() => (this.currentPayPeriod = payPeriod));
  };

  public canWriteCalendar = (startTime?: DateTime) => {
    const userType = this.authStore.currentUser?.userType;
    if (!userType || ["STUDENT", "SLPA", "FACILITATOR"].includes(userType)) return false;
    if (!startTime || !this.currentPayPeriod) return true;
    return startTime > this.currentPayPeriod.startTime;
  };

  public toggleFilter = (key: CalendarTypeFilter) => {
    this.partialTypeFilters = {
      ...this.typeFilters,
      [key]: !this.typeFilters[key],
    };
  };

  public setCalendarTypeFilters = (filters: Partial<Record<CalendarTypeFilter, boolean>>) => {
    this.partialTypeFilters = {
      ...this.typeFilters,
      ...filters,
    };
  };

  public toggleCancelFilter = () => {
    this.isShowingCancelled = !this.isShowingCancelled;
  };

  public zoom = (delta: number) => {
    this.zoomFactor = roundToDecimal(this.zoomFactor + delta, 1);
  };

  public bookAppointment = async (body: BookAppointmentBody) => {
    const appointment = await this.calendarApi.bookAppointment(body);
    this.addItem(appointment, "appointments");
  };

  public updateAppointment = async (appointmentId: string, body: UpdateAppointmentBody) => {
    const appointment = await this.calendarApi.updateAppointment(appointmentId, body);
    this.updateItem(appointment, "appointments", "appointmentId");
  };

  public deleteAppointment = async (appointmentId: string, mode?: RecurrenceEditMode) => {
    await this.calendarApi.deleteAppointment(appointmentId, mode);
    this.removeItem(appointmentId, "appointments", "appointmentId");
  };

  public createCalendarBlock = async (body: CreateCalendarBlockBody) => {
    const availability = await this.calendarApi.createCalendarBlock(body);
    this.addItem(availability, "blocks");
  };

  public updateCalendarBlock = async (availabilityId: string, body: UpdateCalendarBlockBody) => {
    const availabilty = await this.calendarApi.updateCalendarBlock(availabilityId, body);
    this.updateItem(availabilty, "blocks", "calendarBlockId");
  };

  public deleteCalendarBlock = async (availabilityId: string, mode?: RecurrenceEditMode) => {
    await this.calendarApi.deleteCalendarBlock(availabilityId, mode);
    this.removeItem(availabilityId, "blocks", "calendarBlockId");
  };

  public createIndirectTime = async (body: CreateIndirectTimeBody) => {
    const time = await this.calendarApi.createIndirectTime(body);
    this.addItem(time, "indirectTime");
  };

  public updateIndirectTime = async (timeId: string, body: UpdateIndirectTimeBody) => {
    const time = await this.calendarApi.updateIndirectTime(timeId, body);
    this.updateItem(time, "indirectTime", "timeEntryId");
  };

  public deleteIndirectTime = async (timeId: string, mode?: RecurrenceEditMode) => {
    await this.calendarApi.deleteIndirectTime(timeId, mode);
    this.removeItem(timeId, "indirectTime", "timeEntryId");
  };

  public updateAppointmentStudent = async (
    appointmentId: string,
    userId: string,
    update: UpdateAppointmentUserBody,
  ) => {
    const currentAppointment = this.calendarItems.appointments.find(a => a.appointmentId === appointmentId);
    const currentUser = currentAppointment?.students.find(s => s.userId === userId);
    if (!currentAppointment || !currentUser) return;

    const localUpdated = {
      ...currentAppointment,
      students: currentAppointment.students.map(s => (s.userId === userId ? { ...s, ...update } : s)),
    };
    this.updateItem(localUpdated, "appointments", "appointmentId");

    await this.calendarApi.updateAppointmentUser(appointmentId, userId, update);
    // TODO handle inconsistencies
  };

  private addItem = <A extends { startTime: DateTime; endTime: DateTime }>(
    newItem: A,
    itemKey: keyof CalendarItems,
  ) => {
    if (!isWithin(this.period, newItem)) return;
    this.setItems([...this.calendarItems[itemKey], newItem], itemKey);
  };

  private updateItem = <A extends { startTime: DateTime; endTime: DateTime }>(
    updatedItem: A,
    itemKey: keyof CalendarItems,
    idKey: keyof A,
  ) => {
    if (!isWithin(this.period, updatedItem)) this.removeItem(updatedItem[idKey] as string, itemKey, idKey);
    const currentList: any[] = this.calendarItems[itemKey];
    const updatedList = currentList.map(item => (item[idKey] !== updatedItem[idKey] ? item : updatedItem));
    this.setItems(updatedList, itemKey);
  };

  private removeItem = <A>(deletedId: string, itemKey: keyof CalendarItems, idKey: keyof A) => {
    const currentList: any[] = this.calendarItems[itemKey];
    const updatedList = currentList.flatMap(item => (item[idKey] !== deletedId ? [item] : []));
    this.setItems(updatedList, itemKey);
  };

  private setItems = <A>(list: A[], itemKey: keyof CalendarItems) => {
    runInAction(
      () =>
        (this.maybeCalendarItems = {
          ...this.calendarItems,
          [itemKey]: list,
        }),
    );
  };
}
