import AutoCompleteInput from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { AVAILABLE_LANGUAGES, LANGUAGE_NAMES, LanguageType } from "@parallel/vertex/enums/user.enums";

const LanguageInput = <P extends { languages?: LanguageType[] }>({
  params,
  setParams,
  disabled,
}: {
  params: P;
  setParams: (p: P) => void;
  disabled?: boolean;
}) => (
  <AutoCompleteInput
    label="Languages"
    options={AVAILABLE_LANGUAGES.map(key => ({ key, label: LANGUAGE_NAMES[key] }))}
    selected={params.languages?.map(key => ({ key, label: LANGUAGE_NAMES[key] })) || []}
    onSelect={selected => setParams({ ...params, languages: selected.map(s => s.key) })}
    disabled={disabled}
  />
);

export default LanguageInput;
