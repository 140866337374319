import type { User } from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { userTypeEnum } from "../../enums/user.enums";
import { Override, paginateQueryFields, sortQueryFields } from "../shared.types";

export type { UserArchiveReason } from "@prisma/client";

export type ExtendedUser = Override<
  Omit<User, "passwordHash" | "createdAt">,
  {
    fullName: string;
    createdAt: DateTime;
    archivedAt?: DateTime;
  }
>;

export const searchUserQuerySchema = z.object({
  keyword: z.string().trim().optional(),
  userTypes: userTypeEnum.array().optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});

export type SearchUserQuery = z.infer<typeof searchUserQuerySchema> & {
  updatedAfter?: DateTime;
  email?: string;
};

export const updateUserBodySchema = z.object({
  timezone: z.string().trim().optional(),
});

export type UpdateUserBody = z.infer<typeof updateUserBodySchema>;

export const createAdminBodySchema = z.object({
  firstName: z.string().trim(),
  lastName: z.string().trim(),
  email: z.string().email().trim().toLowerCase(),
});

export type CreateAdminBody = z.infer<typeof createAdminBodySchema>;
