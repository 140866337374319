import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { isEmpty, isUndefined } from "lodash";
import ListMenu from "@parallel/polygon/components/shared/layout/ListMenu";
import { FullGrid } from "@parallel/polygon/components/shared/layout/container";
import { CreateObjectiveBody, ObjectiveType } from "@parallel/vertex/types/progress.types";
import { removeAtIndex, updateAtIndex } from "@parallel/vertex/util/collection.util";
import ObjectiveTargetInput from "@/components/progress/form/ObjectiveTargetInput";
import SelectInput from "@/components/shared/input/SelectInput";
import { SingleGoalParams } from "@/util/progress.form.util";

const SingleGoalInput = ({
  goal,
  goalIndex,
  updateGoal,
  allObjectiveTypes,
  isEditing,
  onRemove,
}: {
  goal: SingleGoalParams;
  goalIndex: number;
  updateGoal: (p: Partial<SingleGoalParams>) => void;
  allObjectiveTypes: ObjectiveType[];
  isEditing?: boolean;
  onRemove?: () => void;
}) => {
  const [selectedObjectiveIndex, setSelectedObjectiveIndex] = useState<number | undefined>(
    isEmpty(goal.objectives) ? undefined : 0,
  );

  const currentObjective =
    !isUndefined(selectedObjectiveIndex) && goal.objectives[selectedObjectiveIndex]
      ? { params: goal.objectives[selectedObjectiveIndex], index: selectedObjectiveIndex }
      : undefined;

  const currentObjectiveType = allObjectiveTypes.find(t => t.objectiveTypeId === currentObjective?.params.typeId);

  const updateCurrentObjective = (update: Partial<CreateObjectiveBody>) => {
    if (!currentObjective) return;
    updateGoal({
      objectives: updateAtIndex(goal.objectives, currentObjective.index, update),
    });
  };

  const objectiveListItems = goal.objectives.map((objective, i) => ({
    key: i,
    prefix: `${goalIndex}.${i + 1}`,
    content: objective.description || "New Objective",
  }));

  return (
    <FullGrid container height="100%">
      <Grid xs={6} sx={{ borderRight: 1, borderColor: "grey.200", height: "100%", overflowY: "auto" }}>
        <Stack width="100%" gap={1} pr={2}>
          <Stack direction="row" mb={1} alignItems="center" justifyContent="space-between" height={"30px"}>
            <Typography variant="h3">Goal Description</Typography>
            {onRemove && (
              <IconButton onClick={onRemove} size="small">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
          <TextField
            multiline
            fullWidth
            rows={3}
            placeholder="Description"
            value={goal.description || ""}
            onChange={e => updateGoal({ description: e.target.value })}
            inputProps={{ "aria-label": "Goal Description" }}
          />
          <Typography variant="h3" pt={2} pb={1}>
            Objectives
          </Typography>
          <ListMenu
            items={objectiveListItems}
            selectedKey={selectedObjectiveIndex}
            onSelect={setSelectedObjectiveIndex}
          />
          <Button
            fullWidth
            onClick={() => {
              updateGoal({ objectives: [...goal.objectives, {}] });
              setSelectedObjectiveIndex(goal.objectives.length);
            }}
            startIcon={<AddIcon />}
            sx={{ mt: 1 }}
          >
            Add Objective
          </Button>
        </Stack>
      </Grid>

      <Grid xs={6}>
        {currentObjective && (
          <Stack width="100%" gap={1} pl={2}>
            <Stack direction="row" mb={1} alignItems="center" justifyContent="space-between">
              <Typography variant="h3" sx={{ height: "30px", alignContent: "center" }}>
                Objective Description
              </Typography>
              {!isEditing && (
                <IconButton
                  onClick={() => {
                    const [objectives, newIndex] = removeAtIndex(goal.objectives, currentObjective.index);
                    setSelectedObjectiveIndex(newIndex);
                    updateGoal({ objectives });
                  }}
                  size="small"
                >
                  <DeleteForeverIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
            <Stack width="100%" gap={3}>
              <TextField
                multiline
                fullWidth
                rows={3}
                placeholder="Description"
                value={currentObjective.params.description || ""}
                onChange={e => updateCurrentObjective({ description: e.target.value })}
                inputProps={{ "aria-label": "Objective Description" }}
              />
              <SelectInput
                label="Objective Type"
                options={allObjectiveTypes.map(t => ({ key: t.objectiveTypeId, label: t.title }))}
                value={currentObjective.params.typeId || ""}
                onChange={typeId => updateCurrentObjective({ typeId: typeId || undefined, targetValue: undefined })}
              />
              {currentObjectiveType && (
                <ObjectiveTargetInput
                  objectiveType={currentObjectiveType}
                  value={currentObjective.params.targetValue}
                  setValue={targetValue => updateCurrentObjective({ targetValue })}
                  key={currentObjective.index}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Grid>
    </FullGrid>
  );
};

export default SingleGoalInput;
