import { ReactNode, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { capitalize } from "lodash";
import FormLayout, { getCancelAction, getSubmitAction, SubmitFunction } from "@/components/shared/layout/FormLayout";

export type FormStep = {
  label: string;
  content: ReactNode;
  isValid?: boolean;
};

const StepForm = <P extends object, B extends object>({
  recordName,
  operationName,
  steps,
  params,
  validate,
  onSubmit,
  onCancel,
}: {
  recordName: string;
  operationName: "update" | "create";
  steps: FormStep[];
  params: P;
  validate: (stagedParams: P) => B | undefined;
  onSubmit: SubmitFunction<B>;
  onCancel: () => void;
}) => {
  const [stepIndex, setStepIndex] = useState(0);

  const currentStep = steps[stepIndex];

  const stepper = (
    <Stepper activeStep={stepIndex}>
      {steps.map(({ label }, i) => (
        <Step key={label} completed={stepIndex > i}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  const primaryAction =
    stepIndex !== steps.length - 1
      ? {
          onClick: currentStep.isValid ? async () => setStepIndex(stepIndex + 1) : undefined,
          label: "Next",
          icon: <ArrowRightIcon />,
        }
      : getSubmitAction(recordName, operationName, params, validate, onSubmit);

  const secondaryAction =
    stepIndex > 0
      ? {
          onClick: async () => setStepIndex(stepIndex - 1),
          label: "Back",
          icon: <ArrowLeftIcon />,
        }
      : getCancelAction(onCancel);

  return (
    <FormLayout
      headerText={`${capitalize(operationName)} ${capitalize(recordName)}`}
      headerContent={stepper}
      formContent={currentStep.content}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      minWidth={600}
    />
  );
};

export default StepForm;
