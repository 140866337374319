import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { pick } from "lodash";
import { observer } from "mobx-react-lite";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import {
  CreateCalendarBlockBody,
  createCalendarBlockBodySchema,
  ExtendedCalendarBlock,
} from "@parallel/vertex/types/calendar/calendar.block.types";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import RecurrenceEditModeInput from "@/components/shared/input/RecurrenceEditModeInput";
import RecurrenceInput from "@/components/shared/input/RecurrenceInput";
import TimeRangeInput from "@/components/shared/input/TimeRangeInput";
import SubmitForm from "@/components/shared/layout/SubmitForm";
import { UserOption } from "@/components/user/input/UserInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

type CalendarBlockParams = Partial<Omit<CreateCalendarBlockBody, "userId">> & { user: SelectOption | null };

export const RECORD_NAMES = {
  Availability: { singular: "availability", plural: "availabilities" },
  FocusTime: { singular: "focus time", plural: "focus time" },
};

const logger = initLogger("CalendarBlockForm", getLoggerContext);

const CalendarBlockForm = ({
  blockType,
  editing,
  selectedUser,
  onClose,
}: {
  blockType: "Availability" | "FocusTime";
  editing?: ExtendedCalendarBlock;
  selectedUser?: UserOption;
  onClose: () => void;
}) => {
  const {
    apiStore: { userApi },
    authStore: { currentUser },
    calendarStore,
  } = useContext(StoreContext);

  const [params, setParams] = useState<CalendarBlockParams>({
    blockType,
    ...calendarStore.defaultTimeRange,
    ...editing,
    title: editing?.title || undefined,
    user:
      selectedUser?.userType === "PROVIDER"
        ? { key: selectedUser.userId, label: selectedUser.fullName }
        : editing
          ? { key: editing.userId, label: editing.userName }
          : null,
  });

  const { currentPayPeriod } = calendarStore;

  const formContent = (
    <>
      <TimeRangeInput
        value={pick(params, "startTime", "endTime")}
        onChange={range => setParams({ ...params, ...range })}
        minStart={currentPayPeriod?.startTime}
      />
      {blockType === "FocusTime" && (
        <TextField
          label="Title (Optional)"
          value={params.title}
          onChange={e => setParams({ ...params, title: e.target.value })}
        />
      )}
      {(!editing || editing.recurrence) && (
        <RecurrenceInput
          recurrence={params.recurrence}
          onChange={recurrence => setParams({ ...params, recurrence })}
          eventStartTime={params.startTime || calendarStore.defaultTimeRange.startTime}
        />
      )}
      {currentUser?.userType === "ADMIN" && (
        <AutoCompletePageSearchInput
          label="Provider"
          search={keyword => userApi.searchProviders({ keyword }).catch(logger.handleFailure("searchProviders"))}
          getOption={p => ({ key: p.userId, label: p.fullName })}
          selected={params.user}
          onSelect={user => setParams({ ...params, user })}
        />
      )}
    </>
  );

  const validate = (params: CalendarBlockParams) => {
    if (params.startTime && currentPayPeriod && params.startTime < currentPayPeriod.startTime) return;
    return createCalendarBlockBodySchema.safeParse({ ...params, userId: params.user?.key })?.data;
  };

  const onSubmit = async (body: CreateCalendarBlockBody) => {
    editing
      ? await calendarStore
          .updateCalendarBlock(editing.calendarBlockId, body)
          .catch(logger.handleFailureAndThrow("updateCalendarBlock", { level: "warning" }))
      : await calendarStore
          .createCalendarBlock(body)
          .catch(logger.handleFailureAndThrow("createCalendarBlock", { level: "warning" }));

    onClose();
  };

  const confirmation = editing?.recurrence
    ? {
        prompt: async () => setParams({ ...params, recurrenceEditMode: "single" }),
        content: params.recurrenceEditMode && (
          <RecurrenceEditModeInput
            params={params}
            setParams={setParams}
            actionName="update"
            recordName={RECORD_NAMES[blockType]}
          />
        ),
      }
    : undefined;

  return (
    <SubmitForm
      recordName={RECORD_NAMES[blockType].singular}
      operationName={editing ? "update" : "create"}
      formContent={formContent}
      params={params}
      validate={validate}
      onSubmit={onSubmit}
      onCancel={onClose}
      confirmation={confirmation}
    />
  );
};

export default observer(CalendarBlockForm);
