import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

const SearchTextInput = ({
  value,
  setValue,
  width,
}: {
  value: string;
  setValue: (s: string) => void;
  width?: number;
}) => (
  <TextField
    label="Search"
    value={value}
    onChange={e => setValue(e.target.value)}
    size="small"
    InputProps={{
      startAdornment: <SearchIcon />,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton size="small" onClick={() => setValue("")}>
            <CloseIcon fontSize="small" sx={{ color: "grey.400" }} />
          </IconButton>
        </InputAdornment>
      ),
    }}
    sx={{ width }}
  />
);

export default SearchTextInput;
