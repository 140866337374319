import { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import FileInputBox from "@parallel/polygon/components/shared/input/FileInputBox";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { ReportEditorSubsection } from "@parallel/vertex/types/assessment/assessment.report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { getCommaList } from "@parallel/vertex/util/string.util";
import SelectInput from "@/components/shared/input/SelectInput";
import PromptLayout from "@/components/shared/layout/PromptLayout";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { FileState, guessTestUploadSourceLabel, resolveUploadRequest, useUploadOptions } from "@/util/report.util";

const logger = initLogger("AddSubsectionInput", getLoggerContext);

const AddSubsectionInput = ({ options, onFinished }: { options: ReportEditorSubsection[]; onFinished: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport, uploadTestFiles },
  } = useContext(StoreContext);

  const [selectedSection, setSelectedSection] = useState<ReportEditorSubsection>();

  const sectionOptions: SelectOption[] = options.map(s => ({ key: s.reportSectionTemplateId, label: s.title }));
  const onSectionSelect = (sectionId: string) =>
    setSelectedSection(options.find(s => s.reportSectionTemplateId === sectionId));

  const { sourceOptions, includeReasonOptions } = useUploadOptions(reportApi, logger);

  const eligibleSourceOptions = mapExists(selectedSection?.testUploadSourceIds, sourceId =>
    sourceOptions.find(s => s.key === sourceId),
  );

  const [fileState, setFileState] = useState<FileState>();

  if (!currentReport) return;

  const setFile = (file: File) => {
    const sourceLabel = guessTestUploadSourceLabel(file);
    const source = sourceLabel ? eligibleSourceOptions.find(s => s.label === sourceLabel) : undefined;
    setFileState({ file, sourceId: source?.key });
  };

  const validUploadRequest = fileState && resolveUploadRequest(currentReport, fileState);

  const uploadFile = async () => {
    if (!validUploadRequest) return;
    await uploadTestFiles([validUploadRequest]).catch(logger.handleFailureAndThrow("uploadTestFile"));
    onFinished();
  };

  return (
    <PromptLayout
      headerText="Add Subsection"
      leftAction={{ fn: onFinished, label: "Cancel" }}
      rightAction={{ fn: uploadFile, label: "Submit", icon: <UploadIcon />, disabled: !validUploadRequest }}
    >
      <Stack width={500} gap={3}>
        <SelectInput
          label="Subsection to Add"
          options={sectionOptions}
          value={selectedSection?.reportSectionTemplateId}
          onChange={onSectionSelect}
        />

        {selectedSection && (
          <>
            <Stack gap={1}>
              <Typography variant="body1">
                To add the selected subsection, upload an eligible test score document and submit below.
              </Typography>
              <Typography variant="body2">
                Eligible Tests: {getCommaList(eligibleSourceOptions.map(o => o.label))}
              </Typography>
            </Stack>

            <FileInputBox onFileSelect={fs => setFile(fs[0])}>
              <Stack justifyContent="center" alignItems="center" height={200}>
                <NoteAddIcon sx={{ fontSize: 80, color: "primary.light" }} />
                <Link component="label" sx={{ cursor: "pointer" }}>
                  Select file to upload
                  <VisuallyHiddenInput type="file" onChange={e => e.target.files && setFile(e.target.files[0])} />
                </Link>
                <Typography variant="body2">or drag and drop here</Typography>
              </Stack>
            </FileInputBox>
          </>
        )}

        {fileState && (
          <Stack gap={2}>
            <Stack direction="row" width="100%" gap={2} alignItems="center">
              <NoteAddIcon />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {fileState.file.name}
              </Typography>
              <IconButton onClick={() => setFileState(undefined)}>
                <DeleteIcon />
              </IconButton>
            </Stack>

            <SelectInput
              label="Test"
              options={eligibleSourceOptions}
              value={fileState.sourceId}
              onChange={sourceId => setFileState({ ...fileState, sourceId })}
              size="small"
              fullWidth
            />
            <SelectInput
              label="Reason Added"
              options={includeReasonOptions}
              value={fileState.includeReasonId}
              onChange={includeReasonId => setFileState({ ...fileState, includeReasonId })}
              size="small"
              fullWidth
            />
          </Stack>
        )}
      </Stack>
    </PromptLayout>
  );
};

export default AddSubsectionInput;
