import { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { ProgressUpdateOperation } from "@parallel/polygon/util/progress.util";
import { StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

export type ObjectiveActionsProps = {
  objective: StudentObjective;
  studentId: string;
  goal: StudentGoal;
  displayIndices: { goal: number; objective: number };
  onGoalUpdated: (g: StudentGoal) => void;
};

const logger = initLogger("ObjectiveActions", getLoggerContext);

const ObjectiveActions = ({ objective, studentId, goal, displayIndices, onGoalUpdated }: ObjectiveActionsProps) => {
  const {
    progressStore: { startPendingUpdate, setObjectiveCompleted, setObjectiveArchived },
  } = useContext(StoreContext);

  const reOpenObjective = () =>
    setObjectiveCompleted({ goal, objectiveId: objective.objectiveId, studentId, isCompleted: false })
      .then(onGoalUpdated)
      .catch(logger.handleFailureAndThrow("setObjectiveCompleted"));

  const unArchiveObjective = () =>
    setObjectiveArchived({ goal, objectiveId: objective.objectiveId, studentId, isArchived: false })
      .then(onGoalUpdated)
      .catch(logger.handleFailureAndThrow("setObjectiveArchived"));

  const isCompleted = !!objective.completedAt;

  const onPendingUpdate = (operation: ProgressUpdateOperation) =>
    startPendingUpdate(
      operation,
      { record: goal, displayIndex: displayIndices.goal },
      { record: objective, displayIndex: displayIndices.objective },
    );

  return (
    <Stack direction="row" gap={1} role="region">
      {!objective.isArchived ? (
        <>
          <Button onClick={() => onPendingUpdate("archive")} startIcon={<ArchiveIcon />}>
            Archive
          </Button>
          {isCompleted ? (
            <ProcessButton process={reOpenObjective} startIcon={<LockOpenIcon />} key="re-open">
              Re-Open
            </ProcessButton>
          ) : (
            <Button onClick={() => onPendingUpdate("complete")} startIcon={<CheckCircleOutlineIcon />} key="complete">
              Achieved
            </Button>
          )}
        </>
      ) : (
        <ProcessButton process={unArchiveObjective} startIcon={<UnarchiveIcon />} key="archive">
          Unarchive
        </ProcessButton>
      )}
    </Stack>
  );
};

export default ObjectiveActions;
