import { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const WithLabel = ({ label, children }: { label: string; children: ReactNode }) => (
  <Stack gap={0.5}>
    <Typography variant="h3">{label}</Typography>
    {children}
  </Stack>
);
