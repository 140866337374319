import { useContext, useState } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FormAnswers } from "@parallel/vertex/types/form.types";
import { ReportContentLayout } from "@/components/report/ReportContent";
import FeatheryForm from "@/components/shared/display/FeatheryForm";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportTestingPlan", getLoggerContext);

const ReportTestingPlan = () => {
  const {
    apiStore: { reportApi },
    reportStore: { currentReport: report, updateCurrentReport },
  } = useContext(StoreContext);

  const [isComplete, setIsComplete] = useState(false);

  if (!report) return <></>;

  const { featheryFormId, submissionId } = report.formSubmissions.testingPlan;

  const onSubmit = (formAnswers: FormAnswers) =>
    updateCurrentReport(reportId => reportApi.updateTestingPlan(reportId, { formAnswers }))
      .then(() => setIsComplete(true))
      .catch(logger.handleFailureAndThrow("updateTestingPlan", { level: "warning" }));

  return (
    <ReportContentLayout title="Testing Plan">
      {!isComplete ? (
        <FeatheryForm featheryFormId={featheryFormId} submissionId={submissionId} onSubmit={onSubmit} />
      ) : (
        <Typography variant="body1">
          Successfully Submitted Form -{" "}
          <Link component="button" onClick={() => setIsComplete(false)}>
            Show Answers
          </Link>
        </Typography>
      )}
    </ReportContentLayout>
  );
};

export default observer(ReportTestingPlan);
