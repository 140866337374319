import { DateTime, WeekdayNumbers } from "luxon";
import { DayOfWeek, WEEK_DAYS } from "../enums/calendar.enums";
import { DateTimeRange } from "../types/shared.types";

const US_TIMEZONE_NAMES = ["America/Los_Angeles", "America/Denver", "America/Chicago", "America/New_York"];

export const toLuxonWeekday = (day: DayOfWeek): WeekdayNumbers =>
  day === "SUNDAY" ? 7 : (WEEK_DAYS.indexOf(day) as WeekdayNumbers);

export const fromLuxonWeekday = (weekday: number): DayOfWeek => WEEK_DAYS[weekday % 7];

export const isWithinDays = (
  { startTime, endTime }: { startTime: DateTime; endTime: DateTime },
  days: number,
): boolean => {
  const startsTooEarly = startTime > DateTime.now().plus({ days });
  const endsTooLate = endTime < DateTime.now().minus({ days });

  return !startsTooEarly && !endsTooLate;
};

export const isOverlap = (a1: DateTimeRange, a2: DateTimeRange) =>
  a1.endTime > a2.startTime && a1.startTime < a2.endTime;

export const isWithin = (basis: DateTimeRange, test: DateTimeRange) =>
  test.startTime >= basis.startTime && test.endTime <= basis.endTime;

export const isInRange = (range: DateTimeRange, time: DateTime) => time >= range.startTime && time <= range.endTime;

export const toUtcDate = (datetime: DateTime) => datetime.set({ hour: 12 }).toUTC().startOf("day");

export const toLocalDate = (datetime: DateTime, timezone: string) =>
  datetime.set({ hour: 12 }).setZone(timezone).startOf("day");

export const timeRangeStringTuple = (
  range: DateTimeRange,
  timezone: string,
  { withZone }: { withZone?: boolean } = {},
) => [
  range.startTime.setZone(timezone).toLocaleString(DateTime.TIME_SIMPLE),
  range.endTime
    .setZone(timezone)
    .toLocaleString({ ...DateTime.TIME_SIMPLE, timeZoneName: withZone ? "short" : undefined }),
];

export const timeRangeString = (range: DateTimeRange, timezone: string, options: { withZone?: boolean }) =>
  timeRangeStringTuple(range, timezone, options).join(" - ");

export const shortTimeZone = (dt: DateTime) => {
  return `${dt.offset / 60}:00 ${dt.offsetNameShort}`;
};

export const friendlyTimeZone = (dt: DateTime) => {
  const offsetHours = dt.offset / 60;
  let finalString = `${offsetHours}:00`;

  if (dt.offsetNameLong) finalString += ` ${dt.offsetNameLong}`;

  const city = dt.zoneName?.split("/")[1];
  if (city) finalString += ` - ${city.replace(/_/g, " ")}`;

  return finalString;
};

export const getValidTimezones = (current: DateTime) =>
  US_TIMEZONE_NAMES.map(name => {
    const zoned = current.setZone(name);
    return { name, friendly: friendlyTimeZone(zoned), isCurrent: current.zone === zoned.zone };
  });
