import { useContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { FullBox } from "@parallel/polygon/components/shared/layout/container";
import ReportEditorSection from "@/components/report/editor/ReportEditorSection";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ReportEditor", getLoggerContext);

const ReportEditor = ({ sectionTemplateId }: { sectionTemplateId: string }) => {
  const {
    reportStore: { currentReport, filteredSections },
  } = useContext(StoreContext);

  useEffect(() => {
    logger.info("section template id updated", { context: { sectionTemplateId } });
  }, [sectionTemplateId]);

  if (!currentReport) return <></>;

  return (
    <FullBox>
      <Stack width={800} p={2} gap={4} sx={{ border: 2, borderColor: "grey.300" }}>
        <Typography variant="h1">{currentReport.template.title}</Typography>

        {filteredSections.map(section => (
          <ReportEditorSection section={section} key={section.reportSectionTemplateId} />
        ))}
      </Stack>
    </FullBox>
  );
};

export default observer(ReportEditor);
