import { ReactNode } from "react";
import { Stack } from "@mui/material";
import { FullCenterBox } from "@parallel/polygon/components/shared/layout/container";
import config from "@/config";

const CenterLayout = ({ children }: { children: ReactNode }) => {
  return (
    <FullCenterBox
      sx={{
        bgcolor: "grey.300",
        backgroundImage: 'url("https://storage.googleapis.com/parallel-static-assets/images/login-background.webp")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Stack bgcolor="grey.50" width={500} p={6} borderRadius={2} gap={3}>
        <div style={{ alignSelf: "center" }}>
          <img
            src={`${config.staticAssetsUrl}/logos/parallel-wordmark-ocean.svg`}
            alt="Parallel Logo"
            style={{ height: 52, margin: "0 auto 16px auto", display: "flex" }}
          />
        </div>
        {children}
      </Stack>
    </FullCenterBox>
  );
};

export default CenterLayout;
