import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import { SplitRow } from "@parallel/polygon/components/shared/layout/container";
import { ReportEditorParentSection } from "@parallel/vertex/types/assessment/assessment.report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import ReportEditorBlock from "@/components/report/editor/ReportEditorBlock";
import ReportEditorSubsection from "@/components/report/editor/ReportEditorSubsection";
import GeneralInformationTable from "@/components/report/editor/content/GeneralInformationTable";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getParentSectionMarkdown } from "@/util/report.markdown.util";
import { useEditorAutoScroll } from "@/util/report.util";

const logger = initLogger("ReportEditorSectionComponent", getLoggerContext);

const ReportEditorSectionComponent = ({ section }: { section: ReportEditorParentSection }) => {
  const {
    reportStore: { menuSelection, onEditorSectionVisibilityChange },
  } = useContext(StoreContext);

  const { containerRef } = useEditorAutoScroll(
    section.reportSectionTemplateId,
    menuSelection,
    onEditorSectionVisibilityChange,
  );

  const isGeneralInformation = section.title === "General Information";

  return (
    <Stack width="100%" gap={3}>
      <Stack width="100%" gap={3} ref={containerRef}>
        <SplitRow
          left={<Typography variant="h2">{section.title}</Typography>}
          right={
            isGeneralInformation ? (
              ""
            ) : (
              <CopyIconButton
                text={getParentSectionMarkdown(section)}
                label={`Section ${section.title}`}
                logger={logger}
              />
            )
          }
        />

        {isGeneralInformation && <GeneralInformationTable />}

        {mapExists(
          section.children,
          child => child.type === "block" && <ReportEditorBlock block={child} key={child.reportBlockTemplateId} />,
        )}
      </Stack>

      {mapExists(
        section.children,
        child =>
          child.type === "subsection" && (
            <ReportEditorSubsection subsection={child} key={child.reportSectionTemplateId} />
          ),
      )}
    </Stack>
  );
};

export default observer(ReportEditorSectionComponent);
