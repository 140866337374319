import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { capitalize, groupBy, isEmpty, map, mapValues } from "lodash";
import { SelectOption } from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import {
  ASSIGNMENT_TYPES,
  AssignmentType,
  ExtendedStudentUser,
  UpdateStudentAssignmentBody,
} from "@parallel/vertex/types/user/student.types";
import { formatNounWithCount } from "@parallel/vertex/util/string.util";
import { UserAPI } from "@/api/user.api";
import AutoCompletePageSearchInput from "@/components/shared/input/AutoCompletePageSearchInput";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const ASSIGN_OPERATION_DISPLAY = {
  add: { name: "Add to All", color: "primary.main" },
  remove: { name: "Remove From All", color: "error.main" },
  none: { name: "No Change", color: undefined },
} as const;
type AssignOperation = keyof typeof ASSIGN_OPERATION_DISPLAY;

type AssignUser = { userId: string; fullName: string };

type AssignUserState = AssignUser & { studentCount: number; operation: AssignOperation };

type AssignmentTypeProps = {
  getCurrent: (s: ExtendedStudentUser) => AssignUser[];
  searchAvailable: (keyword: string) => Promise<PaginatedResult<AssignUser> | null>;
};

const logger = initLogger("StudentAssignmentEdit", getLoggerContext);

const useAssignmentTypeProps = (
  students: ExtendedStudentUser[],
  assignmentType: AssignmentType,
  userApi: UserAPI,
): AssignmentTypeProps => {
  const eligibleStudentIds = students.map(s => s.userId);
  switch (assignmentType) {
    case "facilitator":
      return {
        getCurrent: student => student.facilitators,
        searchAvailable: keyword =>
          userApi.searchFacilitators({ keyword, eligibleStudentIds }).catch(logger.handleFailure("searchFacilitators")),
      };
    case "provider":
      return {
        getCurrent: student => student.providers,
        searchAvailable: keyword =>
          userApi.searchProviders({ keyword, eligibleStudentIds }).catch(logger.handleFailure("searchProviders")),
      };
  }
};

const toInitialAssignState = (users: { userId: string; fullName: string }[]): Record<string, AssignUserState> => {
  const duplicateGroups = groupBy(users, "userId");
  return mapValues(duplicateGroups, users => ({
    userId: users[0].userId,
    fullName: users[0].fullName,
    studentCount: users.length,
    operation: "none" as AssignOperation,
  }));
};

const StudentAssignmentEdit = ({
  students,
  onCancel,
  onSave,
}: {
  students: ExtendedStudentUser[];
  onCancel: () => void;
  onSave: (updates: ExtendedStudentUser[]) => void;
}) => {
  const {
    apiStore: { userApi },
    authStore: { currentUser },
  } = useContext(StoreContext);

  const [assignmentType, setAssignmentType] = useState<AssignmentType>("facilitator");

  const { getCurrent, searchAvailable } = useAssignmentTypeProps(students, assignmentType, userApi);

  const [newAssignments, setNewAssignments] = useState<AssignUser[]>([]);
  const [currentAssignments, setCurrentAssignments] = useState<Record<string, AssignUserState>>({});

  const resetState = () => {
    setNewAssignments([]);
    setCurrentAssignments(toInitialAssignState(students.flatMap(getCurrent)));
  };
  useEffect(resetState, [assignmentType, students]);

  const allVisibleAssignmentIds = [...newAssignments.map(a => a.userId), ...Object.keys(currentAssignments)];

  const setAssignOperation = (assignUserId: string, operation: AssignOperation) => {
    setCurrentAssignments({
      ...currentAssignments,
      [assignUserId]: { ...currentAssignments[assignUserId], operation },
    });
  };

  const performSave = async () => {
    const currentUpdates = Object.values(currentAssignments);
    const request: UpdateStudentAssignmentBody = {
      studentIds: students.map(s => s.userId),
      type: assignmentType,
      addUserIds: [
        ...newAssignments.map(a => a.userId),
        ...currentUpdates.filter(a => a.operation === "add").map(a => a.userId),
      ],
      removeUserIds: currentUpdates.filter(a => a.operation === "remove").map(a => a.userId),
    };

    const updated = await userApi
      .updateStudentAssignments(request)
      .catch(logger.handleFailure("updateStudentAssignments"));

    updated ? onSave(updated) : toast.error("Error saving student assignments");
  };

  const isAdmin = currentUser?.userType === "ADMIN";
  const headerText = isAdmin ? "Edit Student Assignments" : "Edit Student Facilitators";

  return (
    <Stack width={800} spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">{headerText}</Typography>
        {isAdmin && (
          <ButtonGroup size="medium">
            {ASSIGNMENT_TYPES.map((type, i) => (
              <Button
                key={i}
                variant={assignmentType === type ? "contained" : "outlined"}
                onClick={() => setAssignmentType(type)}
              >
                <Typography variant="subtitle2">{capitalize(type)}</Typography>
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Stack>

      <Grid container height={400}>
        <Grid xs={5} borderRight={1} borderColor="grey.400" height="100%" overflow="auto" pr={2}>
          <Typography variant="h3" mb={2}>
            Selected Students
          </Typography>
          <Stack direction="column">
            {students.map(student => (
              <Box width="100%" py={2} borderBottom={1} borderColor="grey.400" alignItems="center" key={student.userId}>
                <Typography>{student.fullName}</Typography>
              </Box>
            ))}
          </Stack>
        </Grid>
        <Divider />

        <Grid xs={7} pl={2} height="100%" overflow="auto">
          <Box>
            <Typography variant="h3" mb={isEmpty(newAssignments) ? 3 : 2}>
              New {capitalize(assignmentType)}s
            </Typography>
            {!isEmpty(newAssignments) && (
              <Stack>
                {newAssignments.map(({ userId, fullName }) => (
                  <Box width="100%" borderBottom={1} pb={2} mb={2} borderColor="grey.400" key={userId}>
                    <Typography variant="body1">{fullName}</Typography>
                  </Box>
                ))}
              </Stack>
            )}
            <AutoCompletePageSearchInput
              label={`Select ${capitalize(assignmentType)}`}
              search={searchAvailable}
              getOption={u =>
                allVisibleAssignmentIds.includes(u.userId) ? null : { key: u.userId, label: u.fullName }
              }
              selected={null as SelectOption | null}
              onSelect={selected =>
                selected && setNewAssignments([...newAssignments, { userId: selected.key, fullName: selected.label }])
              }
            />
          </Box>

          <Box mt={3}>
            <Typography variant="h3" mb={2}>
              Current {capitalize(assignmentType)}s
            </Typography>
            {!isEmpty(currentAssignments) && (
              <Stack>
                {Object.values(currentAssignments).map(({ userId, fullName, studentCount, operation }) => (
                  <Stack
                    pb={2}
                    mb={2}
                    direction="row"
                    borderBottom={1}
                    borderColor="grey.400"
                    justifyContent="space-between"
                    alignItems="center"
                    key={userId}
                  >
                    <Stack>
                      <Typography variant="body1">{fullName}</Typography>
                      <Typography variant="body2">
                        Assigned to {formatNounWithCount(studentCount, "student")}
                      </Typography>
                    </Stack>

                    <FormControl size="small">
                      <Select
                        value={operation}
                        onChange={e => setAssignOperation(userId, e.target.value as AssignOperation)}
                        sx={{ color: ASSIGN_OPERATION_DISPLAY[operation].color }}
                      >
                        {map(ASSIGN_OPERATION_DISPLAY, ({ name, color }, operation) =>
                          operation === "add" && studentCount === students.length ? (
                            <></>
                          ) : (
                            <MenuItem
                              value={operation}
                              key={`${userId}-${operation}`}
                              sx={{
                                "& .MuiListItemText-root": { color },
                                "& .MuiListItemIcon-root": { color },
                              }}
                            >
                              {name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                ))}
              </Stack>
            )}
          </Box>
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="space-between">
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={performSave} startIcon={<SaveIcon />} variant="contained">
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default StudentAssignmentEdit;
