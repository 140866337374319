import {
  CreateGoalBody,
  ObjectiveType,
  StudentAppointmentProgress,
  StudentGoal,
  StudentObjective,
  UpdateGoalBody,
  UpdateAppointmentProgressBody,
  SetStudentGoalsBody,
  AppointmentProgress,
  ObjectiveMetricBody,
  SearchStudentProgressQuery,
  SingleStudentAppointmentProgress,
} from "@parallel/vertex/types/progress.types";
import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { BaseAPI } from "@/api/base.api";

export class ProgressAPI extends BaseAPI {
  constructor() {
    super({ parseTimestamps: true });
  }

  public createStudentGoal = (studentId: string, body: CreateGoalBody): Promise<StudentGoal> =>
    this.instance.post(`progress/student/${studentId}/goal`, body).then(r => r.data);

  public updateStudentGoal = (studentId: string, goalId: string, body: UpdateGoalBody): Promise<StudentGoal> =>
    this.instance
      .put(`progress/student/${studentId}/goal/${goalId}`, { ...body, includeArchived: true })
      .then(r => r.data);

  public setAllStudentGoals = (studentId: string, body: SetStudentGoalsBody): Promise<StudentGoal[]> =>
    this.instance.put(`progress/student/${studentId}/goal`, body).then(r => r.data.goals);

  public getAppointmentProgress = (appointmentId: string): Promise<AppointmentProgress> =>
    this.instance.get(`progress/appointment/${appointmentId}`, { params: { includeArchived: true } }).then(r => r.data);

  public searchStudentProgress = (
    studentId: string,
    query: SearchStudentProgressQuery,
  ): Promise<PaginatedResult<SingleStudentAppointmentProgress>> =>
    this.instance
      .get(`progress/student/${studentId}`, { params: { ...query, includeArchived: true } })
      .then(r => r.data);

  public updateStudentAppointmentProgress = (
    studentId: string,
    appointmentId: string,
    body: UpdateAppointmentProgressBody,
  ): Promise<StudentAppointmentProgress> =>
    this.instance
      .put(`progress/student/${studentId}/appointment/${appointmentId}`, { ...body, includeArchived: true })
      .then(r => r.data);

  public upsertObjectiveAppointmentMetric = (
    objectiveId: string,
    appointmentId: string,
    body: ObjectiveMetricBody,
  ): Promise<StudentObjective> =>
    this.instance.put(`progress/objective/${objectiveId}/appointment/${appointmentId}/metric`, body).then(r => r.data);

  public getAllObjectiveTypes = (): Promise<ObjectiveType[]> =>
    this.instance.get("user/objective-type").then(r => r.data.types);

  public setStudentNote = (studentId: string, appointmentId: string, note: string): Promise<void> =>
    this.instance.put(`progress/student/${studentId}/appointment/${appointmentId}/note`, { note });

  public setGroupNote = (appointmentId: string, note: string): Promise<void> =>
    this.instance.put(`progress/appointment/${appointmentId}/note`, { note });
}
