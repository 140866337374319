import { useContext } from "react";
import { ExtendedFrontendEvent } from "@parallel/vertex/types/calendar/appointment.event.types";
import { ExtendedAppointment } from "@parallel/vertex/types/calendar/appointment.types";
import DataTable, { DataTableColumn, DataTablePagination } from "@/components/shared/layout/DataTable";
import { StoreContext } from "@/stores";

type MeetingEventRow = ExtendedFrontendEvent & { id: string; userName?: string };

export const findAppointmentUser = (event: ExtendedFrontendEvent, appointment: ExtendedAppointment) =>
  event.userId === appointment.provider.userId
    ? appointment.provider
    : appointment.students.find(s => s.userId === event.userId);

export const toMeetingEventRow = (
  event: ExtendedFrontendEvent,
  appointment: ExtendedAppointment,
  timezone: string,
): MeetingEventRow => ({
  ...event,
  id: event.frontendEventId,
  timestamp: event.timestamp.setZone(timezone),
  userName: findAppointmentUser(event, appointment)?.fullName,
});

const COLUMNS: DataTableColumn<MeetingEventRow>[] = [
  { key: "timestamp", header: "Timestamp", type: "datetime" },
  { key: "type", header: "Type" },
  { key: "message", header: "Message", flexWidth: 3 },
  { key: "userName", header: "User" },
];

const AppointmentEventsDataTable = ({
  appointment,
  events,
  pagination,
  omitUser,
}: {
  appointment: ExtendedAppointment;
  events: ExtendedFrontendEvent[];
  pagination?: DataTablePagination;
  omitUser?: boolean;
}) => {
  const {
    authStore: { timezone },
  } = useContext(StoreContext);

  const columns = COLUMNS.filter(c => !omitUser || c.key !== "userName");
  const rows = events.map(e => toMeetingEventRow(e, appointment, timezone));

  return <DataTable columns={columns} data={rows} pagination={pagination} />;
};

export default AppointmentEventsDataTable;
