import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMountEffect } from "@react-hookz/web";
import { isUndefined } from "lodash";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { ExtendedPasswordReset } from "@parallel/vertex/types/auth.types";
import CenterLayout from "@/components/shared/layout/CenterLayout";
import LoadingScreen from "@/screens/LoadingScreen";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("ResetPasswordScreen", getLoggerContext);

const ResetPasswordScreen = () => {
  const {
    apiStore: { authApi },
  } = useContext(StoreContext);

  const { resetId } = useParams();
  const navigate = useNavigate();

  const [reset, setReset] = useState<ExtendedPasswordReset | null>();
  useMountEffect(
    () => resetId && authApi.getPasswordReset(resetId).catch(logger.handleFailure("getPasswordReset")).then(setReset),
  );

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [hasRestarted, setHasRestarted] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  if (isUndefined(reset)) return <LoadingScreen />;

  const submitPassword = async () => {
    if (!reset) return;
    setErrorMessage("");
    return authApi
      .performPasswordReset(reset.passwordResetId, password)
      .then(() => {
        const loginParams = reset.email && new URLSearchParams({ email: reset.email });
        navigate(`/?${loginParams}`);
      })
      .catch(e => {
        logger.logFailure("performPasswordReset", e);
        setErrorMessage(
          "Unexpected error resetting password - Please try again and contact support if the issue persists",
        );
        throw e;
      });
  };

  const content = reset ? (
    <>
      <Typography>Enter new password{reset.email && ` for account ${reset.email}`} below</Typography>
      <TextField type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} />
      <ProcessButton variant="contained" process={submitPassword}>
        Submit
      </ProcessButton>
      {errorMessage && <Typography color="error.main">{errorMessage}</Typography>}
    </>
  ) : !hasRestarted ? (
    <>
      <Typography>
        This password reset link has expired. Please request another link by submitting your account email below.
      </Typography>
      <TextField label="Email" value={email} onChange={e => setEmail(e.target.value)} />
      <ProcessButton
        variant="contained"
        process={() =>
          authApi
            .startPasswordReset(email)
            .then(() => setHasRestarted(true))
            .catch(logger.handleFailureAndThrow("startPasswordReset"))
        }
      >
        Submit
      </ProcessButton>
    </>
  ) : (
    <>
      <Typography>
        If an account is registered to {email} you will receive an email with a link to reset your password soon.
      </Typography>
      <Typography>
        If you don't receive an email, check your spam / junk folder and confirm that you're using the same email
        address that was used to create your account.
      </Typography>
    </>
  );

  return <CenterLayout>{content}</CenterLayout>;
};

export default ResetPasswordScreen;
