import { Button, ButtonProps, Link } from "@mui/material";

export const LinkButton = (props: ButtonProps & { href: string; newTab?: boolean }) => (
  <Link
    href={props.href}
    rel={props.newTab ? "noopener noreferrer" : undefined}
    target={props.newTab ? "_blank" : undefined}
  >
    <Button {...props} />
  </Link>
);
