import {
  AssessmentEnrollment,
  LanguageType,
  Report,
  ReportTestUpload,
  ReportTestUploadSource,
  ReportSectionTemplate,
  ReportBlockTemplate,
  ReportBlockCustom,
  ReportSectionCustom,
  Client,
} from "@prisma/client";
import { DateTime } from "luxon";
import { z } from "zod";
import { ReportType } from "../../enums/report.enums";
import { ORDERED_LANGUAGES } from "../../enums/user.enums";
import { formAnswersSchema } from "../form.types";
import {
  booleanQuerySchema,
  datetimeRequestField,
  nullableQueryStringSchema,
  Override,
  paginateQueryFields,
  sortQueryFields,
} from "../shared.types";
import { searchStudentQuerySchema } from "../user/student.types";
import { ExtendedUser } from "../user/user.types";

export type {
  ReportTestUpload,
  ReportTestUploadSource,
  ReportTestUploadIncludeReason,
  ReportTestUploadSkipReason,
  ReportSectionTemplate,
  ReportSectionCustom,
} from "@prisma/client";

type TruncatedServiceLine = { serviceLineId: string; title: string; languages: LanguageType[] };

type ReportClient = ExtendedUser &
  Override<
    Client,
    {
      birthDate?: DateTime;
      campus?: { id: string; name: string };
    }
  >;

export type ExtendedAssessmentEnrollment = Override<
  AssessmentEnrollment,
  {
    client: ReportClient;
    provider: ExtendedUser;
    serviceLine: TruncatedServiceLine;
    assessmentDueDate: DateTime;
    consentSignedAt?: DateTime;
    eligibleReviewers: { userId: string; name: string }[];
  }
>;

type ExtendedNoEnrollmentReport = Override<Report, { dueReminderSentAt?: DateTime }>;

export type ExtendedReport = ExtendedNoEnrollmentReport &
  Omit<ExtendedAssessmentEnrollment, "reportId"> & { reviewer: ExtendedUser };

export type ReportForm = { submissionId: string; featheryFormId: string; hasAnswers: boolean };

export type ExtendedReportTestUpload = Override<
  ReportTestUpload,
  {
    source: ReportTestUploadSource;
    skipReason?: { id: string; title: string };
  }
>;

export type ReportBlockTableRow = { cellValues: string[]; key: string };

export type ReportBlockTableTemplate = {
  columns: { header: string; flexWidth?: number }[];
  defaultRows?: ReportBlockTableRow[];
};

export type ReportEditorBlock = Override<
  ReportBlockTemplate,
  {
    custom?: Override<ReportBlockCustom, { tableRows?: ReportBlockTableRow[] }>;
    table?: ReportBlockTableTemplate;
  }
>;

export type ReportEditorSection = ReportSectionTemplate & {
  custom?: ReportSectionCustom;
  testUploadSourceIds: string[];
};

export type ReportEditorSubsection = ReportEditorSection & {
  blocks: ReportEditorBlock[];
};

export type ReportEditorSectionChild =
  | ({ type: "subsection" } & ReportEditorSubsection)
  | ({ type: "block" } & ReportEditorBlock);

export type ReportEditorParentSection = ReportEditorSection & {
  children: ReportEditorSectionChild[];
};

export type SingleReport = ExtendedReport & {
  template: { title: string };
  formSubmissions: {
    testingPlan: ReportForm;
    caregiverInterview?: ReportForm;
    studentInterview?: ReportForm;
    teacherInterview?: ReportForm;
  };
  testUploads: ExtendedReportTestUpload[];
  editorSections: ReportEditorParentSection[];
};

export type ServiceReportType = {
  reportType: ReportType;
  serviceLine: { id: string; title: string; languages: LanguageType[] };
};

export type ServiceReportEnrollment = ServiceReportType & {
  serviceLineClientId: string;
  consentSignedAt?: DateTime;
  assessmentDueDate?: DateTime;
  eligibleReviewers: { userId: string; name: string }[];
};

// deprecated
export type ReportStudentDetails = {
  enrolledReportType?: ServiceReportType & { consentSignedAt?: DateTime };
  allReportTypes: ServiceReportType[];
};

export type ReportStudent = ExtendedUser & { enrollment: ServiceReportEnrollment };

export const searchAssessmentEnrollmentQuerySchema = searchStudentQuerySchema.extend({
  reportId: nullableQueryStringSchema.optional(),
});
export type SearchAssessmentEnrollmentQuery = z.infer<typeof searchAssessmentEnrollmentQuerySchema>;

export const searchReportQuerySchema = z.object({
  providerId: z.string().uuid().optional(),
  isCompleted: booleanQuerySchema.optional(),
  studentKeyword: z.string().trim().optional(),
  providerKeyword: z.string().trim().optional(),
  serviceLineKeyword: z.string().trim().optional(),
  ...sortQueryFields,
  ...paginateQueryFields,
});
export type SearchReportQuery = z.infer<typeof searchReportQuerySchema>;

export const createReportBodySchema = z.object({
  serviceLineClientId: z.string().uuid(),
  serviceLineId: z.string().uuid().optional(),
  languages: z.enum(ORDERED_LANGUAGES).array().min(1),
  reviewerId: z.string().uuid(),
  assessmentDueDate: datetimeRequestField,
  consentSignedAt: datetimeRequestField.optional(),
});
export type CreateReportBody = z.infer<typeof createReportBodySchema>;

export const updateTestingPlanBodySchema = z.object({
  formAnswers: formAnswersSchema.optional(),
});
export type UpdateTestingPlanBody = z.infer<typeof updateTestingPlanBodySchema>;

export const reportInterviewNotesInitialBodySchema = z.object({
  formTemplateId: z.string().uuid(),
  submissionId: z.string().uuid(),
});
export type ReportInterviewNotesInitialBody = z.infer<typeof reportInterviewNotesInitialBodySchema>;

export const updateReportUploadBodySchema = z.object({
  fileName: z.string().nullable().optional(),
  skipReasonId: z.string().uuid().nullable().optional(),
  isArchived: z.boolean().optional(),
});
export type UpdateReportUploadBody = z.infer<typeof updateReportUploadBodySchema>;

export const updateReportSectionBodySchema = z.object({
  isHidden: z.boolean().optional(),
});
export type UpdateReportSectionBody = z.infer<typeof updateReportSectionBodySchema>;

export const updateReportSectionOrderBodySchema = z.object({
  fromIndex: z.number(),
  toIndex: z.number(),
});
export type UpdateReportSectionOrderBody = z.infer<typeof updateReportSectionOrderBodySchema>;

export const updateReportBlockBodySchema = z.object({
  content: z.string().optional(),
  tableRows: z.object({ cellValues: z.string().array(), key: z.string() }).array().optional(),
});
export type UpdateReportBlockBody = z.infer<typeof updateReportBlockBodySchema>;
