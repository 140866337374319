import {
  SearchServiceLineQuery,
  ServiceLine,
  ServiceLineClientArchiveReason,
  ServiceRequirementType,
} from "@parallel/vertex/types/service.types";
import { BaseAPI } from "@/api/base.api";

export class ServiceAPI extends BaseAPI {
  public searchServiceLines = (params: SearchServiceLineQuery = {}): Promise<ServiceLine[]> =>
    this.instance.get("service", { params }).then(r => r.data.serviceLines);

  public searchServiceRequirementTypes = (): Promise<ServiceRequirementType[]> =>
    this.instance.get("service/requirement/type").then(r => r.data.types);

  public archiveStudentService = (serviceClientId: string, reasonId: string): Promise<void> =>
    this.instance.delete(`service/student/${serviceClientId}`, { data: { reasonId } });

  public getAllServiceClientArchiveReasons = (): Promise<ServiceLineClientArchiveReason[]> =>
    this.instance.get("service/student/archive-reason").then(r => r.data.reasons);
}
