import { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isUndefined, sum } from "lodash";
import { v4 as uuid } from "uuid";
import { CopyIconButton } from "@parallel/polygon/components/shared/input/button.input";
import {
  ProcessButton,
  StatusIcon,
  useAsyncProcessStatus,
  useDebouncedProcessStatus,
} from "@parallel/polygon/components/shared/input/status.input";
import { ReportBlockTableRow, ReportEditorBlock } from "@parallel/vertex/types/assessment/assessment.report.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { toPercentString } from "@parallel/vertex/util/number.util";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";
import { getBlockTableMarkdown } from "@/util/report.markdown.util";

const logger = initLogger("BlockTable", getLoggerContext);

const getTableRows = (block: ReportEditorBlock): ReportBlockTableRow[] =>
  block.custom?.tableRows || block.table?.defaultRows || [];

const TableCellInput = ({
  blockTemplateId,
  rowIndex,
  cellIndex,
  value,
}: {
  blockTemplateId: string;
  rowIndex: number;
  cellIndex: number;
  value: string;
}) => {
  const {
    reportStore: { updateBlockTableCellValue },
  } = useContext(StoreContext);

  const [stagedValue, setStagedValue] = useState(value);

  const { status: saveStatus, perform: saveValue } = useDebouncedProcessStatus(
    (value: string) => updateBlockTableCellValue(blockTemplateId, rowIndex, cellIndex, value),
    { saveKey: blockTemplateId },
  );

  const onChange = (value: string) => {
    setStagedValue(value);
    saveValue(value);
  };

  return (
    <>
      <input
        type="text"
        value={stagedValue}
        onChange={e => onChange(e.target.value)}
        style={{ padding: "0 16px", width: "100%", height: "100%" }}
      />
      <Stack position="absolute" right={0} pr={1} height="100%" justifyContent="center">
        {<StatusIcon status={saveStatus} size={16} />}
      </Stack>
    </>
  );
};

const TableRow = ({
  block,
  cells,
  rowIndex,
}: {
  block: ReportEditorBlock;
  cells: { value: string; flexWidth: number }[];
  rowIndex?: number;
}) => {
  const {
    reportStore: { upsertCustomBlock },
  } = useContext(StoreContext);

  const { status: removeStatus, perform: removeRow } = useAsyncProcessStatus(() => {
    const updatedTableRows = mapExists(getTableRows(block), (row, i) => i !== rowIndex && row);
    return upsertCustomBlock(block.reportBlockTemplateId, { tableRows: updatedTableRows });
  });
  const isRemoveLoading = removeStatus?.value === "loading";

  const isHeader = isUndefined(rowIndex);
  const totalFlexWidth = sum(cells.map(c => c.flexWidth));

  return (
    <Stack direction="row" height="40px" sx={{ borderLeft: 1, borderColor: "grey.300" }}>
      {cells.map((cell, i) => (
        <Stack
          height="100%"
          width={toPercentString(cell.flexWidth / totalFlexWidth)}
          position="relative"
          px={isHeader ? 2 : undefined}
          justifyContent="center"
          sx={{ borderTop: isHeader ? 1 : 0, borderRight: 1, borderBottom: 1, borderColor: "grey.300" }}
          key={i}
        >
          {isHeader ? (
            <Typography variant={"subtitle1"}>{cell.value}</Typography>
          ) : (
            <TableCellInput
              blockTemplateId={block.reportBlockTemplateId}
              rowIndex={rowIndex}
              cellIndex={i}
              value={cell.value}
            />
          )}
        </Stack>
      ))}
      <Stack width={40} justifyContent="center" alignItems="center">
        {!isHeader ? (
          isRemoveLoading ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton size="small" onClick={removeRow}>
              <RemoveIcon sx={{ color: "primary.main" }} />
            </IconButton>
          )
        ) : (
          <CopyIconButton text={getBlockTableMarkdown(block)} label="Block Table" logger={logger} />
        )}
      </Stack>
    </Stack>
  );
};

const BlockTable = ({ block }: { block: ReportEditorBlock }) => {
  const {
    reportStore: { upsertCustomBlock },
  } = useContext(StoreContext);

  if (!block.table) return <></>;

  const headerCells = block.table.columns.map(c => ({ value: c.header, flexWidth: c.flexWidth || 1 }));

  const tableRows = getTableRows(block);

  const rowCells = mapExists(tableRows, row => ({
    cells: headerCells.map((headerCell, i) => ({ value: row.cellValues[i], flexWidth: headerCell.flexWidth })),
    key: row.key,
  }));

  const addRow = () => {
    const newRow: ReportBlockTableRow = { cellValues: headerCells.map(() => ""), key: uuid() };
    return upsertCustomBlock(block.reportBlockTemplateId, { tableRows: [...tableRows, newRow] });
  };

  return (
    <Stack>
      <TableRow block={block} cells={headerCells} />
      {rowCells.map(({ cells, key }, i) => (
        <TableRow block={block} cells={cells} rowIndex={i} key={key} />
      ))}
      <Box width="calc(100% - 38px)">
        <ProcessButton process={addRow} startIcon={<AddIcon />} loadingOnly fullWidth />
      </Box>
    </Stack>
  );
};

export default BlockTable;
