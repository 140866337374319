import { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { FullStack } from "@parallel/polygon/components/shared/layout/container";
import { StudentGoal, StudentObjective } from "@parallel/vertex/types/progress.types";
import { toTitleCase } from "@parallel/vertex/util/string.util";
import ObjectiveActions from "@/components/progress/ObjectiveActions";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

export type GoalDisplayProps = {
  goal: StudentGoal;
  goalIndex: number;
  studentId: string;
  onEdit: () => void;
  onObjectiveSelect: (objective: StudentObjective) => void;
  onGoalUpdated: (g: StudentGoal) => void;
};

const logger = initLogger("GoalDisplay", getLoggerContext);

const GoalDisplay = ({ goal, goalIndex, studentId, onEdit, onObjectiveSelect, onGoalUpdated }: GoalDisplayProps) => {
  const {
    progressStore: { startPendingUpdate, setGoalCompleted, setGoalArchived },
  } = useContext(StoreContext);

  const reOpenGoal = () =>
    setGoalCompleted({ goal, studentId, isCompleted: false })
      .then(onGoalUpdated)
      .catch(logger.handleFailureAndThrow("setGoalCompleted"));

  const unarchiveGoal = () =>
    setGoalArchived({ goal, studentId, isArchived: false })
      .then(onGoalUpdated)
      .catch(logger.handleFailureAndThrow("setGoalArchived"));

  const isCompleted = !!goal.completedAt;

  return (
    <FullStack role="region">
      <Stack pb={2} gap={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Goal {goalIndex}.</Typography>

          <Stack direction="row" gap={1}>
            {!goal.isArchived ? (
              <>
                <Button
                  onClick={() => startPendingUpdate("archive", { record: goal, displayIndex: goalIndex })}
                  startIcon={<ArchiveIcon />}
                >
                  Archive
                </Button>
                {isCompleted ? (
                  <ProcessButton process={reOpenGoal} startIcon={<LockOpenIcon />} key="re-open">
                    Re-Open
                  </ProcessButton>
                ) : (
                  <>
                    <Button
                      onClick={() => startPendingUpdate("complete", { record: goal, displayIndex: goalIndex })}
                      startIcon={<CheckCircleOutlineIcon />}
                    >
                      Achieved
                    </Button>
                    <Button onClick={onEdit} startIcon={<EditIcon />}>
                      Edit
                    </Button>
                  </>
                )}
              </>
            ) : (
              <ProcessButton process={unarchiveGoal} startIcon={<UnarchiveIcon />} key="unarchive">
                Unarchive
              </ProcessButton>
            )}
          </Stack>
        </Stack>

        <Typography>{goal.description}</Typography>
      </Stack>

      {goal.objectives.map((objective, i) => (
        <Stack py={2} gap={1} key={objective.objectiveId}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Link onClick={() => onObjectiveSelect(objective)} sx={{ cursor: "pointer", textDecoration: "none" }}>
              <Typography variant="h3" sx={{ color: "primary.main" }}>
                {toTitleCase(objective.category)} Objective {goalIndex}.{i + 1}
              </Typography>
            </Link>

            <ObjectiveActions
              objective={objective}
              studentId={studentId}
              goal={goal}
              displayIndices={{ goal: goalIndex, objective: i + 1 }}
              onGoalUpdated={onGoalUpdated}
            />
          </Stack>

          <Typography variant="body1">{objective.description}</Typography>
        </Stack>
      ))}
    </FullStack>
  );
};

export default GoalDisplay;
