import { useState } from "react";
import { useDeepCompareEffect } from "@react-hookz/web";
import { intersectionBy, isArray, isNull } from "lodash";
import AutoCompleteInput, {
  BaseAutoCompleteProps,
  SelectOption,
} from "@parallel/polygon/components/shared/input/AutoCompleteInput";
import { toArray } from "@parallel/vertex/util/collection.util";

const AutoCompleteFetchInput = <P, S extends SelectOption | null | SelectOption[]>(
  props: BaseAutoCompleteProps<S> & {
    params: P | null;
    fetchOptions: (param: P) => Promise<SelectOption[] | null>;
  },
) => {
  const { params, selected, fetchOptions, onSelect } = props;

  const [options, setOptions] = useState<SelectOption[]>();
  const [isError, setIsError] = useState(false);

  const selectedOptions: SelectOption[] = toArray(selected);

  useDeepCompareEffect(() => {
    if (isNull(params)) return;

    setOptions(undefined);
    setIsError(false);

    fetchOptions(params).then(newOptions => {
      if (isNull(newOptions)) {
        setIsError(true);
        return;
      }
      // ensure current selection only includes the fetched options
      const filteredSelected = intersectionBy(selectedOptions, newOptions, "key");
      const newSelection = (isArray(selected) ? filteredSelected : filteredSelected[0] || null) as S;
      onSelect(newSelection);
      setOptions(newOptions);
    });
  }, [params]);

  const loadingText = isError ? "Error Fetching Options" : !options ? "Loading" : undefined;

  return <AutoCompleteInput {...props} options={options} loadingText={loadingText} />;
};

export default AutoCompleteFetchInput;
