import { ReactNode, useContext, useState } from "react";
import { toast } from "react-toastify";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import FileInputBox from "@parallel/polygon/components/shared/input/FileInputBox";
import VisuallyHiddenInput from "@parallel/polygon/components/shared/input/VisuallyHiddenInput";
import { ProcessButton } from "@parallel/polygon/components/shared/input/status.input";
import { displayInputFieldStyles } from "@parallel/polygon/util/style.util";
import { ExtendedReportTestUpload } from "@parallel/vertex/types/assessment/assessment.report.types";
import { getLoggerContext, StoreContext } from "@/stores";
import { initLogger } from "@/util/logging.util";

const logger = initLogger("TestUploadRow", getLoggerContext);

const TestUploadRowLayout = ({
  upload,
  actionButton,
  sx,
  children,
}: {
  upload: ExtendedReportTestUpload;
  actionButton: ReactNode;
  sx?: SxProps;
  children: ReactNode;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: 56, width: "100%", px: 1, ...sx }}
    >
      <Stack direction="row" alignItems="center">
        <Typography variant="body1" width={200}>
          {upload.source.name}
        </Typography>
        {children}
      </Stack>

      {actionButton}
    </Stack>
  );
};

const TestUploadRow = ({ upload, onSkip }: { upload: ExtendedReportTestUpload; onSkip: () => void }) => {
  const {
    apiStore: { reportApi },
    reportStore: { uploadTestFiles, updateTestUpload },
  } = useContext(StoreContext);

  const [loadingFileName, setLoadingFileName] = useState("");

  const clearSkipReason = () =>
    updateTestUpload(upload.reportTestUploadId, { skipReasonId: null }).catch(
      logger.handleFailureAndThrow("clearSkipReason"),
    );

  const viewFile = async () => {
    const url = await reportApi
      .getTestUploadFileUrl(upload.reportId, upload.reportTestUploadId)
      .catch(logger.handleFailureAndThrow("getTestUploadFileUrl"));

    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      logger.warn("upload file pop-up blocked");
      toast.error("Pop-up blocked. Please allow pop-ups for this site.");
    }
  };

  if (upload.skipReason)
    return (
      <TestUploadRowLayout
        upload={upload}
        actionButton={
          <ProcessButton startIcon={<UndoIcon />} process={clearSkipReason}>
            Reset
          </ProcessButton>
        }
        sx={{ bgcolor: "grey.200" }}
      >
        <Typography variant="body1">Did not administer - {upload.skipReason.title}</Typography>
      </TestUploadRowLayout>
    );

  const uploadFile = (file: File) => {
    setLoadingFileName(file.name);
    uploadTestFiles([{ file, sourceId: upload.reportTestUploadSourceId }])
      .catch(logger.handleFailure("uploadFiles"))
      .finally(() => setLoadingFileName(""));
  };

  const deleteUpload = () => {
    if (!fileName) return;
    setLoadingFileName(fileName);
    updateTestUpload(upload.reportTestUploadId, { isArchived: true })
      .catch(logger.handleFailure("deleteUpload"))
      .finally(() => setLoadingFileName(""));
  };

  const fileName = loadingFileName || upload.fileName;

  const content = fileName ? (
    <TextField
      sx={displayInputFieldStyles}
      value={fileName}
      size="small"
      disabled
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {loadingFileName ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={deleteUpload} size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Typography variant="body1">
      <Link component="label" sx={{ cursor: "pointer" }}>
        Select file to upload
        <VisuallyHiddenInput type="file" onChange={e => e.target.files && uploadFile(e.target.files[0])} />
      </Link>{" "}
      or drag and drop here
    </Typography>
  );

  if (!upload.fileName)
    return (
      <FileInputBox onFileSelect={fs => uploadFile(fs[0])}>
        <TestUploadRowLayout
          upload={upload}
          actionButton={
            <Button startIcon={<ContentPasteOffIcon />} onClick={onSkip}>
              Did not administer
            </Button>
          }
        >
          {content}
        </TestUploadRowLayout>
      </FileInputBox>
    );

  return (
    <TestUploadRowLayout
      upload={upload}
      actionButton={
        <ProcessButton process={viewFile} startIcon={<VisibilityIcon />}>
          View
        </ProcessButton>
      }
      sx={{ borderTop: 1, borderColor: "grey.200" }}
    >
      {content}
    </TestUploadRowLayout>
  );
};

export default TestUploadRow;
